import React, {useState} from 'react'
import { ToastContainer } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import PageMenuHeader from 'components/PageMenuHeader'
import DateRange from 'components/DateRange'
import { useQuery } from "react-query"
import { startOfMonth, endOfMonth } from "date-fns";
import { jsonToCSV } from 'react-papaparse'
import moment from 'moment'
import { Table, TableHeader, Row, Cell } from '@leafygreen-ui/table';
import { FaFileExport } from 'react-icons/fa'
import Button from '@leafygreen-ui/button'
import { ModalPreloader } from 'utils/Preloader'
import { errorHandler } from 'utils/errorHandler'
import axios from "axios";
import _ from 'lodash'

const title="Defect Transactions"

const sDate = startOfMonth(new Date());
const eDate = endOfMonth(new Date());

const fetchDefectsTransactions = ({sku, startDate, endDate}) => {
  console.log("* fetchDefectsTransactions init")
  return new Promise(async (resolve, reject) => {
    let results;
    try {
      if(window?.printflo_api) {
        results = await window.printflo_api._transactionsDefect({sku, startDate, endDate})
        if(_.isError(results)) throw results
      } else {
        try {
          const response = await axios.get(`/api/defect/transactions`, {params: { sku, startDate, endDate }});
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(results)
    } catch (error) {
      reject(errorHandler(error))
    }
  })
};

export default function DefectTransactions() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [sku, ] = useState(null);
  const [startDate, setStartDate] = useState(sDate);
  const [endDate, setEndDate] = useState(eDate);
  const [loading, setLoading] = useState(false);


  const { data:transactions, isLoading, isError, error, refetch } = useQuery(["transactions"],
    () => fetchDefectsTransactions({sku, startDate, endDate}),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1,
    }
  );

  const exportTransactions = () => {
    setLoading(true);
    // console.log("transactions", transactions);
    const result = jsonToCSV({
      fields: [
        "sku",
        "description",
        "quantity",
        "inventoryArea",
        "date",
        "currentStock",
        "user",
      ],
      data: transactions
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${sku ? sku + "-" : ""}defect_transactions_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoading(false);
  };

  const columns = [
    <TableHeader label='SKU' sortBy={datum => datum.sku}/>,
    <TableHeader label='Description' />,
    <TableHeader label='Quantity' dataType="number"/>,
    <TableHeader label='Inventory Area' sortBy={datum => datum.inventoryArea}/>,
    <TableHeader label='Date' sortBy={datum => datum.date} dataType='date'/>,
    <TableHeader label='Stock' dataType="number" className={!settings?.useInventoryModule ? 'hidden' : '' }/>,
    <TableHeader label='Handled by' sortBy={datum => datum.user}/>,
  ]

  return <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <ToastContainer theme='dark'/>
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger'>{error?.message ? error.message : error}</Banner>
        ) : (
          null
        )}
        <PageMenuHeader>
          <DateRange  startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} onClickHandler={refetch}/>
          <Button
            variant="primary" 
            className="swing-icon"
            leftGlyph={<FaFileExport />}
            onClick={() => exportTransactions()}
          >
            Export
          </Button>
        </PageMenuHeader>
        {
          transactions && Boolean(transactions.length)  
          ? <Table
              data={transactions}
              columns={columns}
            >
              {({datum}) =>(
                <Row key={datum._id}>
                  <Cell>{datum.sku}</Cell>
                  <Cell>{datum.description}</Cell>
                  <Cell>{datum.quantity}</Cell>
                  <Cell>{datum.inventoryArea ? datum.inventoryArea : ""}</Cell>
                  <Cell>{moment(datum.date).local().format("MM-DD-YYYY h:mm:ss a")}</Cell>
                  <Cell className={!settings?.useInventoryModule ? 'hidden' : '' }>{datum.currentStock}</Cell>
                  <Cell>{datum.user}</Cell>
                </Row>
              )}
            </Table>
          : <Banner>Transactions not found</Banner>
        }
        <ModalPreloader  modalPreloaderIsOpen={loading} />
      </section>
    </>
}
