import { errorHandler } from "./errorHandler";
import axios from "axios";
import _ from "lodash";

export const fetchInventoryTransactions = (payload) => {
  console.log("* utils:fetchInventoryTransactions init");
  return new Promise(async (resolve, reject) => {
    // console.log("[utils:fetchInventoryTransactions] payload: ", payload);
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchInventoryTransactions(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(`/api/inventory-transaction`, {
            params: payload,
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log(
      //   "[utils:inventoryTransactions:addTransaction] result: ",
      //   result
      // );
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log(
        "[utils:inventoryTransactions:addTransaction] retval: ",
        retval
      );
      reject(retval);
    }
  });
};

export const addTransaction = (payload) => {
  console.log("* utils:inventoryTransactions:addTransaction init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._addInventoryTransactions(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(
            `/api/inventory-transaction/add`,
            payload
          );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log(
      //   "[utils:inventoryTransactions:addTransaction] result: ",
      //   result
      // );
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log(
        "[utils:inventoryTransactions:addTransaction] retval: ",
        retval
      );
      reject(retval);
    }
  });
};
