import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import { Title } from "utils/Title";
import PageMainHeader from "components/PageMainHeader";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
import Banner from "@leafygreen-ui/banner";
import { fetchPackingItems } from "utils/packingItems";
import "react-toastify/dist/ReactToastify.css";
import { getS3presign } from "utils/awsS3";
import { ModalPreloader } from "utils/Preloader";
import queryString from "query-string";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getShipStationOrder } from "utils/shipstation";
import { getItemDoc } from "utils/items";
import _ from "lodash";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import Checkbox from "@leafygreen-ui/checkbox";
import Button from "@leafygreen-ui/button";
import {
  FaBold,
  FaCheckCircle,
  FaPrint,
  FaQrcode,
  FaTrashAlt,
  FaWindowClose,
} from "react-icons/fa";
import moment from "moment";
import { GridLoader } from "react-spinners";
import { errorHandler } from "utils/errorHandler";
import ItemOptions from "utils/ItemOptions";
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import { skuLabelTemplateQR } from "utils/skuLabelTemplateQR";
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { printOrderItemLabel } from "utils/printOrderItemLabel";
import { sessionValidate } from "utils/sessionValidate";
import { barcodeScannedHandler } from "utils/barcodeScannedHandler";
import socketIOClient from "socket.io-client";

const title = "Queue Print";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const fetchPackingItem = ({ queryKey }) => {
  console.log("* fetchPackingItem init");
  return new Promise(async (resolve, reject) => {
    try {
      console.log("[fetchPackingItem] queryKey: ", queryKey);
      let condition = queryKey[1];
      if (condition) {
        const response = await fetchPackingItems({ ...condition });
        if (!Boolean(response.length)) {
          throw new Error("No packing item found");
        } else {
          resolve(response[0]);
        }
      } else {
        resolve(null);
      }
    } catch (error) {
      let retval = errorHandler(error);
      reject(retval);
    }
  });
};

const fetchActivePackingItems = (sku) => {
  console.log("* fetchActivePackingItems init");
  return new Promise(async (resolve, reject) => {
    try {
      // console.log("- sku: ", sku);
      let condition = { sku, isActive: true };
      if (condition) {
        const response = await fetchPackingItems(condition);
        // console.log("- fetchPackingItems response: ", response);
        if (!Boolean(response.length)) {
          throw new Error("No packing item found");
        } else {
          resolve(response);
        }
      } else {
        resolve(null);
      }
    } catch (error) {
      let retval = errorHandler(error);
      reject(retval);
    }
  });
};


const integratedAutomations = [
  { value: "KOTHARI", name: "Kothari Automation" },
  { value: "GTXCMD", name: "Brother GTX CMD Line" },
  { value: "CADLINK", name: "CADlink Digital Factory" },
  { value: "MAVERICK", name: "MAVERICK" },
  { value: "ROQNOW", name: "ROQ NOW" },
];

const color = "#adadad";
const highlightColor = "#252525";

export default function QueuePrint({ location, history }) {
  const { settings, platens, hostname, sessions } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableQueueBtn, setDisableQueueBtn] = useState(true);
  const [disableCancelQueueBtn, setDisableCancelQueueBtn] = useState(true);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [preSignedUrl, setPreSignedUrl] = useState(null);
  const [condition, setCondition] = useState(null);
  const [, setPlatenValue] = useState(null);
  const [customPlaten, setCustomPlaten] = useState(null);
  const [loadedArtwork, setLoadedArtwork] = useState(false);
  const [printQty, setPrintQty] = useState(1);
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const itemRef = useRef(null);
  const ssOrderRef = useRef(null);
  const selectedPositionRef = useRef(null);
  const printQtyRef = useRef(1);
  const isQueueCompletedRef = useRef(true);
  const settingsRef = useRef(null);
  const bannerVariant = useRef("info");
  let queueCountRef = useRef(null);

  const conditionRef = useRef(null);
  const graphicsRef = useRef(null);
  const [sessionMsg, setSessionMsg] = useState(null);
  const sessionMsgRef = useRef(null);
  const isSessionValid = useRef(true);
  // const [activePackingItems, setActivePackingItems] = useState(null);
  const activePackingItemsRef = useRef(null);
  const [selectedCadlinkQueue, setSelectedCadlinkQueue] = useState(null)
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)
  const selectedAutomationRef = useRef(null)
  const isManualOrderRef = useRef(false);
  const barcodeRef = useRef();
  const [selectedMaverickQueue, setSelectedMaverickQueue] = useState(null)
  const preSignedUrlRef = useRef(null);

  const {
    data: item,
    refetch: refetchPackingItem,
    isLoading,
  } = useQuery(["item", condition], fetchPackingItem, {
    enabled: false,
    refetchOnWindowFocus: false,
    onError: (error) => {
      setMsg(error);
      setDisableQueueBtn(true);
    },
  });


  const { data: ssOrder, isLoading: isLoadingSS, refetch: refetchSSOrder } = useQuery(
    ["ssOrder"],
    async () => {
      console.log("* refetchSSOrder init");
      // console.log('- conditionRef: ', conditionRef.current)
      let orderId = conditionRef.current?.orderId;
      // console.log("- orderId: ", orderId);
      if (orderId) {
        return await getShipStationOrder(parseInt(orderId));
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        ssOrderRef.current = data
      }
    }
  );

  const { data: itemDoc, refetch: refetchItemDoc } = useQuery(
    ["itemDoc", condition],
    async () => {
      console.log("* refetchItemDoc init");
      // console.log('- conditionRef: ', conditionRef.current)
      let sku = condition?.sku
        ? condition.sku
        : conditionRef.current?.sku ?? conditionRef.current.sku;
      console.log("- sku: ", sku);
      if (sku) {
        return await getItemDoc(sku);
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // console.log(data)
        if(_.isNil(data)) {
          bannerVariant.current = "danger"
          setMsg("Unable to retrieve item info.")
        }
      }
    }
  );

  const { refetch: refetchActivePackingItems } = useQuery(
    ["activePackingItems", condition],
    async () => {
      console.log("* refetchActivePackingItems init");
      // console.log('- conditionRef: ', conditionRef.current)
      let sku = condition?.sku
        ? condition.sku
        : conditionRef.current?.sku ?? conditionRef.current.sku;
      // console.log("- sku: ", sku);
      if (sku) {
        return await fetchActivePackingItems(sku);
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // console.log("- activePackingItems data; ", data);
        activePackingItemsRef.current = data;
      },
    }
  );

  const queryClient = useQueryClient();

  // console.log("- item", item);
  // console.log("- ssOrder", ssOrder);
  // if (ssOrder) ssOrderRef.current = ssOrder;
  // console.log("- itemDoc", itemDoc);
  // console.log("- isLoading", isLoading);
  // console.log("- settings.autoQueue: ", settings);

    //settings hook
  useEffect(() => {
    console.log("* settings hook init");
    // console.log('- settings: ', settings)
    if (settings) {
      settingsRef.current = settings;
      setSelectedAutomation(settings.defaultIntegratedAutomation || "KOTHARI");
      selectedAutomationRef.current = settings.defaultIntegratedAutomation || "KOTHARI";
    }
  }, [settings]);

  const onBarcodeScannedHandler = ({ sku, orderId, orderItemId, graphicPosition } ) => {
    console.log('- isSessionValid:', isSessionValid)
    console.log('- sessionMsg:', sessionMsg)
    if(!isSessionValid.current) {
      if(sessionMsg){
        alert(sessionMsg);
      } else if(sessionMsgRef.current) {
        alert(sessionMsgRef.current);
      }
      return
    }
    console.log(
      "- settings.current?.autoQueue: ", settingsRef.current?.autoQueue
    );
    console.log("- isQueueCompleted: ", isQueueCompletedRef.current);
    // console.log("- itemRef: ", itemRef.current);
    let queryParams = queryString.parse(location.search);
    console.log("- queryParams: ", queryParams);
    if (queryParams?.sku) {
      history.push({ pathname: location.pathname, search: "" });
      alert("Encounter with old parameters, reload this page and scan again");
      window.location.reload();
      return;
    }

    // if(settingsRef.current?.autoQueue && !isQueueCompletedRef.current && itemRef.current) {
    if (!isQueueCompletedRef.current && itemRef.current) {
      let txt =
        "Data is being loaded or loading is completed, but queue print is not performed. ";
      if (settingsRef.current?.autoQueue) {
        txt = txt + "Scan a sticker after data loading completed or queued";
        window.alert(txt);
        return;
      } else {
        txt = txt + "Confirm OK to cancel the queue and load new data!";
        const confirm = window.confirm(txt);
        console.log("- confirm: ", confirm);
        if (!confirm) return;
      }
    }

    toast.dismiss();
    queryClient.removeQueries('item')
    queryClient.removeQueries('ssOrder')
    queryClient.removeQueries('itemDoc')
    queryClient.removeQueries('activePackingItems')
    ssOrderRef.current = null;
    itemRef.current = null;
    graphicsRef.current = null;
    setDisableQueueBtn(true);
    // setDisableQueueBtn(false);
    
    setSelectedPosition(null);
    selectedPositionRef.current = null;
    
    queueCountRef.current = null;
    disableCancelQueueBtn && setDisableCancelQueueBtn(true);
    activePackingItemsRef.current = null;
    bannerVariant.current = "info"

    console.log("- sku", sku);
    console.log("- orderId", orderId, typeof orderId);
    console.log("- orderItemId", orderItemId, typeof orderItemId);
    console.log("- graphicPosition", graphicPosition);

    let condition = { sku };
    if (orderId) condition.orderId = orderId;
    if (orderItemId) condition.orderItemId = orderItemId;
    if (graphicPosition) condition.graphicPosition = graphicPosition;

    setMsg(null);
    setCondition({ ...condition });
    conditionRef.current = condition;
    isQueueCompletedRef.current = false;
  }
  
  //onBarcodeScanned
  const onBarcodeScanned = async (data) => {
    console.log("* onBarcodeScanned init");
    console.log("[onBarcodeScanned] data: ", data);
    const { sku, orderId, orderItemId, position:graphicPosition } = barcodeScannedHandler(data);
    barcodeRef.current = data;
    onBarcodeScannedHandler({ sku, orderId, orderItemId, graphicPosition })
  };

  const socketOnBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("* on-barcode-scanned")
      console.log("[on-barcode-scanned] data: ", data);
      const { sku, orderId, orderItemId, position:graphicPosition } = barcodeScannedHandler(data);
      barcodeRef.current = data;
      onBarcodeScannedHandler({ sku, orderId, orderItemId, graphicPosition })
    });
  };

  //onBarcodeScanned hook
  useEffect(() => {
    const listner = window?.printflo_api && window.printflo_api.receive("serialport_readline", onBarcodeScanned)
    
    setTimeout(() => {
      _.isNil(window?.printflo_api) && socketOnBarcodeScanned();
    }, 1000);

    return () => {
      if(listner) listner()
      socket.removeAllListeners(["on-barcode-scanned"]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("* settingsRef & sessions & hostname hook ran");
    // console.log('- sessions: ', sessions);
    // console.log('- hostname: ', hostname);
    if (settingsRef.current) {
      // console.log('- settingsRef: ', settingsRef)
      // console.log('- settingsRef.current?.useUnlockedPrintflo: ', settingsRef.current?.useUnlockedPrintflo)
      if(!settings?.useUnlockedPrintflo){
        console.log('[Session Validation Hook] Subscription customer')
        
        const sessionValidateResult = sessionValidate({sessions, hostname});
        console.log("- sessionValidateResult: ", sessionValidateResult)
        if(sessionValidateResult?.message) {
          setSessionMsg(sessionValidateResult.message);
          sessionMsgRef.current = sessionValidateResult.message;
        } 
        isSessionValid.current = sessionValidateResult?.result;
      } else {
        console.log('[Session Validation Hook] Unlocked version')
      }
      
      console.log('- defaultIntegratedAutomation: ', settingsRef.current?.defaultIntegratedAutomation);
      console.log('- hostname: ', hostname);

      //CADLINK QUEUE
      if(hostname && Boolean(settingsRef.current?.cadLinkQueues?.length)) {
        console.log('- settingsRef.current?.cadLinkQueues: ', settingsRef.current?.cadLinkQueues);
        for (let index = 0; index < settingsRef.current.cadLinkQueues.length; index++) {
          const q = settingsRef.current.cadLinkQueues[index];
          for (let i = 0; i < q?.hostnames.length; i++) {
            const h = q?.hostnames[i];
            // console.log(index, i, h)
            if(hostname === h) {
              if(selectedAutomationRef.current !== 'CADLINK') {
                setSelectedAutomation('CADLINK');
                selectedAutomationRef.current = 'CADLINK';
              }

              setSelectedCadlinkQueue(q)
              break;
            }
          }
        }
      }

      if(hostname && Boolean(settingsRef.current?.maverick?.queueStations?.length)) {
        console.log('- settingsRef.current?.maverick?.queueStations: ', settingsRef.current.maverick.queueStations);
        for (let index = 0; index < settingsRef.current.maverick.queueStations.length; index++) {
          const q = settingsRef.current.maverick.queueStations[index];
          // console.log(q)
          if(q?.hostname === hostname) {
            if(selectedAutomationRef.current !== 'MAVERICK') {
              setSelectedAutomation('MAVERICK');
              selectedAutomationRef.current = 'MAVERICK';
            }

            setSelectedMaverickQueue(q)
            break;

          }
        }
      }
      //ROQ
      if(hostname && Boolean(settingsRef.current?.roq?.queueStations?.length)) {
        console.log('- settingsRef.current?.roq?.queueStations: ', settingsRef.current.roq.queueStations);
        for (let index = 0; index < settingsRef.current.roq.queueStations.length; index++) {
          const q = settingsRef.current.roq.queueStations[index];
          // console.log(q)
          if(q?.hostname === hostname) {
            if(selectedAutomationRef.current !== 'ROQNOW') {
              setSelectedAutomation('ROQNOW');
              selectedAutomationRef.current = 'ROQNOW';
            }

            setSelectedMaverickQueue(q)
            break;

          }
        }
      }

      
    }
    
    return () => {
      setSessionMsg(null);
      sessionMsgRef.current = null;
    }

  }, [settings, sessions, hostname]);

  useEffect(() => {
    console.log('* settingsRef & selectedAutomationRef hook init')
    // console.log('- settingsRef; ', settingsRef.current)
    console.log('- selectedAutomationRef; ', selectedAutomationRef.current)
    // console.log('- selectedPosition; ', selectedPosition)
    // console.log('- selectedPositionRef; ', selectedPositionRef.current)
    console.log('- selectedCadlinkQueue; ', selectedCadlinkQueue?.name)
    msg && setMsg(null)
    
    if(selectedAutomationRef.current && settingsRef.current) {
      if(selectedAutomationRef.current && _.includes(selectedAutomationRef.current, 'CAD')) {
        console.log('- settingsRef.current?.cadLinkQueues: ', settingsRef.current?.cadLinkQueues)
        if(!Boolean(settingsRef.current?.cadLinkQueues?.length)) {
          setMsg("CADlink is selected for integrated automation but not found CADlink queue(s)")
        } else {
          if(_.isNil(selectedCadlinkQueue)) {
            setMsg('Required at least a CADlink queue method!')
            loading && setLoading(false)
          }
        }
        
      }
    }


  // eslint-disable-next-line
  }, [settings, selectedAutomation, selectedCadlinkQueue])

  //location hook
  useEffect(() => {
    console.log("* location hook init");
    // console.log('- location: ', location)
    let parsed = queryString.parse(location?.search);
    console.log("- parsed: ", parsed);

    if (parsed?.sku) {
      let condition = {};
      let sku = parsed?.sku ?? parsed.sku;
      let orderId = parsed?.orderId ?? parsed.orderId;
      let orderItemId = parsed?.orderItemId ?? parsed.orderItemId;
      let consolidation = parsed?.consolidation ?? parsed.consolidation;
      let printedFilms = parsed?.printedFilms ?? parsed.printedFilms;
      let qty = parsed?.qty ?? parsed.qty;
      // console.log('- sku: ', sku);
      // console.log('- orderId: ', orderId);
      // console.log("- consolidation: ", consolidation, Boolean(consolidation));
      // console.log("- printedFilms: ", printedFilms, Boolean(printedFilms));
      // console.log("- qty: ", qty, Boolean(qty));

      if (sku) condition["sku"] = sku;
      if (orderId) condition["orderId"] = orderId;
      if (orderItemId) condition["orderItemId"] = orderItemId;
      if (consolidation) condition["consolidation"] = Boolean(consolidation);
      if (printedFilms) condition["printedFilms"] = Boolean(printedFilms);
      if (qty) condition["qty"] = qty;
      console.log("- condition: ", condition);
      setCondition({ ...condition });
      conditionRef.current = condition;
    }
    // eslint-disable-next-line
  }, [location]);

  //refect by condition
  useEffect(() => {
    console.log("* condition hook init");
    console.log("- condition: ", condition);
    if(_.isNil(condition)) {
      queryClient.removeQueries(['ssOrder'])
      ssOrderRef.current = null
    }
    if (condition?.sku && condition?.orderId) {
      refetchPackingItem();
      // refetchSSOrder();
    }

    if (condition?.sku && !condition?.orderId) {
      if(condition?.qty) {
        setPrintQty(condition.qty);
        printQtyRef.current = condition.qty;
      } 
      refetchItemDoc();
      if(condition?.consolidation) refetchActivePackingItems();
    }


    // eslint-disable-next-line
  }, [condition]);

  //item & itemDoc hook
  useEffect(() => {
    console.log("* item & itemDoc hooks init");
    if(item) console.log("- item retrived");
    // console.log("- item: ", item);
    if(itemDoc) console.log("- itemDoc retrived");
    // console.log("- itemDoc: ", itemDoc);
    if (item || itemDoc) {
      itemRef.current = item || itemDoc;
      // console.log('- itemRef.current: ', itemRef.current)
      if (item && !itemDoc) queryClient.removeQueries("itemDoc");

      //graphics
      graphicsRef.current = itemRef.current?._item?._graphics
        ? itemRef.current._item._graphics
        : itemRef.current?._graphics ?? itemRef.current._graphics;
      console.log("- graphicsRef: ", graphicsRef.current);

      if (!settingsRef.current?.useARXfile) {
        if (_.isArray(graphicsRef.current)) {
          if (!Boolean(graphicsRef.current?.length)) {
            if (!disableQueueBtn) setDisableQueueBtn(true);
            if (!isQueueCompletedRef.current)
              isQueueCompletedRef.current = true;
            setMsg("Graphic not defined.");
            return;
          } else {
            //setSelectedPosition
            handleByGraphics(graphicsRef.current);
          }
        }
      } else {
        if (!Boolean(graphicsRef.current?.length)) {
          if (!disableQueueBtn) setDisableQueueBtn(true);
          if (!isQueueCompletedRef.current) isQueueCompletedRef.current = true;
          setMsg("ARX file not defined.");
          return;
        } else {
          //setSelectedPosition
          handleByGraphics(graphicsRef.current);
        }
      }

      //checked-out?
      if (item) {
        let txt;
        if (itemRef.current?.checkedOutQty > 0) {
          txt = "Already checked-out before";
        } else if (
          !_.includes(itemRef.current?._order?.orderStatus, "awaiting")
        ) {
          txt = "Might be already a shipped item";
        }
        toast.dismiss();
        txt &&
          toast.info(txt, {
            position: "bottom-right",
            autoClose: 2000,
          });

        // call ssOrder if source is not manual order
        console.log('+ item?._order?.source: ', item?._order?.source)
        if(item?._order?.source !== 'manual') {
          refetchSSOrder()
        } else {
          isManualOrderRef.current = true;
        }  
      }

      // customPlaten
      let ripEnv = itemRef.current?._item?.ripEnv
        ? itemRef.current._item.ripEnv
        : itemRef.current?.ripEnv ?? itemRef.current.ripEnv;
      console.log("- ripEnv: ", ripEnv);
      if (ripEnv) {
        handleByRipEnv(ripEnv);
      }

      msg && setMsg(null);
      if (itemDoc) {
        if (disableQueueBtn) setDisableQueueBtn(false);
        if (activePackingItemsRef.current) {
          if (Boolean(activePackingItemsRef.current.length)) {
            setMsg(
              `Use the Print Queue icon button for saving printed count data in Active Order Items`
            );
          }
        } 
      }
    } else {
      bannerVariant.current = "danger";
      setMsg('Item profile not found, please verify that the item exists')
      setDisableQueueBtn(true)
    } // end-if itemDoc

    // eslint-disable-next-line
  }, [item, itemDoc, activePackingItemsRef, settings]);

  useEffect(() => {
    console.log('- ssOrder hook init')
    // console.log(ssOrder)
    if(ssOrder) {
      if(ssOrder?.orderStatus==='cancelled') {
        bannerVariant.current = "danger";
        setMsg('Unable to queue since the order was cancelled')
        setDisableQueueBtn(true)
      } else {
        disableQueueBtn && setDisableQueueBtn(false)
      }
    }
    // eslint-disable-next-line
  }, [ssOrder]);

  useEffect(() => {
    console.log("* selectedPosition hook init");
    // console.log('- selectedPosition: ', selectedPosition);
    // console.log('- selectedPositionRef: ', selectedPositionRef.current);
    // console.log('- settings: ', settings)
    // console.log('- settingsRef: ', settingsRef)
    // console.log('- isManualOrderRef: ', isManualOrderRef)

    if(selectedPosition || selectedPositionRef.current) {
      if (isManualOrderRef.current && disableQueueBtn) setDisableQueueBtn(false);
      // get mockup link
      let graphicFileName = selectedPosition?.graphicFileName 
        ? selectedPosition.graphicFileName
        : selectedPositionRef?.current?.graphicFileName 
        ?? selectedPositionRef.current.graphicFileName;
      
      console.log("- graphicFileName: ", graphicFileName);
      
      if (graphicFileName) {
        if (/^true$/.test(settingsRef.current?.useLocalRepository)) {
          console.log("[selectedPosition hook] case of useLocalRepository");
          
          // setPreSignedUrl(
            // `file://${settingsRef.current?.graphicFilePath}/${graphicFileName}`
            // `/graphics/${graphicFileName}`
            // `https://localhost:8080/graphics/${graphicFileName}`
          // );
          

        } else {
          if(!_.includes(graphicFileName, "http")) {
            const key = `${
              settingsRef.current?.aws?.graphics || "graphics"
            }/${graphicFileName}`;
            getS3presign({ key }).then((response) => {
              console.log("[getS3presign] response: ", response);
              setPreSignedUrl(() => {
                if(response?.url) {
                  preSignedUrlRef.current = response.url;
                  return response.url;
                } else {
                  preSignedUrlRef.current = response;
                  return response;
                }
              });
            });
          } else {
            setPreSignedUrl(graphicFileName);
            preSignedUrlRef.current = graphicFileName;
          }
        }
      }

      // warning platen change
      let platen =
        selectedPositionRef?.current?.platen ??
        selectedPositionRef.current.platen;

      if (platen) {
        if (
          /^true$/.test(settingsRef.current?.platenChangeWarning) && _.includes(!settingsRef.current?.defaultIntegratedAutomation, "CAD")
        ) {
          warnPlatenChange(platen);
        }
      }

      if (_.isNil(queueCountRef.current)) queueCountRef.current = 0;

      //check ripEnv from selectedPositionRef
      if (selectedPositionRef.current?.ripEnv) {
        console.log(
          "- selectedPositionRef?.current?.ripEnv: ",
          selectedPositionRef.current.ripEnv
        );
        handleByRipEnv(selectedPositionRef.current.ripEnv);
      }

      if(selectedAutomationRef.current && selectedAutomationRef.current.includes('CAD') && _.isNil(selectedPositionRef.current?.media)) {
        const defaultMedia = _.find(settingsRef.current?.media, {isDefault: true});
        // console.log('- defaultMedia; ', defaultMedia)
        if(defaultMedia) {
          if(defaultMedia?.code) {
            setSelectedPosition(current => {
              let retVal = {...current, media: defaultMedia.code}
              selectedPositionRef.current = retVal;
              return retVal
            })
          } 
        } else {
          if(Boolean(settingsRef.current?.media.length)) {
            setSelectedPosition(current => {
              let retVal = {...current, media: settingsRef.current?.media[0]?.code}
              selectedPositionRef.current = retVal;
              return retVal
            })
          }
        
            
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedPosition, settings, isManualOrderRef]);
  // }, [settingsRef, selectedAutomationRef, selectedPosition]);

  useEffect(() => {
    console.log('* autoQueue hook init')
    // autoQueue
    if (settingsRef.current?.autoQueue) {
      console.log("[autoQueue hook] autoQueue is true");
      console.log("[autoQueue hook] isSessionValid: ", isSessionValid);
      console.log("[autoQueue hook] queueCountRef: ", queueCountRef);
      console.log("[autoQueue hook] conditionRef: ", conditionRef);
      isQueueCompletedRef.current = false;
      if (
        queueCountRef.current === 0 &&
        isSessionValid.current &&
        !conditionRef?.current?.consolidation
      ) {
        setTimeout(() => {
          console.log('[autoQueue hook] call queuePrint')
          queuePrint();
        }, 2000);
      }
    }
    // eslint-disable-next-line
  },[settingsRef.current, isSessionValid.curent, queueCountRef.current, conditionRef.current])

  const handleByRipEnv = (ripEnv) => {
    console.log("* handleByRipEnv init");
    // console.log("- ripEnv: ", ripEnv);
    // console.log('- selectedAutomation: ', selectedAutomation);
    // console.log('- settings.useCustomPlatens: ', settingsRef?.current?.useCustomPlatens);

    if (settingsRef?.current?.useCustomPlatens) {
      getSetCustomPlaten(ripEnv);
    }
  };

  const handleByGraphics = (graphics) => {
    console.log("* handleByGraphics init");
    // console.log('- graphics: ', graphics)
    // setSelectedPosition
    if (graphics.length === 1) {
      // console.log("- graphics[0]", graphics[0]);
      const graphic = graphics[0]
      if (graphic?.graphicPosition.toLowerCase() !== "back") {
        setSelectedPosition(graphic);
        selectedPositionRef.current = graphic;
      }
    } else {
      console.log('- conditionRef: ', conditionRef)
      if(conditionRef.current?.graphicPosition) {
        for(let g of graphics) {
          // console.log(g)
          if(g?.graphicPosition) {
            if(g.graphicPosition === conditionRef.current?.graphicPosition) {
              setSelectedPosition(g);
              selectedPositionRef.current = g;
              break;
            }
          }
        }
      }
    }
  };

  const warnPlatenChange = (platen) => {
    console.log("* warnPlatenChange init");
    setPlatenValue((currentPlaten) => {
      console.log("- currentPlaten: ", currentPlaten);
      console.log("- platen: ", platen);

      if (currentPlaten !== platen) {
        bannerVariant.current = "danger";
        setMsg(`Replace the platen with ${platen}`);
      }
      return platen;
    });
  };

  const queueResultHandler = async ({flag, queueResult}) => {
    console.log("* queueResultHandler init");
    console.log("[queueResultHandler] flag: ", flag);
    console.log("[queueResultHandler] queueResult: ", queueResult);

    bannerVariant.current = "info";  
    if (queueResult?.name === "Error" || queueResult?.code > 0) bannerVariant.current = "warning";
    if(queueResult?.description){
      setMsg(`${queueResult.description} ${!_.isNil(queueResult?.code) && queueResult?.code < 0 ? "(" + queueResult.code + ")" : ""}`);
    } 
    else
    if(queueResult?.message) {
      setMsg(queueResult.message)
    }

    if (queueResult?.printed) {
      if (itemRef.current)
        itemRef.current = { ...itemRef.current, printed: queueResult.printed };
      if (activePackingItemsRef.current) {
        refetchActivePackingItems();
      }
    }
      
    isQueueCompletedRef.current = true;
    if(settingsRef.current.roq.enableLaunch) {
      if(flag === 'roq' && queueResult?.jobId && queueResult?.token) {
        //launch
        toast.info("The job will be launching in 5 seconds.", {
          position: "bottom-right",
          autoClose: settingsRef.current.roq?.delay || 3000,
          onClose: async () => {
            const launchResult = await window.printflo_api._launchROQ({ jobId: queueResult.jobId, token: queueResult?.token});
            console.log("[queueResultHandler] launchResult: ", launchResult);
            if(launchResult?.name === "Error") 
              bannerVariant.current = "warning"
            setMsg(launchResult?.message);
          }
        })
  
      }
    }

    setDisableCancelQueueBtn(false);
    
  }

  const queuePrint = async (orderItem = null) => {
    console.log("* queuePrint init");
    console.log("[queuePrint] isSessionValid: ", isSessionValid);
    console.log("[queuePrint] selectedAutomation: ", selectedAutomation);
    // console.log("- orderItem: ", orderItem);
    // console.log("- orderItem?.sku: ", orderItem?.sku);
    if (!isSessionValid.current) {
      alert("Your license for Printflo is expired or this station is not allowed for printing");
      setDisableQueueBtn(true);
      setDisableCancelQueueBtn(true);
      return;
    }
    queueCountRef.current = queueCountRef.current + 1;
    if (orderItem?.sku) itemRef.current = { _item: itemRef.current, ...orderItem};

    setLoading(true);
    // console.log("[queuePrint] itemRef.current: ", itemRef.current);
    // console.log("- selectedPositionRef.current", selectedPositionRef.current);
    // console.log("- printQty: ", printQty);
    // console.log("- printQtyRef.current: ", printQtyRef.current);

    toast.dismiss();
    if (msg) setMsg(null);

    if (itemRef.current && selectedPositionRef.current) {
      let _id = itemRef.current?._id;
      let sku = itemRef.current?.sku;
      let ripEnv = selectedPositionRef.current?.ripEnv
        ? selectedPositionRef.current.ripEnv
        : itemRef.current?._item?.ripEnv
        ? itemRef.current._item.ripEnv
        : itemRef.current?.ripEnv ?? itemRef.current.ripEnv;
      
      let graphicPosition = selectedPositionRef.current?.graphicPosition;

      let itemObj = { _id, sku, ripEnv };
      let args = {
        item: itemObj,
        selectedPosition: selectedPositionRef.current,
      };

      let updatePrinted;

      //for updating printed.qty
      // if(ssOrderRef.current && itemRef.current?.printed) {
      if (itemRef.current?.printed) {
        let tempPrinted = _.find(itemRef.current?.printed, (value) => {
          return _.includes(
            value.position.toLowerCase(),
            graphicPosition.toLowerCase()
          );
        }) || {
          qty: 0,
        };
        // console.log("- tempPrinted.qty: ", tempPrinted?.qty);

        if (tempPrinted.qty < itemRef.current.quantity) {
          let _id = itemRef?.current?._id;
          // console.log("- _id, position:(printed) ", _id, graphicPosition);
          updatePrinted = {
            _id,
            graphicPosition,
            hostname,
            username: user?.username,
            printedQty: tempPrinted.qty + 1,
          };
        }
      }

      let queueResult;
      let payload;

      //GTX
      if (_.includes(selectedAutomation, "GTX")) {
        const arxFileName =
          selectedPositionRef.current?.arxFileName ??
          selectedPositionRef.current?.arxFileName;
        console.log("[queuePrint:GTX] arxFileName: ", arxFileName);

        if (!_.has(itemRef.current, "_item")) {
          args["printQty"] = printQtyRef.current;
        }

        if (!arxFileName) {
          setMsg("ARX file name is missing");
          setLoading(false);
          return;
        }

        bannerVariant.current = "info";
        setMsg("Sent a queue data to Command Line...");
        payload = {
          ...args,
          updatePrinted,
          arxFileName,
          printQty: printQtyRef.current.toString()
        }
        
        try {
          if(window?.printflo_api) {
            queueResult = await window.printflo_api._queueGTX(payload)
          } else {
            const promise = () => {
              return new Promise((resolve, reject) => {
                socket.emit("on-queue-gtx", payload, (response) => {
                  console.log('[queuePrint:gtx] response: ', response);
                  resolve(response);
                });

              })
            }
            queueResult = await promise();
          }
          
        } catch (error) {
          console.log("[queuePrint:GTX] error: ", error)
        }

        setLoading(false);
        queueResultHandler({flag: 'gtx', queueResult});

      }
      //KOTHARI
      if (_.includes(selectedAutomation, "KOTHARI")) {
        try {
          const payload = {
            ...args,
            printQty: printQtyRef.current.toString(),
            updatePrinted
          };

          if(window?.printflo_api) {
            queueResult = await window.printflo_api._queueKothari(payload);
          } else {
            const promise = () => {
              return new Promise((resolve, reject) => {
                socket.emit("on-queue-kothari", payload, (response) => {
                  console.log('[queuePrint:kothari] response: ', response);
                  resolve(response);
                });

              })
            }
            queueResult = await promise();
          }
          console.log("[queuePrint:KOTHARI] queueResult: ", queueResult);
          setLoading(false);
          queueResultHandler({flag: "kothari", queueResult});

        } catch (error) {
          console.log("[queuePrint:KOTHARI] error: ", error);
          setMsg(error);
          setLoading(false);
          return;
        }
      }
      //CADLINK
      if (_.includes(selectedAutomation, "CADLINK")) {
        // console.log("[queuePrint:CADLINK] selectedAutomation: ", selectedAutomation);
        // console.log("[queuePrint::CADLINK] selectedCadlinkQueue: ", selectedCadlinkQueue)
        console.log("[queuePrint:CADLINK] selectedPositionRef: ", selectedPositionRef)
        console.log("[queuePrint:CADLINK] usePrintMode: ", settingsRef.current?.usePrintMode)
        if(_.isNil(selectedCadlinkQueue)) {
          setMsg('CADlink queue is required');
          setLoading(false);
          return
        }
        if(settingsRef.current?.usePrintMode && selectedPositionRef.current?.printMode) {
          ripEnv = selectedPositionRef.current?.printMode
        }
        console.log("[queuePrint:CADLINK] ripEnv: ", ripEnv);

        payload = {
          orderId: itemRef?.current?.orderId,
          orderItemId: itemRef?.current?.orderItemId,
          ripEnv,
          selectedPosition: selectedPositionRef.current,
          selectedCadlinkQueue,
          updatePrinted
        }

        if(window?.printflo_api) {
          queueResult = await window.printflo_api._queueCADlink(payload);
        } else {
          const promise = () => {
            return new Promise((resolve, reject) => {
              socket.emit("on-queue-CADlink", payload, (response) => {
                console.log('[queuePrint:CADlink] response: ', response);
                resolve(response);
              });

            })
          }
          queueResult = await promise();
        }

        console.log('[queuePrint:CADLINK] queueResult: ', queueResult);
        setLoading(false);
        queueResultHandler({flag: "cadlink", queueResult});
      }
      
      //ROWNOW
      if (_.includes(selectedAutomation, "ROQNOW")) {
        console.log("[queuePrint:ROQNOW] selectedPositionRef: ", selectedPositionRef);
         queueResult = await window.printflo_api._queueROQ({
          orderId: itemRef?.current?.orderId,
          orderItemId: itemRef?.current?.orderItemId,
          ripEnv,
          selectedPosition: selectedPositionRef.current,
          updatePrinted,
          preSignedUrl: preSignedUrl? preSignedUrl : preSignedUrlRef.current ?? preSignedUrlRef.current,
          barcode: barcodeRef.current
        });

        console.log('[queuePrint:ROQNOW] queueResult: ', queueResult);
        setLoading(false);
        queueResultHandler({flag: "roq", queueResult});
      }

      //Maverick
      if (_.includes(selectedAutomation, "MAVERICK")) {
        console.log("[queuePrint:MAVERICK] selectedPositionRef: ", selectedPositionRef);
        if(_.isNil(selectedPositionRef.current?.hfTemplate) && !_.isNil(itemRef.current.hfTemplate)) {
          selectedPositionRef.current['hfTemplate'] = itemRef.current.hfTemplate;
        }
        const payload = {
          orderId: itemRef?.current?.orderId,
          orderItemId: itemRef?.current?.orderItemId,
          selectedPosition: selectedPositionRef.current,
          updatePrinted,
          preSignedUrl,
          barcode: barcodeRef.current
        }
        if(window?.printflo_api) {
          queueResult = await window.printflo_api._queueMAVERICK(payload);
        } else {
          const promise = () => {
            return new Promise((resolve, reject) => {
              socket.emit("on-queue-maverick", payload, (response) => {
                console.log('[queuePrint:maverick] response: ', response);
                resolve(response);
              });

            })
          }
          queueResult = await promise();
        }

        console.log('[queuePrint:MAVERICK] queueResult: ', queueResult);
        setLoading(false);
        queueResultHandler({flag: "maverick", queueResult});
      }


    } else {
      bannerVariant.current = "danger";
      setMsg("Item or selected position not found");
      setLoading(false);
      return;
    }
  };

  const PlatenName = ({ platen }) => {
    const foundPlaten = _.find(platens, { code: platen });
    // console.log("foundPlaten", foundPlaten);
    return (
      <span className="content content-ref">
        {foundPlaten && `${foundPlaten.name}(${platen})`}
      </span>
    );
  };

  const MediaName = ({media, index}) => {
    // console.log('* MediaName init')
    // console.log('- media: ', media)
    // console.log('- index: ', index)
    // console.log('- selectedPositionRef: ', selectedPositionRef)
    return (
      <select
        name="media"
        value={media ? media : selectedPositionRef.current?.media ? selectedPositionRef.current.media : undefined}
        onChange={(e) => {
          graphicsRef.current[index] = {...graphicsRef.current[index], media: e.target.value}
          setSelectedPosition((current) => {
            selectedPositionRef.current = {...current, media: e.target.value}
            return {...current, media: e.target.value}
          })
          msg && setMsg(null)
        }}
      >
        <option value="">-- Choose media --</option>
        {settingsRef.current?.media &&
          settingsRef.current?.media.map((p) => {
            return (
              <option value={p.code} key={p.code}>
                {p.name} ({p.size.width}x{p.size.height} inch)
              </option>
            );
          })}
      </select>
    )
  }

  const Printed = ({ position }) => {
    // console.log("* Printed init");
    // console.log("[Printed] position: ", position);
    // console.log("[Printed] itemRef.current: ", itemRef.current);
    const foundPrinted = _.find(itemRef.current?.printed, (value) => {
      // console.log("[Printed] value: ", value);
      
      return _.includes(value?.position?.toLowerCase(), position.toLowerCase());
    });
    // console.log("- foundPrinted", foundPrinted);
    return <b className="printed-qty">{foundPrinted ? foundPrinted.qty : 0}</b>;
  };

  const PrintedStatus = ({ position }) => {
    // console.log("* PrintedStatus init");
    // console.log("[PrintedStatus] position: ", position);
    const foundPrinted = _.find(itemRef.current?.printed, (value) => {
      return _.includes(value?.position?.toLowerCase(), position.toLowerCase());
    });
    // console.log("foundPrinted", foundPrinted);
    return foundPrinted && foundPrinted.qty >= itemRef.current?.quantity ? (
      <FaCheckCircle />
    ) : (
      <FaWindowClose />
    );
  };

  const getSetCustomPlaten = (ripEnv) => {
    const regex = /\d+[x]\d+/gim;
    let isCustomPlaten = regex.test(ripEnv);
    console.log(`- isCustomPlaten: ${isCustomPlaten}`);
    if (isCustomPlaten) {
      let matched = ripEnv.match(regex);
      console.log("- matched", matched);
      if (Boolean(matched.length)) {
        toast.dismiss();
        let customPlaten = matched[0];
        // console.log("- customPlaten: ", customPlaten);
        let platenName = `Custom Platen (${customPlaten})`;
        setCustomPlaten(platenName);
        toast.info(`${platenName} was applied`, {
          position: "bottom-right",
          autoClose: false,
          onOpen: () => {
            warnPlatenChange(platenName);
          },
        });
      }
    }
  };

  const handleSelectedCadlinkQueue = (e) => {
    console.log('* handleSelectedCadlinkQueue init')
    // console.log('e.target.value: ', e.target.value)
    const found = _.find(settingsRef.current?.cadLinkQueues, {name: e.target.value})
    if(found) setSelectedCadlinkQueue(found);
  }

  const handleSelectedMaverickQueue = (e) => {
    console.log('* handleSelectedMaverickQueue init')
    // console.log('e.target.value: ', e.target.value)
    const found = _.find(settingsRef.current?.maverick?.queueStations, {name: e.target.value})
    if(found) setSelectedMaverickQueue(found);
  }

  const GraphicsTable = ({ graphics, useARXfile, selectedAutomation, usePrintMode }) => {
    let index = 0;
    return (
      <div className="queue-print-graphics card mb-10">
        <Table
          data={graphics}
          columns={[
            <TableHeader label="Check position to print" />,
            <TableHeader label="Graphic File" />,
            <TableHeader
              label="ARX File"
              className={!useARXfile && "hidden"}
            />,
            <TableHeader label="Size(width/height)" />,
            <TableHeader label="Platen" className={_.includes(selectedAutomation, "CAD") && "hidden"}/>,
            <TableHeader label="Media(CADlink)" className={!_.includes(selectedAutomation, "CAD") && "hidden"}/>,
            <TableHeader label="Rip Env." className={!_.includes(selectedAutomation, "KOTHARI") && "hidden"}/>,
            <TableHeader label="HF Template" className={!_.includes(selectedAutomation, "MAVERICK") && "hidden"}/>,
            <TableHeader label="Print Mode(CADlink)" 
              className={!_.includes(selectedAutomation, "CAD") ? "hidden" : !usePrintMode ? "hidden" : ""} />,
            <TableHeader label="Printed" className={itemDoc && "hidden"} />,
            <TableHeader label="status" className={itemDoc && "hidden"} />,
          ]}
        >
          {({ datum }) => {
            
            return (
              <Row key={datum.graphicPosition}>
                <Cell>
                  <Checkbox
                    label={datum.graphicPosition}
                    checked={
                      selectedPosition &&
                      selectedPosition.graphicPosition === datum.graphicPosition
                        ? true
                        : false
                    }
                    onChange={() => {
                      if (disableQueueBtn) setDisableQueueBtn(false);
                      setSelectedPosition(datum);
                      selectedPositionRef.current = datum;
                    }}
                  />
                </Cell>
                <Cell style={{maxWidth: "300px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} title={datum?.graphicFileName ? datum.graphicFileName : ""}>{datum?.graphicFileName ? datum.graphicFileName : ""}</Cell>
                <Cell className={!useARXfile && "hidden"}>
                  {datum.arxFileName ? datum.arxFileName : ""}
                </Cell>
                <Cell>
                  <input
                    type="text"
                    id="width"
                    defaultValue={
                      _.has(datum, "size") &&
                      !_.isNil(datum.size.width)
                        ? datum.size.width
                        : ""
                    }
                    style={{ maxWidth: 60 }}
                    placeholder="width"
                    disabled={true}
                  />
                  <input
                    type="text"
                    id="height"
                    defaultValue={
                      _.has(datum, "size") &&
                      !_.isNil(datum.size.height)
                        ? datum.size.height
                        : ""
                    }
                    style={{ maxWidth: 60, marginLeft: 10 }}
                    placeholder="height"
                    disabled={true}
                  />
                </Cell>
                { !_.includes(selectedAutomation, 'CAD') 
                 ? (
                  <Cell>
                    {customPlaten ? (
                      <span>{customPlaten}</span>
                    ) : (
                      <PlatenName platen={datum.platen} />
                    )}
                  </Cell>

                 ) : (
                  <Cell>
                    <MediaName media={datum?.media} index={index} />
                  </Cell>
                 )
                }
                
                {_.includes(selectedAutomation, "KOTHARI") && (
                  <Cell>{datum?.ripEnv ? datum.ripEnv : ""}</Cell>
                )}
                
                {_.includes(selectedAutomation, "MAVERICK") && (
                  <Cell>{datum?.hfTemplate ? datum.hfTemplate : ""}</Cell>
                )}

                {
                  (selectedAutomation?.toLowerCase().includes('cad') && usePrintMode) && (
                    <Cell>{datum?.printMode ? datum.printMode: ""}</Cell>
                  )
                }
                <Cell className={itemDoc && "hidden"}>
                  <Printed position={datum.graphicPosition} />
                </Cell>
                <Cell className={itemDoc && "hidden"}>
                  <PrintedStatus position={datum.graphicPosition} />
                </Cell>
                {index = index + 1}
              </Row>
            )
          }}
        </Table>
      </div>
    );
  };

  const ActivePackingItemsTable = ({ activePackingItems }) => {
    let index = 0;

    return (
      <div className="active-packingitems card mb-10 p-10">
        <h5>Active Order Items:: {activePackingItems.length}</h5>
        <div className="list">
          <Table
            data={activePackingItems}
            columns={[
              <TableHeader label="No" />,
              <TableHeader label="Order Number" />,
              <TableHeader label="Ordered" />,
              <TableHeader label="Printed" />,
              <TableHeader label="Checked Out" />,
              <TableHeader label="Synced At" />,
              <TableHeader label="Action" />,
            ]}
          >
            {({ datum }) => {
              index = index + 1;
              return (
                <Row key={datum._id}>
                  <Cell>{index}</Cell>
                  <Cell>
                    <Link to={`workorder-details/${datum?.orderId}`}>
                      <span className="block">
                        {datum.orderNumber}
                        {datum.isBundle && (
                          <span>
                            <FaBold
                              size="10px"
                              color="#007CAD"
                              className="ml-10"
                            />
                          </span>
                        )}
                      </span>
                    </Link>

                    <span className="block">
                      {datum.sequence} / {datum.count}
                    </span>
                  </Cell>
                  <Cell>{datum?.quantity ? datum.quantity : 0}</Cell>
                  <Cell>
                    <span className="active-packingitem-printed">
                      {datum?.printed ? _.sumBy(datum.printed, "qty") : 0}
                    </span>
                  </Cell>
                  <Cell>{datum?.checkedOutQty ? datum.checkedOutQty : 0}</Cell>
                  <Cell>
                    {datum?.createdAt
                      ? moment(datum.createdAt).format("MM-DD-YYYY")
                      : ""}
                  </Cell>
                  <Cell>
                    <div className="action-btn-list d-flex align-items-center">
                        
                        <Button
                          className="ml-10"
                          variant="primary"
                          onClick={() => queuePrint(datum)}
                          leftGlyph={<FaPrint />}
                          size="small"
                        >
                          Queue Print
                        </Button>
                        <Tooltip
                          className="lg-tooltip-container"
                          darkMode={true}
                          trigger={
                            <IconButton
                              onClick={() => printOrderItemLabel({item: datum, settings})}
                              className="swing-icon"
                              aria-label="Print a label"
                            >
                              <FaQrcode />
                            </IconButton>
                          }
                        >
                          Print a label
                        </Tooltip>
                      </div>  

                  </Cell>
                </Row>
              );
            }}
          </Table>

        </div>
      </div>
    );
  };

  const onCancelQueueResult = useCallback(() => {
    // socket.on("on-cancel-queue-result", (result) => {
    //   console.log("* on-cancel-queue-result init");
    //   console.log("- result: ", result);
    //   // setDisableQueueBtn(false);
    //   setMsg(result);
    // });
  }, []);

  //onCancelQueueResult
  useEffect(() => {
    onCancelQueueResult();

    // return () => {
    //   socket.removeAllListeners(["on-cancel-queue-result"]);
    // };
  }, [onCancelQueueResult]);

  const cancelQueueHandler = async () => {
    console.log("* cancelQueueHandler init");
    try {
      console.log("[cancelQueueHandler] itemRef.current: ", itemRef.current);
      const confirm = window.confirm("Confirm to delete!");
      if (confirm) {
        const sku = itemRef.current?.sku
        const filePath = "C:/Users/Public/hot";
        let result;
        if(window?.printflo_api) {
          result = await window.printflo_api._queueCancel({sku, filePath})
        } else {
          const promise = () => {
            return new Promise((resolve, reject) => {
              socket.emit("on-cancel-kothari", {sku}, (callback) => {
                console.log("[on-cancel-kothari] callback")
                if(_.isError(callback)) reject(callback)
                resolve(callback)
              })
            })
          }
          try {
            result = await promise()
          } catch (error) {
            throw error
          }
        }
        console.log("[cancelQueueHandler] result: ", result);
        setMsg(result);
      }
      
    } catch (error) {
      console.log("[cancelQueueHandler] error: ", error)
    }
  };

  const handlePrintSkuLabel = async () => {
    console.log('* handlePrintLabel init')
    // console.log('- printQtyRef: ', printQtyRef.current)
    // console.log('- itemRef: ', itemRef.current)
    let zpl = skuLabelTemplateQR({ settings, item: itemRef.current, printQty: printQtyRef.current });
    // console.log('- zpl: ', zpl)
    if(window?.printflo_api) {
      zpl && window.printflo_api._zplPrint({template: zpl});
    } else {
      zpl && socket.emit("on-sku-print", {template: zpl});
    }
    setModalConfirmIsOpen(false);
  }
  

  return (
    <>
      <Title title={title} />
      <PageMainHeader
        title={title}
        user={user}
        settings={settings}
        description={
          _.includes(selectedAutomation, "GTX")
            ? "Integrated Brother GTX Command line"
            : _.includes(selectedAutomation, "KOTHARI")
            ? "Integrated Kothari automation"
            : _.includes(selectedAutomation, "CADLINK")
            && "Integrated CADlink automation"
        }
      />
      <ToastContainer
        theme="colored"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <section className="primary queue-print">
        {sessionMsg && (
          <Banner className="mb-10" variant="warning">
            {sessionMsg}
          </Banner>
        )}
        <div className="queue-default-values-wrapper card">
          <div className="value-wrapper">
            <label htmlFor="integratedAutomation">Integrated Automation:</label>
            <select
              value={selectedAutomation ? selectedAutomation : ""}
              onChange={(e) => {
                setSelectedAutomation(e.target.value);
                selectedAutomationRef.current = e.target.value;
              }}
              id="integratedAutomation"
            >
              {integratedAutomations.map((a, index) => {
                return (
                  <option value={a?.value} key={index}>
                    {a.name}
                  </option>
                );
              })}
            </select>
          </div>
          
          { (settingsRef.current?.cadLinkQueues && (selectedAutomation && _.includes(selectedAutomation, 'CAD'))) 
            && (
              <div className="value-wrapper">
                <label htmlFor="selectedCadlinkQueue">CADlink Queue:</label>
                <select
                  value={selectedCadlinkQueue?.name ? selectedCadlinkQueue.name : ""}
                  onChange={(e) => handleSelectedCadlinkQueue(e)}
                  id="selectedCadlinkQueue"
                >
                  <option value="">== Select a queue ==</option>
                  {settingsRef.current?.cadLinkQueues.map((c, index) => {
                    return (
                      <option value={c.name} key={index}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
              </div>

            ) 
          }

          { (settingsRef.current?.maverick && (selectedAutomation && _.includes(selectedAutomation, 'MAVERICK'))) 
            && (
              <div className="value-wrapper">
                <label htmlFor="selectedMaverickQueue">MAVERICK Queue:</label>
                <select
                  value={selectedMaverickQueue?.name ? selectedMaverickQueue.name : ""}
                  onChange={(e) => handleSelectedMaverickQueue(e)}
                  id="selectedMaverickQueue"
                >
                  <option value="">== Select a queue ==</option>
                  {settingsRef.current?.maverick?.queueStations.map((c, index) => {
                    return (
                      <option value={c.name} key={index}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
              </div>

            ) 
          }

        </div>
        <div className="queue-print-info card mb-10">
          <div className="title-content-list">
            <div className="title-content">
              <span className="title">SKU</span>
              <span className="d-block content">
                {item ? item?.sku : itemDoc && itemDoc?.sku}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Description</span>
              <span>{item ? item?.name : itemDoc && itemDoc?.description}</span>
            </div>
            {item && (
              <>
                <div className="title-content">
                  <span className="title">Order Quantity</span>
                  <span>{item && item?.quantity}</span>
                </div>
                <div className="title-content">
                  <span className="title">Order No.</span>
                  <Link to={`workorder-details/${item && item?.orderId}`}>
                    <span>{item && item?.orderNumber}</span>
                  </Link>
                </div>

                <div className="title-content">
                  <span className="title">Order Date</span>
                  <span>
                    {item &&
                      moment(item?._order?.orderDate)
                        .local()
                        .format("MM-DD-YYYY")}
                  </span>
                </div>
              </>
            )}
            <div className="title-content">
              <span className="title">Component SKU</span>
              <span>
                {item ? item?._item?.component : itemDoc && itemDoc.component}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Rip Environment</span>
              <span>
                {item ? item?._item?.ripEnv : itemDoc && itemDoc.ripEnv}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Position</span>
              <span>
                {item
                  ? item?._item?.graphicPosition
                  : itemDoc && itemDoc.graphicPosition}
              </span>
            </div>
            {item && Boolean(item?.options?.length) && (
              <div className="title-content">
                <span className="title">Order Item Options</span>
                <ItemOptions options={item.options} />
              </div>
            )}
            {(itemDoc && !conditionRef.current?.consolidation) && (
              <div className="d-flex">
                <div className="title-content" >
                  <span className="title">Print Quantity</span>
                  <div className="d-flex">
                    <input
                      type="text"
                      // value={printQtyRef.current}
                      value={printQty}
                      onChange={(e) => {
                        setPrintQty(e.target.value)
                        printQtyRef.current = e.target.value;
                      }}
                      style={{ maxWidth: "70px", marginRight: 10 }}
                      className="text-center"
                    />
                    <Tooltip
                      className="lg-tooltip-container" 
                      darkMode={true}
                      trigger={
                        <IconButton
                          onClick={() => handlePrintSkuLabel()}
                          className="swing-icon"
                          aria-label="Print labels"
                        >
                          <FaQrcode />
                        </IconButton>}
                    >
                      Print labels
                    </Tooltip>

                  </div>
                </div>
                
              </div>
            )}
            
            <div className="title-content">
              <span className="title">Order Status</span>
              {!isLoadingSS ? (
                <span className="font-size-sm">
                  { ssOrderRef.current?.orderStatus 
                    ? ssOrderRef.current.orderStatus 
                    : item && item?._order?.orderStatus
                    ? item._order.orderStatus
                    : null
                  }
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
              
            </div>

            <div className="title-content">
              <span className="title">Customer Notes</span>
              {!isLoadingSS ? (
                <span className="font-size-sm">
                  {ssOrderRef.current?.customerNotes}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
              
            </div>

            <div className="title-content">
              <span className="title">Internal Notes</span>
              {!isLoadingSS ? (
                <span className="font-size-sm">
                  {ssOrderRef.current?.internalNotes}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
              
            </div>
            {item && (
              <div className="title-content">
                <span className="title">Print label</span>
                <Tooltip
                  className="lg-tooltip-container" 
                  darkMode={true}
                  trigger={
                    <IconButton
                      onClick={() => printOrderItemLabel({item, settings})}
                      className="swing-icon"
                      aria-label="Print labels"
                    >
                      <FaQrcode />
                    </IconButton>}
                >
                  Print order item label
                </Tooltip>
              </div>
            )}
              
          </div>

          <div className="queue-print-img markup">
            <img
              src={
                !_.isNil(selectedPosition)
                  ? !_.isEmpty(selectedPosition.imageUrl)
                    ? selectedPosition.imageUrl
                    : item
                    ? item?._item?.imageUrl
                    : itemDoc && itemDoc?.imageUrl
                  : item
                  ? item?._item?.imageUrl
                  : itemDoc && itemDoc?.imageUrl
              }
              className="responsive-img graphic-image-url"
              alt={item ? item?.sku : itemDoc && itemDoc?.sku}
              onError={(e) =>
                (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
              }
            />
          </div>
          <div className="queue-print-img graphic">
            {selectedPosition && (
              <>
                <img
                  src={`${preSignedUrl ? preSignedUrl : `graphics\\${selectedPosition?.graphicFileName}`}`}
                  className="responsive-img graphic-file"
                  alt={selectedPosition.graphicFileName}
                  key={preSignedUrl ? preSignedUrl : selectedPosition?.graphicFileName}
                  onError={(e) =>
                    (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
                  }
                  onLoad={() => {
                    setLoadedArtwork(true);
                  }}
                  style={{ display: !loadedArtwork ? "none" : "block" }}
                />
                {!loadedArtwork && <GridLoader color={"#09804C"} />}
              </>
            )}
          </div>
        </div>

        {graphicsRef.current && (
          <GraphicsTable
            graphics={graphicsRef.current}
            useARXfile={selectedAutomation?.toLowerCase().includes('gtx')}
            selectedAutomation={selectedAutomation}
            usePrintMode = {settingsRef.current?.usePrintMode}
          />
        )}

        {activePackingItemsRef.current && (
          <ActivePackingItemsTable
            activePackingItems={activePackingItemsRef.current}
          />
        )}

        {msg && (
          <Banner variant={bannerVariant.current} className="mb-10">
            {msg}
          </Banner>
        )}

        <div
          className={`d-flex ${
            selectedAutomation === "KOTHARI"
              ? "justify-content-space"
              : "justify-content-end"
          }`}
        >
          {selectedAutomation === "KOTHARI" && (
            <Button
              onClick={cancelQueueHandler}
              leftGlyph={<FaTrashAlt />}
              variant="danger"
              size="large"
              disabled={disableCancelQueueBtn}
            >
              Cancel Queue
            </Button>
          )}

          {!activePackingItemsRef.current && (
            <Button
              className="ml-10"
              variant="primary"
              onClick={queuePrint}
              disabled={disableQueueBtn}
              leftGlyph={<FaPrint />}
              size="large"
            >
              Queue Print
            </Button>
          )}
        </div>
        <ModalPreloader modalPreloaderIsOpen={isLoading || loading} />
        <ConfirmationModal
          open={modalConfirmIsOpen}
          onConfirm={handlePrintSkuLabel}
          onCancel={() => setModalConfirmIsOpen(false)}
          buttonText='Confirm'
          title="Confirm printing labels"
        >
          Click confirm to print labels or cancel to close
        </ConfirmationModal>
        {/* <pre>{itemRef.current && JSON.stringify(itemRef.current, null, 2)}</pre> */}
        {/* <pre>{activePackingItems && JSON.stringify(activePackingItems, null, 2)}</pre>   */}
        {/* <pre>selectedCadlinkQueue {selectedCadlinkQueue && JSON.stringify(selectedCadlinkQueue, null, 2)}</pre>   */}
        {/* <pre>selectedPositionRef.current {selectedPositionRef.current && JSON.stringify(selectedPositionRef.current, null, 2)}</pre>   */}
        {/* <pre>selectedPosition {selectedPosition && JSON.stringify(selectedPosition, null, 2)}</pre>   */}
        {/* <pre>preSignedUrl: {preSignedUrl && preSignedUrl}</pre>   */}
        {/* <pre>preSignedUrlRef: {preSignedUrlRef.current && preSignedUrlRef.current}</pre>   */}
        {/* <pre>selectedAutomationRef.current {selectedAutomationRef.current}</pre>   */}
        {/* <pre>selectedAutomation {selectedAutomation}</pre>   */}
        {/* <pre>graphicsRef.current {graphicsRef.current && JSON.stringify(graphicsRef.current, null, 2)}</pre>   */}
        {/* <pre>isSessionValid.current {isSessionValid.current}</pre>   */}
      </section>
    </>
  );
}