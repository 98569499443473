import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import WorkOrderList from "./WorkOrderList";
import WorkOrdersHeader from "./WorkOrdersHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Skeleton from "react-loading-skeleton";
// import log from "utils/log";
import { Title } from "utils/Title";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
import { ModalPreloader } from "utils/Preloader";
import Pagination from "utils/Pagination";
import Msg from "components/Msg";
import PageMainHeader from "components/PageMainHeader";
import _ from "lodash";
import Banner from "@leafygreen-ui/banner";
import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
import PageMenuHeader from "components/PageMenuHeader";
import Search from "components/Search";
import { useDebouncedCallback } from "use-debounce/lib";
import { createManualOrder, deleteLocalOrder, fetchWorkOrders, getOrderId, updateOrder } from "utils/orders";
// import { fetchShipStationOrders } from "utils/shipstation";
// import { searchByOrderNumber } from "utils/search";
import ScrollTop from "utils/ScrollTop";
import { Tabs, Tab } from "@leafygreen-ui/tabs";
import Button from "@leafygreen-ui/button";
import Icon from "@leafygreen-ui/icon";
import ManualOrderList from "./ManualOrderList";
import ModalManualOrder from "components/modals/ModalManualOrder";
import { errorHandler } from "utils/errorHandler";
import FileImportExport from "components/FileImportExport";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import { deletePackingItem } from "utils/packingItems";
import { Option, Select } from "@leafygreen-ui/select";
import { barcodeScannedHandler } from "utils/barcodeScannedHandler";
import socketIOClinet from "socket.io-client";
import axios from "axios";

const title = "Work Orders";

const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

const resetData = async () => {
  console.log("* resetData init");
  const confirm = window.confirm("All reference data will be initialized");
  if (confirm) {
    const orderOption = {
      condition: {
        orderStatus: { $in: '/awaiting/i' },
      },
      update: {
        checkedOutQty: 0,
        printedQty: 0,
        pickedQty: 0,
      },
    }

    const packingItemOption = {
      condition: { isActive: true },
      update: {
        checkedOutQty: 0,
        pickedQty: 0,
        printed: [],
      },
    }

    
    if(window?.printflo_api) {
      await window.printflo_api._updateManyOrder(orderOption)
      .then(async () => {
        await  window.printflo_api._updateManyPackingItem(packingItemOption);
      })
    } else {
      await axios.post(`/api/orders/update-many`, {...orderOption})
      .then(async () => {
        await axios.post(`/api/packing-items/update-many`, {...packingItemOption});
      })
    }

    window.location.reload();
  }
};

const pageSize = 50;
const manualOrderLimit = 50;

const initialOrder = {
  orderId: null,
  orderNumber: null,
  orderDate: new Date(),
  shipTo: {name: null},
  quantity: 0,
  pickedQty: 0,
  printedQty: 0,
  checkedOutQty: 0,
  source: 'manual',
}

const queryOptions = {
  keepPreviousData: true,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
}

const WorkOrders = ({ history }) => {
  // console.info("* Orders page init");

  const [page, setPage] = useState(1);
  const [pageManualOrders, setPageManualOrders] = useState(1);
  // const [openConfirmSyncBanner, setOpenConfirmSyncBanner] = useState(false);
  const [openConfirmAddItemBanner, setOpenConfirmAddItemBanner] = useState(false);
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [order, setOrder] = useState(initialOrder)
  const [modalManualOrderIsOpen, setModalManualOrderIsOpen] = useState(false)
  const [mode, setMode] = useState('create')
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const [msg, setMsg] = useState(null);
  const [manualOrderMsg, setManualOrderMsg] = useState(null);
  const [shipstationOrderMsg, setShipstationOrderMsg] = useState(null);
  const [selectedSearchOption, setSelectedSearchOption] = useState("orderNumber");
  // const [locationCode, setLocationCode] = useState(null);
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  
  const locationCodeRef = useRef(null);

  // console.log("user: ", user);
  // console.log("settings: ", settings);
  useEffect(() => {
    if(user && user?.locationCode) locationCodeRef.current = user.locationCode;
  }, [user])

  let {
    data: shipstation_orders,
    isLoading,
    isFetching,
    isError,
    error,
    refetch: refetchShipstationOrders,
  } = useQuery(["shipstation_orders", page], async () => await fetchWorkOrders({ source: "shipstation", page, pageSize, pagination: true, locationCode: locationCodeRef.current}), {
    ...queryOptions,
    enabled: false,
    onError: (error) => {
      console.error("[refetchShipstationOrders:onError] onError: ", error);
      console.error("[refetchShipstationOrders:onError] onError:message ", error?.message);
      error?.message && setShipstationOrderMsg(error.message)
    },
  });

  const {
    data: manual_orders,
    refetch: manulalOrderRefetch
  } = useQuery(["manual_orders", pageManualOrders], async () => await fetchWorkOrders({ source: "manual", page: pageManualOrders, limit: manualOrderLimit, pagination: true, locationCode: locationCodeRef.current}), {
    ...queryOptions,
    enabled: false,
   
  });

  // console.log(
  //   `isLoading: ${isLoading}, isError: ${isError}, isFetching: ${isFetching}`
  // );
  // console.log("error", error);
  // console.log("- workorders: ", workorders);
  // console.log("- manual_orders: ", manual_orders);
  
  const [modalPreloaderIsOpen, setModalPreloaderIsOpen] = useState(false);

  useEffect(() => {
    console.log('* settings hook init')
    if(settings) {
      // console.log('[settings hook] settings: ', settings)
      // console.log('[settings:hook:settings] : defaultOrderManagement', defaultOrderManagement)
      if(settings?.defaultOrderManagement === 'manual') {
        setSelectedTab(1)
      } else {
        setSelectedTab(0)
        refetchShipstationOrders()
      }
    }
    // eslint-disable-next-line
  }, [settings])

  useEffect(() => {
    console.log("* orders hook init");
    console.log("[orders hook] page: ", page);
    console.log("[orders hook] locationCodeRef: ", locationCodeRef);
    console.log("[orders hook] shipstation_orders: ", shipstation_orders);

    let cachedData;

    if(settings && settings?.defaultOrderManagement==='shipstation') {
      cachedData = queryClient.getQueryData(['shipstation_orders', page]);
      console.log('- cachedData: ', cachedData);
      if(_.isNil(cachedData)) {
        refetchShipstationOrders();
      } 
      else 
      {
        if (shipstation_orders?.totalPages > page) {
          queryClient.prefetchQuery(
            ["shipstation_orders", page + 1],
            () => fetchWorkOrders({ source: "shipstation", page: page + 1, pageSize, locationCode: locationCodeRef.current}),
            queryOptions
          );
        }
      }
    };

    if(settings && (settings?.defaultOrderManagement==='manual' || settings?.useManualOrderCreation) ) {
      cachedData = queryClient.getQueryData(['manual_orders', page]);
      console.log('- cachedData: ', cachedData?.docs && cachedData.docs.length);
      if(_.isNil(cachedData)) {
        manulalOrderRefetch();
      }
    }
    
    // eslint-disable-next-line
  }, [page, shipstation_orders, queryClient, settings]);

  useEffect(() => {
    console.log("* manual_orders hook init");
    if(settings?.defaultOrderManagement==='manual' || settings?.useManualOrderCreation) {
      // console.log("- pageManualOrders: ", pageManualOrders);
      msg && setMsg(null)
      // console.log("- manual_orders: ", manual_orders);
      if (manual_orders?.totalPages > pageManualOrders) {
        queryClient.prefetchQuery(
          ["manual_orders", pageManualOrders + 1],
          () => fetchWorkOrders({ orderStatus: "awaiting_shipment", source: "manual", page: pageManualOrders + 1, limit: manualOrderLimit, pagination: true, locationCode: locationCodeRef.current}),
          queryOptions
        );
      }

      if(!Boolean(manual_orders?.docs.length)) setManualOrderMsg('Orders not found')
    }
    // eslint-disable-next-line
  }, [pageManualOrders, manual_orders, queryClient]);

  const onBarcodeScanned = (data) => {
    console.log("* onBarcodeScanned init");
    console.log("[onBarcodeScanned] data: ", data);
    const { orderId } = barcodeScannedHandler(data);
    console.log("[onBarcodeScanned] orderId: ", orderId);
    orderId && history.replace(`/workorder-details/${orderId}`);
  }

  //onBarcodeScanned hook
  useEffect(() => {
    const listner = window?.printflo_api && window.printflo_api.receive("serialport_readline", onBarcodeScanned)
    
    setTimeout(() => {
      _.isNil(window?.printflo_api) && socketOnBarcodeScanned();
    }, 1000);

    return () => {
      if(listner) listner();
      socket.removeAllListeners(["on-barcode-scanned"]);
    }
    // eslint-disable-next-line
  }, []);

  const socketOnBarcodeScanned = useCallback(() => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("- on-barcode-scanned: ", data);
      const { orderId } = barcodeScannedHandler(data);
      console.log("[onBarcodeScanned] orderId: ", orderId);
      orderId && history.replace(`/workorder-details/${orderId}`);
    });
    // eslint-disable-next-line
  }, []);

  const handleOnPageChange = ({ selected }) => {
    console.log("- handleOnPageChange init");
    console.log("- selected: ", selected);
    setPage(selected + 1);
  };

  const handleOnPageChangeManualOrders = ({ selected }) => {
    console.log("- handleOnPageChangeManualOrders init");
    console.log("- selected: ", selected);
    setPageManualOrders(selected + 1);
  };

  const handleSearch = async (searchTxt) => {
    console.log("* handleSearch init");
    console.log("[handleSearch] searchTxt", searchTxt);
    console.log("[handleSearch] selectedSearchOption", selectedSearchOption);
    if (!_.isEmpty(searchTxt)) {
      let payload = {[selectedSearchOption]: searchTxt};
      setModalPreloaderIsOpen(true);
      try {
        const result = await getOrderId(payload)
        console.log("[handleSearch:getOrderId] result: ", result);
        setModalPreloaderIsOpen(false);
        if(_.isNil(result)) {
          toast.error("Not found!", {
            position: "bottom-right",
            pauseOnFocusLoss: false,
          });
          return;
        }
        if (result?.orderId) {
          toast.info(`Moving the order details: ${searchTxt}`, {
            position: "bottom-right",
            onClose: () =>
              history.replace(`/workorder-details/${result.orderId}`),
            autoClose: 2000,
          });
        } 
      } catch (error) {
        console.log("[handleSearch:getOrderId] error: ", error);
        setModalPreloaderIsOpen(false);
        toast.error("Not found!", {
          position: "bottom-right",
          pauseOnFocusLoss: false,
          // onClose: () => setSearchTxt("")
        });
        return false;
      }
       
      /* if(selectedSearchOption==="orderNumber") {
        await searchByOrderNumber(searchTxt)
          .then((result) => {
            console.log("- result: ", result);
            setModalPreloaderIsOpen(false);

            if (result?.orderId) {
              toast.info(`Moving the order details: ${searchTxt}`, {
                position: "bottom-right",
                onClose: () =>
                  props.history.replace(`/workorder-details/${result.orderId}`),
                autoClose: 2000,
              });
            }
          })
          .catch((error) => {
            console.log("- error: ", error);
            setModalPreloaderIsOpen(false);
            toast.error("Not found!", {
              position: "bottom-right",
              pauseOnFocusLoss: false,
              // onClose: () => setSearchTxt("")
            });
            return false;
          });
      } else {
        await getOrderId({bin: searchTxt})
          .then((result) => {
              console.log("- result: ", result);
              setModalPreloaderIsOpen(false);
              if (result?.orderId) {
                toast.info(`Moving the order details: ${searchTxt}`, {
                  position: "bottom-right",
                  onClose: () =>
                    history.replace(`/workorder-details/${result.orderId}`),
                  autoClose: 2000,
                });
              }
            })
          .catch((error) => {
            console.log("- error: ", error);
            setModalPreloaderIsOpen(false);
            toast.error("Not found!", {
              position: "bottom-right",
              pauseOnFocusLoss: false,
              // onClose: () => setSearchTxt("")
            });
            return false;
          });
      } */
    }    
  };

  const updateLocaOrder = useMutation(
    async ({ condition, update }) => {
      return await updateOrder({ condition, update });
    },
    {
      onSuccess: (data, variables) => {
        console.log("[updateLocaOrder:onSuccess] data: ", data);
        manulalOrderRefetch();
        
        if(settings?.defaultOrderManagement==='shipstation'){
          refetchShipstationOrders();
        }
      },
    }
  );

  const workOrderStatusHandler = useDebouncedCallback(
    ({ orderId, value }) => {
      console.log("* workOrderStatusHandler");
      console.log("- orderId: ", orderId);
      console.log("- value: ", value);
      let payload = {
        condition: { orderId },
        update: { workOrderStatus: value },
      };
      updateLocaOrder.mutate(payload);
    },
    [100]
  );

  const openModalManualOrder = ({flag, order}) => {
    console.log('* openModalManualOrder init')
    console.log('[openModalManualOrder] flag: ', flag)
    console.log('[openModalManualOrder] order: ', order)
    setMode(flag)
    msg && setMsg(null);

    if(_.isNil(flag)) {
      setMode('create')
      setOrder(initialOrder)
    } 
    else {
      setOrder(order)  
    }
    setModalManualOrderIsOpen(true)
  }

  const submitManualOrderHandler = async () => {
    console.log('* createManualOrderHandler')
    // console.log("[createManualOrderHandler] order: ", order)
    console.log("[createManualOrderHandler] mode: ", mode)
    if(mode==='create') {
      try {
        const result = await createManualOrder({...order})
        // console.log("[createManualOrderHandler:createManualOrder] result: ", result)
        setOrder(result)
        setOpenConfirmAddItemBanner(true)
      } catch (error) {
        const retVal = errorHandler(error)
        console.log("[createManualOrderHandler:createManualOrder] retVal: ", retVal)
        setMsg(retVal)
      }

    } else {
      let payload = {
        condition: { orderId: order.orderId },
        update: order
      }
      updateLocaOrder.mutate(payload);
    }

    manulalOrderRefetch()
    setModalManualOrderIsOpen(false)
  }

  const deleteManualOrderHandler = async (order) => {
    console.log('* deleteManualOrderHandler')
    // console.log("- order: ", order);
    const {orderId} = order;
    // console.log("- orderId", orderId);
    const confirm = window.confirm(
      "Confirm to remove this order. Restoring data is not available after removing an order"
    );
    if (confirm) {
      setModalManualOrderIsOpen(false)
  
      await deleteLocalOrder(orderId)
        .then(async () => {
          return await deletePackingItem(orderId);
        })
        .then(async () => {
          if (order?.bin) {
            await window.printflo_api._updateBin({
              condition: { binCode: order.bin },
              update: { $set: { isActive: false } },
            });

          }
          manulalOrderRefetch()
          setModalManualOrderIsOpen(false);
        })
        .catch(error => {
          const retVal = errorHandler(error)
          console.error('- deleteManualOrderHandler error: ', retVal)
          setModalManualOrderIsOpen(false);
          setMsg(retVal)
        })
    }
  }

  const exportManualOrderHandler = async () => {
    console.log("* exportManualOrderHandler init");
    console.log("[exportManualOrderHandler] selectedExportMode: ", selectedExportMode);

    let exportOrders;

    if (selectedExportMode === "all") {
      setSelectedExportMode("retrieved");
      await fetchWorkOrders({orderStatus: "awaiting_shipment", source: "manual", pagination: false, locationCode: locationCodeRef})
        .then(results => {
          exportOrders = results?.docs.map(order => {
            const shipTo = order.shipTo?.name
            return {...order, shipTo, orderDate: moment(order.orderDate).format('MM-DD-yyyy')}
          });
        })
    } else {
       console.log('[exportManualOrderHandler] manual_orders: ', manual_orders)
      if(manual_orders?.docs) {
        exportOrders = _.cloneDeep(manual_orders?.docs).map(order => {
          const shipTo = order.shipTo?.name
          return {...order, shipTo, orderDate: moment(order.orderDate).format('MM-DD-yyyy')}
        });

      }
    }
    console.log("[exportManualOrderHandler] exportOrders", exportOrders);
    if(!exportOrders) {
      alert('Not exists orders')
      return
    }

    let fields = [
      'orderNumber',
      'orderDate',
      'shipTo',
    ];

    if(settings?.useMultipleLocations) fields.push('locationCode');

    const result = jsonToCSV({
      fields,
      data: exportOrders
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `manual_orders_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const importManualOrderHandler = (parsedData) => {
    console.log("* importManualOrderHandler init");
    console.log("[importManualOrderHandler] parsedData: ", parsedData);

    let tempOrders = _.filter(parsedData, (order) => {
      if(!_.isEmpty(order?.data?.orderNumber)) {
        return order.data
      }
    })

    console.log("[importManualOrderHandler] tempOrders: ", tempOrders);
    let msg = `Total number of orders: ${tempOrders.length}`;
    setMsg(msg)

    let i = 0;
    let len = tempOrders.length - 1;

    async function loop() {
      let tempOrder = tempOrders[i];
      tempOrder['source'] = 'manual';
      const tempShipTo = _.clone(tempOrder?.shipTo);
      console.log('[importManualOrderHandler] tempShipTo: ', tempShipTo);

      if(!_.isNil(tempShipTo) && !_.isEmpty(tempShipTo)) {
        tempOrder.shipTo = {}
        tempOrder.shipTo.name = tempShipTo
      }
      console.log('[importManualOrderHandler] tempOrder: ', i, tempOrder);

      try {
        const result = await createManualOrder({...tempOrder})
        console.log('[importManualOrderHandler:createManualOrder] result: ', result)
        i += 1;
      } catch (error) {
        const retVal = errorHandler(error);
        console.log('[importManualOrderHandler:createManualOrder] error: ', retVal)
        setMsg(retVal)
        return
      }

      if (i <= len) {
        loop();
      } else {
        setMsg("* Completed import orders");
        manulalOrderRefetch()
        return;
      }
    }  

    if (i <= len) {
      loop();
    }
  };

  const onChangeLocationCodeHander = (v) => {
    console.log("* onChangeLocationCodeHander init");
    console.log("[onChangeLocationCodeHander] v: ", v);
    if(v==="all") v = null;
    locationCodeRef.current = v;
    console.log("[onChangeLocationCodeHander] locationCode: ", locationCodeRef)
    refetchShipstationOrders();
  }



  return (
    <>
      <Title title={title} />
      <ToastContainer theme="dark" />
      <PageMainHeader title={title} />
      <section className="primary">
        {(isLoading || isFetching)? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Msg msg={error?.message ? error.message : error} />
        ) : (
          <>
            <PageMenuHeader>
              <WorkOrdersHeader
                testLabel={settings?.testLabel}
                resetData={resetData}
                user={user}
              />
              <div className="align-right_container">
                <Select
                  className="search-select"
                  onChange={(value) => {
                    console.log("- onChange args:", value);
                    setSelectedSearchOption(value);
                  }}
                  value={selectedSearchOption}
                  placeholder="Search Options"
                  aria-labelledby="Search options"
                >
                  <Option value="orderNumber">Order Number</Option>
                  <Option value="bin">Bin</Option>
                </Select>
                <Search
                  handleSearch={handleSearch}
                  placeholderText="Search"
                />
              </div>

            </PageMenuHeader>
            {/* {msg && <Msg msg={msg} />}       */}
            <Tabs
              setSelected={setSelectedTab}
              selected={selectedTab}
              aria-label="Tabs"
            >
              {(settings?.defaultOrderManagement === 'shipstation' && !_.isNil(shipstation_orders)) && (
                <Tab name="Shipstation Orders">
                  <div className="card p-10">
                    <PageMenuHeader>
                      {user?.role === "admin" && (
                        <Button
                          variant="primary"
                          onClick={() => history.replace("/sync-orders-backend")}
                          leftGlyph={<Icon glyph="Download" />}
                          className="swing-icon"
                        > Move to Sync Orders
                        </Button>
                      )}
                      {(settings?.useMultipleLocations && settings?.locations) && (
                        <div className="input-field location-container align-items-center">
                          <label htmlFor="locationCode" className="mr-10">Location:</label>
                          <Select
                            value={locationCodeRef?.current ? locationCodeRef.current : 'all'}
                            onChange={(value) => onChangeLocationCodeHander(value)}
                            className="select-location"
                            placeholder="Location Options"
                            aria-labelledby="Location options"
                          >
                            <Option value="all">All</Option>
                            {settings?.locations.map((location) => {
                              return (
                                <Option key={location?.code} value={location?.code}>
                                  {location.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      )}
                      
                      {/* {shipstation_orders?.orders && Boolean(shipstation_orders?.orders?.length) && (
                        <Banner>
                          <FaBellSlash color="red" style={{ marginBottom: 3 }} /> This
                          icon means an order that is not yet synced.
                        </Banner>
                      )} */}
                    </PageMenuHeader>


                    {shipstation_orders?.totalPages >= 0 && (
                      <Pagination
                        handleOnPageChange={handleOnPageChange}
                        totalDocs={shipstation_orders.totalDocs}
                        totalPages={shipstation_orders.totalPages}
                        page={page}
                        limit={pageSize}
                      />
                    )}
                    {shipstationOrderMsg && <Banner className="mb-10">{shipstationOrderMsg}</Banner>}

                    {shipstation_orders && (
                      <WorkOrderList
                        orders={shipstation_orders}
                        useMultipleLocations={settings?.useMultipleLocations}
                      />

                    )}
                  </div>  
                </Tab>
              )}
              {(settings?.defaultOrderManagement === 'manual' || settings?.useManualOrderCreation) && (
                <Tab name="Manual Orders">
                  <div className="card p-10">
                    <PageMenuHeader>
                      <Button
                        variant="primary"
                        onClick={openModalManualOrder}
                        leftGlyph={<Icon glyph="Plus" />}
                        className="swing-icon"
                      >Create a manual order
                      </Button>
                      <FileImportExport
                        importHandler={importManualOrderHandler}
                        exportHandler={exportManualOrderHandler}
                        selectedExportMode={selectedExportMode}
                        setSelectedExportMode={setSelectedExportMode}
                      />
                    </PageMenuHeader>
                    {manual_orders?.totalPages > 1  && (
                      <Pagination
                        handleOnPageChange={handleOnPageChangeManualOrders}
                        totalDocs={manual_orders.totalDocs}
                        totalPages={manual_orders.totalPages}
                        page={pageManualOrders}
                        limit={manualOrderLimit}
                      />
                    )}
                    {manualOrderMsg && <Banner className="mb-10">{manualOrderMsg}</Banner>}
                    {
                      manual_orders?.docs && Boolean(manual_orders.docs.length) && (
                        <ManualOrderList
                          orders={manual_orders}
                          useWorkOrderStatus={settings?.useWorkOrderStatus}
                          workOrderStatuses={settings?.workOrderStatuses}
                          workOrderStatusHandler={workOrderStatusHandler}
                          openModalManualOrder={openModalManualOrder}
                        />
                      )
                    }
                  </div>
                </Tab>  
              )}
            </Tabs>
                

            <ScrollTop />
          </>
        )}

        

      </section>
      
      <ConfirmationModal
        open={openConfirmAddItemBanner}
        onConfirm={() => {
          if (order?.orderId) {
            toast.info(`Moving the order details: ${order.orderNumber}`, {
              position: "bottom-right",
              onClose: () =>
                history.replace(`/workorder-details/${order.orderId}?source=manual`),
              autoClose: 2000,
            });
          }
        }}
        onCancel={() => setOpenConfirmAddItemBanner(false)}
        title="Add items"
        buttonText="Confirm"
      >
        Click to confirm to add items?
      </ConfirmationModal>

      <ModalManualOrder
        title="Manual Order"
        modalIsOpen={modalManualOrderIsOpen}
        setModalIsOpen={setModalManualOrderIsOpen}
        mode={mode}
        submitHandler={submitManualOrderHandler}
        handleDelete={deleteManualOrderHandler}
        order={order}
        setOrder={setOrder}
      />

      <ModalPreloader modalPreloaderIsOpen={modalPreloaderIsOpen} />
    </>
  );
};

export default WorkOrders;
