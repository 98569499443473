import axios from "axios";
import { errorHandler } from "./errorHandler";
import _ from "lodash";

export const fetchPrintedFilms = (data) => {
  console.log("* utils:fetchPrintedFilms init");
  return new Promise(async (resolve, reject) => {
    try {
      let results;
      if (window?.printflo_api) {
        results = await window.printflo_api._fetchPrintedFilms(data);
        if (_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.post(`/api/printed-film`, data);
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(results);
    } catch (error) {
      let retval = errorHandler(error);
      console.log("[utils:fetchPrintedFilms] retval: ", retval);
      reject(retval);
    }
  });
};

export const updatePrintedFilm = (data) => {
  console.log("* utils:updatePrintedFilm init");
  return new Promise(async (resolve, reject) => {
    try {
      console.log("[utils:updatePrintedFilm] data: ", data);
      let result;
      if (window?.printflo_api) {
        result = await window.printflo_api._updatePrintedFilm(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/printed-film/update`, data);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log("[utils:updatePrintedFilm] retval: ", retval);
      reject(retval);
    }
  });
};

export const deletePrintedFilm = (data) => {
  console.log("* utils:deletePrintedFilm init");
  return new Promise(async (resolve, reject) => {
    try {
      console.log("[utils:deletePrintedFilm] data: ", data);
      let result;
      if (window?.printflo_api) {
        result = await window.printflo_api._deletePrintedFilm(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/printed-film/delete`, data);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log("[utils:deletePrintedFilm] retval: ", retval);
      reject(retval);
    }
  });
};

export const updatePrintedFilmStock = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result;
      if (window?.printflo_api) {
        result = await window.printflo_api._updatePrintedFilmStock(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(
            `/api/printed-film/update-stock`,
            data
          );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log("-retval: ", retval);
      reject(retval);
    }
  });
};
