import React from "react";
import _ from "lodash";
import moment from "moment";

export default function PickingListPrint({ components, finishedGoods, componentsPF, TotalPickupQty, filmInventoryAreaCodes, locationCode, settings }) {
  // console.log('* CollectionItemsPrint init')
  // console.log("- components", components);
  // console.log("- finishedGoods", finishedGoods);
  // console.log("[PickingListPrint] componentsPF", componentsPF);
  // console.log("[PickingListPrint] locationCode", locationCode);

  const FilmInventoryAreaCodes = ({packingDocs}) => {
    const areaCodes = filmInventoryAreaCodes(packingDocs);
    // console.log("[PickingListPrint:FilmInventoryAreaCodes] areaCodes: ", areaCodes)
    const groupedAreaCodes = _.groupBy(areaCodes, 'graphic');
    // console.log("[FilmInventoryAreaCodes] groupedAreaCodes: ", groupedAreaCodes)
    const temp = Object.keys(groupedAreaCodes).map((areaCode) => {
      // console.log(areaCode)
      const sumQty = _.sumBy(groupedAreaCodes[areaCode], "quantity")
      // console.log("[FilmInventoryAreaCodes] areaCode: ", areaCode, sumQty)

      return {areaCode, sumQty}
    })

    // console.log("[FilmInventoryAreaCodes] temp: ", temp)
    const doms = temp.map((a, index) => {
      return <span key={index} style={{ border: "thin solid grey", padding: "2px", borderRadius: "2px", marginRight: "2px" }}>{a?.areaCode}, {a?.sumQty}</span>
    }) 

    return doms

  }

  function TableRow({ state, index, type }) {
    const { packing_docs } = state
    const ordered = _.sumBy(packing_docs, 'quantity')
    const picked = _.sumBy(packing_docs, 'pickedQty')
    return (
      <tr className={ordered<=picked ? 'hidden' : ''}>
        <td>{index + 1}</td>
        <td>{state?.sku ? state.sku : 'undefined'}</td>
        {
          type !== 'fg' ? (
            <td>
              {state?.inventoryArea}
            </td>
          
          ) : (
            <td>
              {state?.picking_suggests.map(p => {
                return (
                  <span key={p.areaCode} style={{ border: "thin solid grey", padding: "2px", borderRadius: "2px", marginRight: "2px" }}>
                    {p?.areaCode}: {p?.picking_qty}
                  </span>
                )
              })}
            </td>
          )
        }
        {type !== "fg" && <td>{state?.gp}</td>}
        {type === "pf" && (
          <td>
            {
              <FilmInventoryAreaCodes packingDocs={state?.packing_docs} />
            }
          </td>
        )}
        <td className="text-center">
          {ordered - picked}
        </td>
        <td>{state?.category}</td>
        <td>{state?.color}</td>
      </tr>
    )
  }

  const PickingListTable = ({ states, type }) => {
    return (
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th style={{ minWidth: "100px" }}>SKU</th>
            <th>Location</th>
            {type !== "fg" && <th>Position</th>}
            {type === 'pf' && <th>Printed Film Inventory Location</th>}
            <th>Quantity</th>
            <th>Category</th>
            <th>Color</th>
          </tr>
        </thead>
        <tbody>
          {states.map((state, index) => {
            return (
              <TableRow state={state} index={index} type={type} key={index} />
            );
          })}
        </tbody>
      </table>
    )
  }

  const LocationDate = () => {
    return (
      <div>
        {settings?.useMultipleLocations && (
          <span>{_.find(settings?.locations, {code: locationCode})?.name}</span>
        )}
        <span style={{display: "inline-block", marginLeft: "10px"}}>{moment().local().format("MM-DD-YYYY h:mm a")}</span>
      </div>
    )
  }

  return (
    // <div id="print-section" >
    <div id="print-section" className="hidden">
      <div
        className="print-section-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <h3>Picking List</h3>
      </div>
      <div>
        {components && Boolean(components.length) &&
          <div style={{
            pageBreakAfter: "always"
          }}>
            <h3>Components</h3>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}>
              <TotalPickupQty states={components} />
              <LocationDate />
            </div>
            <PickingListTable states={components} type={ 'component' }/>
          </div>
        }
        {componentsPF && Boolean(componentsPF.length) &&
          <div style={{
            pageBreakAfter: "always"
          }}>
            <h3>Components/Printed Films</h3>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}>
              <TotalPickupQty states={componentsPF} />
              <LocationDate />
            </div>
            
            <PickingListTable states={componentsPF} type={ 'pf' }/>

          </div>
        }
        {finishedGoods && Boolean(finishedGoods.length) &&
          <div style={{
            pageBreakAfter: "always"
          }}>
            <h3>Finished Goods</h3>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}>
              <TotalPickupQty states={finishedGoods} />
              <LocationDate />

            </div>
            
          <PickingListTable states={finishedGoods} type={ 'fg' }/>

          </div>
        }
        
      </div>
    </div>
  );
}