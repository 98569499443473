import React from 'react'
import Tooltip from '@leafygreen-ui/tooltip'
import IconButton from '@leafygreen-ui/icon-button'
import { FaEdit, FaPrint } from 'react-icons/fa'
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import { useHistory } from 'react-router-dom';

export default function PrintedFilmsInventoryList({ items, showModal, handleReplenishment }) {
  // console.log('* FinishedGoodsInventoryList init')
  // console.log('- items: ', items)
  const history = useHistory()
  const columns = [
    <TableHeader label="Graphic file name(Film Id)" sortBy={(datum) => datum.graphicFilename} />,
    <TableHeader label="Inventory Area" sortBy={(datum) => datum.areaCode}/>,
    <TableHeader label="In stock" dataType="number" sortBy={(datum) => datum.stock}/>,
    <TableHeader label="Low Stock Threshold" dataType="number" sortBy={(datum) => datum.lowStock}/>,
    <TableHeader label="Anticipated Stock" dataType="number" sortBy={(datum) => datum.anticipatedStock}/>,
    <TableHeader label="Replenishment" dataType="number" />,
    <TableHeader label="Action" />,
  ];


  const handlePrintQueue = (datum) => {
    console.log('* handlePrintQueue init')
    console.log('[handlePrintQueue] datum: ', datum)
    let url = `/queue-print-graphic?graphicFilename=${datum?.graphicFilename}&printQty=${datum?.replenishment}&printedFilms=1`;
    history.push(url);
  }
    
  return (
    <Table data={items} columns={columns}>
      {({ datum }) => {
        // console.log("- datum: ", datum);
        return (
          <Row key={datum._id}>
            <Cell>{datum?.graphicFilename ? datum.graphicFilename : ""}</Cell>
            <Cell>{datum?.areaCode ? datum.areaCode : ""}</Cell>
            <Cell>{datum?.stock ? datum.stock : 0}</Cell>
            <Cell>{datum?.lowStock ? datum.lowStock : 0}</Cell>
            <Cell>{datum?.anticipatedStock ? datum.anticipatedStock : 0}</Cell>
            <Cell>
              <div className='input-field' style={{maxWidth: 50, marginBottom: 0}}>
                <input type='text' 
                  value={datum?.replenishment ? datum.replenishment : 0} 
                  className='text-right'
                  onChange={(e) => {
                    handleReplenishment(e, datum)
                  }}
                />
              </div>
            </Cell>
            <Cell>
              <Tooltip
                align="top"
                justify="start"
                triggerEvent="hover"
                aria-labelledby="Edit"
                className='lg-tooltip-container'
                darkMode={true}
                trigger={  
                  <IconButton 
                    className="swing-icon"
                    aria-labelledby="Edit"
                    onClick={() => showModal("_updatePrintedFilm", datum)}
                  >
                    <FaEdit />
                  </IconButton>
                }
              >
                Edit
              </Tooltip>
              <Tooltip
                align="top"
                justify="start"
                className="lg-tooltip-container"
                darkMode={true}
                trigger={
                  <IconButton
                    onClick={() => handlePrintQueue(datum)}
                    className="swing-icon"
                    aria-label="Queue print"
                  >
                    <FaPrint />
                  </IconButton>
                }
                triggerEvent="hover"
              >
                Queue print
              </Tooltip>
            </Cell>
          </Row>
        )
      }}
    </Table>

    // <ReactTableContainer
    //   columns={columns}
    //   data={items}
    //   hiddenColumns={hiddenColumns}
    //   sortBy={[{ id: "sku" }, { id: "anticipatedStock" } ]}
    // />
  )
}
