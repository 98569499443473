import React from "react";
import _ from "lodash";
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import { FaEdit } from "react-icons/fa";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";

export default function GraphicsList({
  graphics,
  setSelectedGraphic,
  showGraphicModal,
  initialGraphics,
  media,
  useRecipe,
  usePrintMode,
  useHFtemplate,
  defaultIntegratedAutomation
}) {
  // console.log('* GraphicsList init')
  // console.log('- graphics: ', graphics)
  // console.log('- media: ', media)
  console.log('- defaultIntegratedAutomation: ', defaultIntegratedAutomation)
  console.log('- useHFtemplate: ', useHFtemplate)

  const columns = [
    <TableHeader label="SKU" sortBy={(datum) => datum.sku} />,
    <TableHeader label="Graphic File Name" />,
    <TableHeader
      label="ARX File Name"
      className={defaultIntegratedAutomation && !defaultIntegratedAutomation.includes("GTX") ? "hidden" : ""}
    />,
    <TableHeader label="Graphic Position" />,
    <TableHeader label="Platen" />,
    <TableHeader label="Size" />,
    <TableHeader label="Rip Env" className={defaultIntegratedAutomation && !defaultIntegratedAutomation.includes('KOTHARI') ? "hidden" : ""}/>,
    <TableHeader label="Media(CADlink)" className={media && Boolean(media.length) ? "" : "hidden"} />,
    <TableHeader label="Print Mode(CADlink)" className={usePrintMode ? "" : "hidden"} />,
    <TableHeader label="Recipe/Polaris" className={!useRecipe ? "hidden" : ""} />,
    <TableHeader label="HF Template/Maverick" className={!useHFtemplate ? "hidden" : ""} />,
    <TableHeader label="Action" />,
  ];


  return (
    <>
      {Boolean(graphics.length) ? (
        <Table data={graphics} columns={columns}>
          {({ datum: graphic }) => (
            <Row key={graphic._id}>
              <Cell>{graphic.sku}</Cell>
              <Cell>
                {graphic?.graphicFileName ? graphic.graphicFileName : ""}
              </Cell>
              <Cell className={defaultIntegratedAutomation && !defaultIntegratedAutomation.includes("GTX")? "hidden" : ""}>
                {graphic?.arxFileName ? graphic.arxFileName : ""}
              </Cell>
              <Cell>
                {graphic?.graphicPosition ? graphic.graphicPosition : ""}
              </Cell>
              {/* <Cell className={useRecipe ? "hidden" : ""}>{graphic?.ripEnv ? graphic.ripEnv : ""}</Cell> */}
              <Cell>{graphic?.platen ? graphic.platen : ""}</Cell>
              <Cell>
                {!_.isNil(graphic?.size?.width)
                  ? `width: ${graphic?.size?.width}`
                  : !_.isNil(graphic?.size?.height)
                  ? `height: ${graphic?.size?.height}`
                  : ""}
              </Cell>
              <Cell className={defaultIntegratedAutomation && !defaultIntegratedAutomation.includes('KOTHARI') ? "hidden" : ""}>
                {graphic?.ripEnv ? graphic.ripEnv : ""}
              </Cell>
              <Cell className={media && Boolean(media.length) ? "" : "hidden"}>{graphic?.media ? graphic.media : ""}</Cell>}
              <Cell className={!usePrintMode ? "hidden" : ""}>{graphic?.printMode ? graphic.printMode : ""}</Cell>
              <Cell className={!useRecipe ? "hidden" : ""}>{graphic?.polarisRecipe ? graphic.polarisRecipe : ""}</Cell>
              <Cell className={!useHFtemplate ? "hidden" : ""}>{graphic?.hfTemplate ? graphic.hfTemplate : ""}</Cell>
              <Cell>
                <div className="action-btn-list">
                  <Tooltip
                    className="lg-tooltip-container"
                    darkMode={true}
                    align="left"
                    justify="start"
                    triggerEvent="hover"
                    aria-labelledby="Edit"
                    trigger={
                      <IconButton
                        className="swing-icon"
                        aria-labelledby="Edit"
                        onClick={() => {
                          // setSelectedGraphic({...initialGraphics, ...row.original})
                          showGraphicModal("update", {
                            ...initialGraphics,
                            ...graphic,
                          });
                        }}
                      >
                        <FaEdit />
                      </IconButton>
                    }
                  >
                    Edit
                  </Tooltip>
                </div>
              </Cell>
            </Row>
          )}
        </Table>
      ) : (
        <span>Graphics not found</span>
      )}
    </>
  );
}
