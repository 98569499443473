import React from "react";
import Modal from "react-modal";
import GridLoader from "react-spinners/GridLoader";

Modal.setAppElement("#root");

export function Preloader({size}) {
  // console.log("size: ", size);
  return (
    <GridLoader color={"#09804C"} size={size}/>
  );
}

export function ModalPreloader({ modalPreloaderIsOpen, afterOpenModalPreloader, closeModalPreloader }) {
  // console.log("modalPreloaderIsOpen: ", modalPreloaderIsOpen)
  if (typeof modalPreloaderIsOpen === "number") {
    modalPreloaderIsOpen = Boolean(modalPreloaderIsOpen);
  }
  return(
    <Modal
      isOpen={modalPreloaderIsOpen}
      onAfterOpen={afterOpenModalPreloader}
      onRequestClose={closeModalPreloader}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "transparent",
          border: "none",
          overflow: "hidden"
        },
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0, .70)",
          zIndex: "9999"
        },}}
      contentLabel="Modal Preloader"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Preloader />
    </Modal>
  )
}

