import _ from "lodash";

export const barcodeScannedHandler = (data) => {
  console.log("* utils:barcodeScannedHandler init");
  console.log("[utils:barcodeScannedHandler] data: ", data);

  if (data) {
    let dataArr = [];
    let delimiter = ";";
    if (_.includes(data, "*")) delimiter = "*";
    // console.log("[utils:barcodeScannedHandler] delimiter: ", delimiter);
    dataArr = data.split(delimiter);
    const sku = dataArr[0];
    const orderId = parseInt(dataArr[1]);
    const orderItemId = dataArr[2];
    const position = dataArr[3];
    // console.log("[utils:barcodeScannedHandler] sku: ", sku);
    // console.log("[utils:barcodeScannedHandler] orderId: ", orderId);
    // console.log("[utils:barcodeScannedHandler] orderItemId: ", orderItemId);
    // console.log("[utils:barcodeScannedHandler] position: ", position);
    return { sku, orderId, orderItemId, position };
  }
};
