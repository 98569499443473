import _ from "lodash";
import { serializeError } from "serialize-error";

export const errorHandler = (error) => {
  console.error("* utils:errorHandler init");
  // console.log("[utils:errorHandler] error(arg): ", error, _.isObject(error));
  console.log("[utils:errorHandler:error] isObject: ", _.isObject(error));
  let parsedErr;
  if (_.isObject(error)) {
    parsedErr = error;
  } else {
    parsedErr = serializeError(error);
  }
  if (parsedErr) {
    console.log("[utils:errorHandler] parsedError: ", parsedErr);
    console.log(
      "[utils:errorHandler:parsedError] response: ",
      parsedErr?.response
    );
    console.log(
      "[utils:errorHandler:parsedError] data: ",
      parsedErr?.response?.data
    );

    if (parsedErr?.response?.data?.message) {
      return parsedErr.response.data.message;
    } else if (parsedErr?.message) {
      return parsedErr?.message;
    } else {
      return parsedErr;
    }
  } else {
    return "Unknown error occured";
  }
};
