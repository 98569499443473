import React, { useEffect, useState } from "react";
import { fetchItemsSearch } from "utils/items";
import Autosuggest from "react-autosuggest";
import Switch from "react-switch";
import ModalTemplate from "./ModalTemplate";
import { FaSave, FaSpinner, FaWindowClose } from "react-icons/fa";
import Button from "@leafygreen-ui/button";
import { submitItem } from "utils/items";
import _ from "lodash";

const OrderItemModal = ({
  modalIsOpen,
  setModalIsOpen,
  item,
  editOrderItemSubmitHandler,
  title,
  mode,
}) => {
  // console.log("* OrderItemModal init");
  // console.log("[OrderItemModal] mode: ", mode);
  // console.log("[OrderItemModal] item: ", item);
  const [searchResults, setSearchResults] = useState([]);
  const [orderItem, setOrderItem] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (item) setOrderItem(item);
  }, [item]);

  const onChangeHandler = (e) => {
    console.log(
      `id: ${e.target.id}, value: ${e.target.value}, typeof: ${typeof e.target
        .value}`
    );
    setOrderItem({ ...orderItem, [e.target.id]: e.target.value });
  };

  const getSuggestionValue = (suggestion) => {
    console.log("[OrderItemModal:getSuggestionValue] suggestion: ", suggestion);
    if (suggestion) return suggestion.sku;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    // console.log("* OrderItemModal:onSuggestionsFetchRequested init");
    // console.log("[OrderItemModal:onSuggestionsFetchRequested] value", value);
    setSearchLoading(true);
    fetchItemsSearch({
      searchTxt: value,
      options: { page: 1, limit: 1000 },
      type: "production",
    }).then(({ docs }) => {
      // console.log("[OrderItemModal:onSuggestionsFetchRequested] docs", docs);
      setSearchResults(docs);
      setSearchLoading(false);
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    // console.log("clear");
    setSearchResults([]);
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    setOrderItem({ ...item, sku: suggestion.sku, _item: suggestion });
  };

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "400px", height: "auto" }}
    >
      <form className="card">
        <div className="input-field">
          <div className="d-flex justify-content-space">
            <label htmlFor="sku">SKU*</label>
            {searchLoading && <FaSpinner className="spinner" />}
          </div>
          <Autosuggest
            suggestions={searchResults}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={(suggestion) => <span>{suggestion.sku}</span>}
            inputProps={{
              placeholder: "SKU*",
              id: "sku",
              value: orderItem?.sku ? orderItem.sku : "",
              onChange: (e) => onChangeHandler(e),
            }}
            shouldRenderSuggestions={(value) => {
              // console.log(`shouldRenderSuggestions value: ${value}`);
              return value.trimLeft().length > 4;
            }}
            highlightFirstSuggestion={true}
          />
        </div>
        <div className="input-field">
          <label
            htmlFor="quantity"
            className={orderItem?.quantity ? "active" : ""}
          >
            Ordered Quantity
          </label>
          <input
            type="text"
            id="quantity"
            onChange={(e) => onChangeHandler(e)}
            value={orderItem?.quantity ? orderItem.quantity : ""}
          />
        </div>
        <div className="input-field">
          <label htmlFor="pickedQty">Picked</label>
          <input
            type="text"
            id="pickedQty"
            onChange={(e) => onChangeHandler(e)}
            value={orderItem?.pickedQty ? orderItem.pickedQty : ""}
          />
        </div>
        <div className="input-field">
          <label htmlFor="checkedOutQty">Checked-out</label>
          <input
            type="text"
            id="checkedOutQty"
            onChange={(e) => onChangeHandler(e)}
            value={orderItem?.checkedOutQty ? orderItem.checkedOutQty : ""}
          />
        </div>
        <div className="switch-wrapper">
          <label className="d-flex align-items-center">
            <span>Active</span>
            <Switch
              checked={orderItem?.isActive ? orderItem.isActive : false}
              id="isActive"
              className="is-active-wrapper"
              onChange={(checked, e, id) => {
                setOrderItem({ ...orderItem, isActive: checked });
              }}
              onColor="#09804C"
            />
          </label>
        </div>

        <div className="modal-footer">
          <Button
            variant="danger"
            className="swing-icon"
            onClick={() => setModalIsOpen(false)}
            leftGlyph={<FaWindowClose />}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="swing-icon"
            onClick={async (e) => {
              e.preventDefault();
              console.log("[submit] orderItem: ", orderItem);

              if (
                orderItem?._item &&
                orderItem?.sku === orderItem?._item?.sku
              ) {
                editOrderItemSubmitHandler(orderItem);
                setOrderItem(null);
              } else {
                console.log("[submit] orderItem?._item not exists");
                if (!_.isNil(orderItem?.sku) && !_.isEmpty(orderItem?.sku)) {
                  const confirm = window.confirm(
                    `${orderItem?.sku} is not existed, do you want to save the SKU?`
                  );
                  if (confirm) {
                    let sku = orderItem?.sku;
                    if (sku) {
                      const result = await submitItem({
                        condition: { sku },
                        update: {
                          sku,
                          category: null,
                          color: null,
                          component: null,
                          childSKUs: [],
                          description: null,
                          dimensionalUnit: 1,
                          imageUrl: null,
                          inventoryArea: null,
                          graphicPosition: null,
                          priority: null,
                          ripEnv: null,
                          stock: 0,
                          basicStock: 0,
                          anticipatedStock: 0,
                          size: null,
                          pretreatmentBarcode: null,
                          recipe: null,
                          type: "production",
                          createdAt: new Date(),
                          modifiedAt: new Date(),
                        },
                      });
                      console.log("[submitItem] result: ", result);
                      if (result) orderItem["_item"] = result;
                      editOrderItemSubmitHandler(orderItem);
                      setOrderItem(null);
                    }
                  }
                }
              }
            }}
            leftGlyph={<FaSave />}
          >
            Submit
          </Button>
        </div>
      </form>
    </ModalTemplate>
  );
};

export default React.memo(OrderItemModal);
