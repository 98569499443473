import React from 'react'
import ReactTableContainer from 'utils/ReactTableContainer'
import { Link } from 'react-router-dom'
import { FaEdit, FaPallet, FaQrcode } from 'react-icons/fa'
import IconButton from '@leafygreen-ui/icon-button'
import Tooltip from '@leafygreen-ui/tooltip'

function ProductionsList({ items, showModal, printSKULabel, settings }) {
  // console.log('* ProductionsList init')
  // console.log('- items: ', items)
  // console.log('- settings: ', settings)
  const hiddenColumns = ["_id"]
  !settings?.usePretreatmentBarcode && hiddenColumns.push("pretreatmentBarcode")
  !settings?.useRecipe && hiddenColumns.push("polarisRecipe")
  !settings?.useHFtemplate && hiddenColumns.push("hfTemplate")
  // console.log('- hiddenColumns: ', hiddenColumns)
  const columns = React.useMemo(
    () => [
      { Header: "id", accessor: "_id" },
      { Header: "SKU", accessor: "sku"},
      { Header: "Category", accessor: "category" },
      { Header: "Child SKUs", accessor: "childSKUs",
        Cell: ({row}) => {
          return <span className='inline'>{row?.values.childSKUs.join(", ")}</span> 
        }
      },
      { Header: "Component", accessor: "component" },
      { Header: "Graphic Position", accessor: "graphicPosition" },
      { Header: "Rip Env", accessor: "ripEnv" },
      { Header: "Recipe(Polaris)", accessor: "polarisRecipe" },
      { Header: "HF Template(Maverick)", accessor: "hfTemplate" },
      { Header: "Pretreatment Barcode", accessor: "pretreatmentBarcode" },
      {
        Header: "Stock", accessor: "stock"
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <div className="action-btn-list">
              <Tooltip
                align="left"
                justify="start"
                triggerEvent="hover"
                aria-labelledby="Edit"
                darkMode={true}
                className='lg-tooltip-container'trigger={  
                  <IconButton 
                    className="swing-icon"
                    aria-labelledby="Edit"
                    onClick={() => showModal("update", row.original)}
                  >
                    <FaEdit />
                  </IconButton>
                }
              >
                Edit
              </Tooltip>
              {
                settings?.useInventoryModule && (
                  <Tooltip
                    align="top"
                    justify="start"
                    triggerEvent="hover"
                    aria-labelledby="View inventory transactions"
                    darkMode={true}
                    className='lg-tooltip-container'
                    trigger={  
                      <IconButton 
                        className="swing-icon"
                        aria-labelledby="View inventory transactions icon"
                      >
                        <Link
                          to={{
                            pathname: "/inventory-transactions",
                            search: `?sku=${row?.values?.sku}`,
                          }}
                        >
                          <FaPallet color="#89979B"/>
                        </Link>
                      </IconButton>
                    }
                  >
                    View inventory transaction
                  </Tooltip>
                )
              }
              
              
              <Tooltip
                align="top"
                justify="start"
                triggerEvent="hover"
                aria-labelledby="Print a SKU label"
                darkMode={true}
                className='lg-tooltip-container'trigger={
                  <IconButton
                    onClick={() => printSKULabel(row.original)}
                    className="swing-icon"
                    aria-labelledby="Print a SKU label icon"
                  >
                    <FaQrcode className="m-0" />
                  </IconButton>
                }
              >
                Print a SKU label
              </Tooltip>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  )
  return (
    <ReactTableContainer
      columns={columns}
      data={items}
      hiddenColumns={hiddenColumns}
      sortBy={[{ id: "sku" }]}
    />
  
  )
}

export default React.memo(ProductionsList)
