import React from 'react'
import _ from "lodash";
import { deleteInventoryArea, submitInventoryArea } from 'utils/inventoryArea';
import Tooltip from '@leafygreen-ui/tooltip';
import IconButton from '@leafygreen-ui/icon-button';
import { FaEdit, FaTimes, FaSave } from "react-icons/fa";
import { toast } from 'react-toastify';

const initialInventoryArea = {
  areaCode: "",
  stock: 0,
  sku: ""
}

export default function MappedInventoryTable({item, locationCode}) {
  console.log('* MappedInventoryTable init')
  // console.log('- item: ', item)
  // console.log('- locationCode: ', locationCode)
  const [ inventoryAreas, setInventoryAreas ] = React.useState([]);

  React.useEffect(() => {
    // console.log('- item: ', item)
    if(item?._inventoryArea && Boolean(item?._inventoryArea.length)) {
      if(locationCode) {
        let matchedInventoryArea = _.filter(item._inventoryArea, {locationCode})
        console.log('- matchedInventoryArea: ', matchedInventoryArea)
        setInventoryAreas(matchedInventoryArea);
      } else {
        setInventoryAreas(item._inventoryArea);
      }

    }
    // eslint-disable-next-line
  }, [item])

  const onChangeHandler = ({id, value, index}) => {
    // console.log("== onChangeHandler init");
    // console.log('- id: ', id);
    // console.log('- value: ', value);
    // console.log('- index ', index );
    setInventoryAreas(_inventoryArea => {
      _inventoryArea[index][id] = value
      return [..._inventoryArea]
    })
  }

  const deleteInventoryAreaHandler = (inventoryArea, rowId) => {
    console.log('* MappedInventoryTable:deleteInventoryAreaHandler init')
    console.log('[MappedInventoryTable:deleteInventoryAreaHandler] inventoryArea: ', inventoryArea)
    console.log('[MappedInventoryTable:deleteInventoryAreaHandler] rowId: ', rowId)
    
    function removeRow(rowId) {
      let graphicsRowElem = document.getElementById(rowId);
      // console.log('-graphicsRowElem: ', graphicsRowElem)
      graphicsRowElem.remove();

      // let temp = _.cloneDeep(graphics)
      // let filtered = _.filter(temp, (t) =>  t.graphicPosition !== graphic.graphicPosition)
      // console.log('-filtered: ', filtered)
      // setGraphics([...filtered]);
      let graphicsRowElems = document.querySelectorAll(".inventoryareas-row");
      // console.log('-graphicsRowElems.length: ', graphicsRowElems.length);
      if(graphicsRowElems.length===0) setInventoryAreas([])
    }
    
    if(!inventoryArea?._id) {
      removeRow(rowId)
    } else {
      const confirm = window.confirm('Confirm to delete the inventory area!');
      if(confirm) {
        
        deleteInventoryArea({_id: inventoryArea._id, sku: inventoryArea?.sku, areaCode: inventoryArea?.areaCode, locationCode: inventoryArea?.locationCode})
          .then(result => {
            console.log('[MappedInventoryTable:deleteInventoryAreaHandler] result: ', result);
            removeRow(rowId)
          })
          .catch(error => {
            console.error('[MappedInventoryTable:deleteInventoryAreaHandler] error: ', error);
            toast.error(error, {
              position: "bottom-right"
            })
          })
      }
    }
  }

  const submitHandler = async (index) => {
    console.log("* MappedInventoryTable:submitHandler init")
    // console.log("[MappedInventoryTable:submitHandler] index", index);
    const inventoryArea = inventoryAreas[index]
    // console.log("[MappedInventoryTable:submitHandler] inventoryArea", inventoryArea);
    
    const { _id, sku, areaCode } = inventoryArea
    let query = { _id, sku, areaCode };
    if(locationCode){
      query["locationCode"] = locationCode;
      inventoryArea["locationCode"] = locationCode;
    } 

    await submitInventoryArea({
      condition: query,
      update: inventoryArea,
    }).then(result => {
      console.log('[MappedInventoryTable:submitHandler] result: ', result);
      let temp = _.cloneDeep(inventoryAreas)
      let idx = _.findIndex(temp, {areaCode: result.areaCode});
      console.log('[MappedInventoryTable:submitHandler] idx: ', idx)
      if(idx === -1) {
        temp.push(result)
      } else {
        temp[idx] = result
      }
      setInventoryAreas([...temp]);
    }).catch(error => {
      console.error('- submitHandler error ', error);
      toast.error(error, {
        position: "bottom-right"
      })
    })

  };

  return (
    <>
      <div className='d-flex align-items-center'>
        <span className='block font-weight-bold font-size-sm'>Inventories</span>
        <Tooltip
          align="right"
          justify="start"
          triggerEvent="hover"
          aria-labelledby="Add an inventory"
          darkMode={true}
          className='lg-tooltip-container'
          popoverZIndex={99999}
          trigger={
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                setInventoryAreas(current => [...current, {initialInventoryArea, sku: item.sku}]);
              }}
              className="swing-icon"
              aria-labelledby="Add an inventory icon"
            >
              <FaEdit className="m-0" />
            </IconButton>
          }
        >
          Add an inventory
        </Tooltip>
      </div>

      {
        (Boolean(inventoryAreas.length)) ? 
        (
          <table className='inventoryarea-table'>
            <thead>
              <tr>
                <th className='text-left'>Area Code</th>
                <th className='text-left'>Stock</th>
                <th className='text-left'>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                inventoryAreas.map((inventory, index) => {
                  return (
                    <tr key={index} className="inventoryareas-row" id={`inventoryarea-row-${index}`}>
                      <td style={{maxWidth: 60}}>
                      <input type="text" id="areaCode" defaultValue={inventory?.areaCode ? inventory.areaCode : ""} onChange={(e) => onChangeHandler({id: e.target.id, value: e.target.value, index})} />
                      </td>
                      <td><input type="text" id="stock" defaultValue={inventory?.stock ? inventory.stock : ""} onChange={(e) => onChangeHandler({id: e.target.id, value: e.target.value, index})}/></td>
                      <td style={{maxWidth: 70}} className="text-center">
                        <Tooltip
                          align="right"
                          justify="start"
                          triggerEvent="hover"
                          aria-labelledby="Save an inventory"
                          darkMode={true}
                          className='lg-tooltip-container'
                          popoverZIndex={99999}
                          trigger={
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                submitHandler(index)
                              }}
                              className="swing-icon"
                              aria-labelledby="Save an inventory icon"
                            >
                              <FaSave className="m-0" />
                            </IconButton>
                          }
                        >
                          Save
                        </Tooltip>
                        <Tooltip
                          align="right"
                          justify="start"
                          triggerEvent="hover"
                          aria-labelledby="Delete an inventory"
                          darkMode={true}
                          className='lg-tooltip-container'
                          popoverZIndex={99999}
                          trigger={
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                deleteInventoryAreaHandler(inventory, `inventoryarea-row-${index}`)
                              }}
                              className="swing-icon"
                              aria-labelledby="Delete an inventory icon"
                            >
                              <FaTimes className="m-0" />
                            </IconButton>
                          }
                        >
                          Delete
                        </Tooltip>
                      </td>
                    </tr>
                  )
                }) 
              }
            </tbody>
          </table>
        ) : (
        <span className='font-size-sm'>- No inventory found</span>
        )
      }
      {/* <pre>{JSON.stringify(inventoryAreas, null, 2)}</pre> */}
    </>

  )
}
