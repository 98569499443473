import React, { useState, useEffect, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import _ from "lodash";
import { SettingsContext } from "contexts/SettingsContext";
import Pagination from "utils/Pagination";
import { Title } from "utils/Title";
import { errorHandler } from "utils/errorHandler";
import ItemsHeader from "./ItemsHeader";
import {
  deleteItem,
  fetchItems,
  fetchItemsSearch,
  submitItem,
} from "utils/items";
import ComponentsList from "./ComponentsList";
import ModalComponent from "components/modals/ModalComponent";
import { AuthContext } from "contexts/AuthContext";
import PageMainHeader from "components/PageMainHeader";
import PageMenuHeader from "components/PageMenuHeader";
import Search from "components/Search";
import Banner from "@leafygreen-ui/banner";
import ScrollTop from "utils/ScrollTop";
import { ModalPreloader } from "utils/Preloader";
import { barcodeScannedHandler } from "utils/barcodeScannedHandler";
import socketIOClient from "socket.io-client";

const title = "Components";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const initialItem = {
  sku: "",
  description: "",
  category: "",
  stock: "",
  inventoryArea: "",
  createdAt: "",
  modifiedAt: "",
  priority: "",
  type: "component",
};

const initialLimit = 50;
const selectedItemsType = "component";

const useQueryOptions = {
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 1,
};

export default function Components() {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(initialItem);
  const [mode, setMode] = useState("create");
  const [msg, setMsg] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState(null);
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const [limit, setLimit] = useState(initialLimit);

  const queryClient = useQueryClient();
  // console.log("- settings: ", settings);

  const {
    data: items,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useQuery(
    ["components", page],
    async () =>
      await fetchItems({
        query: { type: selectedItemsType },
        options: { page, limit },
      }),
    {
      ...useQueryOptions,
      // enabled: false,
    }
  );

  useEffect(() => {
    // console.log("items in hook: ", items);
    if (items?.totalPages > page) {
      queryClient.prefetchQuery(
        ["components", page + 1],
        () =>
          fetchItems({
            query: { type: selectedItemsType },
            options: { page: page + 1, limit },
          }),
        {
          ...useQueryOptions,
        }
      );
    }
    // eslint-disable-next-line
  }, [page, items, queryClient]);

  useEffect(() => {
    console.log("* page hooks init");
    if (_.isNil(searchTxt)) {
      refetch();
    } else {
      fetchSearchResults.mutate(searchTxt);
    }
    // eslint-disable-next-line
  }, [page, searchTxt]);

  const onBarcodeScanned = (data) => {
    console.log("* onBarcodeScanned init");
    console.log("[onBarcodeScanned] data: ", data);
    const { sku } = barcodeScannedHandler(data);
    setPage(1);
    setSearchTxt(sku);
    fetchSearchResults.mutate(sku);
  }

  const socketOnBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("* on-barcode-scanned")
      console.log("[on-barcode-scanned] data: ", data);
      const { sku } = barcodeScannedHandler(data);
      setPage(1);
      setSearchTxt(sku);
      fetchSearchResults.mutate(sku);
    })
  }

  //onBarcodeScanned hook
  useEffect(() => {
    const listner = window?.printflo_api && window.printflo_api.receive("serialport_readline", onBarcodeScanned)
    
    setTimeout(() => {
      _.isNil(window?.printflo_api) && socketOnBarcodeScanned();
    }, 1000);
    
    return () => {
      if(listner) listner();
      socket.removeAllListeners(["on-barcode-scanned"]);

    }
    // eslint-disable-next-line
  }, []);

  const updateItem = useMutation(
    async ({ condition, update }) => {
      return await submitItem({ condition, update });
    },
    {
      onError: (error) => {
        console.log("- updateItem error", error);
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: (data) => {
        console.log("- updateItem onSuccsess data: ", data);
        // setModalPreloaderIsOpen(false);
        console.log("-searchTxt: ", searchTxt);
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          refetch();
        }
      },
    }
  );

  const showModalHandler = (mode, item) => {
    console.log("* showModalHandler init");
    // console.log("- mode: ", mode);
    // console.log("- item: ", item);
    setMode(mode);
    if (msg) setMsg(null);
    if (mode === "create") {
      setItem(initialItem);
    } else {
      item && setItem(item);
    }
    setItemModalIsOpen(true);
  };

  const submitHandler = () => {
    console.log("* submitHandler init");
    // e.preventDefault();
    console.log("- onsubmit item", item);
    setItemModalIsOpen(false);

    item["modifiedAt"] = new Date();
    if (mode === "create") {
      item["createdAt"] = new Date();
    }

    if (!_.isEmpty(item.sku)) {
      updateItem.mutate({
        condition: { sku: item.sku },
        update: { ...item, modifiedAt: new Date() },
      });
    } else {
      console.log("sku is empty");
      return;
    }
  };

  const deleteHandler = useMutation(
    async () => {
      console.log("* deleteHandler init");
      console.log("- item._id: ", item._id);
      return await deleteItem({_id: item._id});
    },
    {
      onSuccess: () => {
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          refetch();
        }
      },
    }
  );

  const handleDelete = () => {
    setItemModalIsOpen(false);
    deleteHandler.mutate();
  };

  const fetchSearchResults = useMutation(
    async (searchTxt) => {
      console.log("* fetchSearchResults init");
      console.log("[fetchSearchResults] searchTxt: ", searchTxt);
      setLoading(true);
      return await fetchItemsSearch({
        searchTxt,
        type: selectedItemsType,
        options: { page, limit },
      });
    },
    {
      onError: (error) => {
        setMsg(error);
        setLoading(false);
      },
      onSuccess: (result) => {
        queryClient.setQueryData(["components", page], () => {
          return { ...result };
        });
        setLoading(false);
      },
    }
  );

  const handleSearch = async (searchTxt) => {
    console.log("* handleSearch init");
    // console.log("- searchTxt:", searchTxt);
    if (!_.isEmpty(searchTxt)) {
      if (page > 1) {
        alert("Required to move page 1");
        return;
      }
      setSearchTxt(searchTxt);
      fetchSearchResults.mutate(searchTxt);
    }
  };

  const exportItems = async () => {
    console.log("* components:exportItems init");
    console.log("[components:exportItems] selectedExportMode: ", selectedExportMode);
    setLoading(true);
    setMsg(null);
    let exportItems;

    if (selectedExportMode === "all") {
      setSelectedExportMode("retrieved");
      if (_.isNil(searchTxt)) {
        exportItems = await fetchItems({
          query: { type: selectedItemsType },
          options: {
            pagination: false,
          },
        });
      } else {
        exportItems = await fetchItemsSearch({
          searchTxt,
          type: selectedItemsType,
          options: { pagination: false },
        });
      }
    } else {
      exportItems = _.cloneDeep(items);
    }
    if (_.has(exportItems, "docs")) exportItems = exportItems.docs;
    let fields = [
        "sku",
        "description",
        "category",
        "color",
        "size",
        "inventoryArea",
        "stock",
        "priority",
        "type",
    ];
    const result = jsonToCSV({
      fields,
      data: exportItems,
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `components_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    setLoading(false);
  };

  const importItems = (parsedData) => {
    let tempItems = parsedData.map((item) => item.data);
    // tempItems = tempItems.slice(126883, 126983);

    // console.log("tempItems", tempItems);
    let msg = `Total number of items: ${tempItems.length}`;
    setMsg(msg);

    let i = 0;
    let len = tempItems.length - 1;

    async function loop() {
      // console.log(i, tempItems[i]);
      try {
        if (!tempItems[i]?.type) {
          tempItems[i]["type"] = selectedItemsType;
        } else {
          if (tempItems[i]?.type !== selectedItemsType) {
            tempItems[i]["type"] = selectedItemsType;
          }
        }
        let sku = _.trimStart(_.trimEnd(tempItems[i].sku));
        if(!_.isNil(sku) && !_.isEmpty(sku)) {
          const result = await submitItem({
            condition: { sku },
            update: { ...tempItems[i], modifiedAt: new Date() },
          });
          setMsg(`${i + 1}/${tempItems.length}: ${result.sku} updated.`);
        } else {
          setMsg(`${i + 1}/${tempItems.length}: sku is null or empty.`);
        }
        i += 1;
      } catch (error) {
        console.log("submit item error", error);
        setMsg("Error occured, try again from the number of last imported");
        return;
      }

      if (i <= len && _.has(tempItems[i], "sku")) {
        loop();
      } else {
        setMsg("* Completed import items");
        return;
      }
    }

    if (i <= len) {
      loop();
    }
  };

  const handleOnPageChange = ({ selected }) => {
    // console.log("handleOnPageChange page", selected);
    setPage(selected + 1);
  };

  const batchDelete = (parsedData) => {
    console.log("* batchDelete init");
    try {
      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of components for deleting: ${temp.length}`;
      setMsg(msg);
      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        let sku = temp[i].sku;
        try {
          await deleteItem({ sku });
          // const result = await deleteItemBySku({ sku });
          // console.log("deleteItemBySku result", result);
          setMsg(`${i + 1}/${temp.length}: ${sku} deleted.`);
          i += 1;
        } catch (error) {
          console.log("deleteItemBySku error", error);
          const retval = errorHandler(error);
          setMsg(retval);
          return;
        }

        if (i <= len && _.has(temp[i], "sku")) {
          loop();
        } else {
          setMsg("* Completed batch deletion, click refresh to load a page.");
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      const retval = errorHandler(error);
      console.log("- batchDelete error: ", retval);
      setMsg(retval);
    }
  };

  const onChangeShowRows = (e) => {
    console.log('* onChangeShowRows init')
    // console.log('- rows: ', e.target.value)
    // console.log('- limit: ', limit)
    if (page > 1) {
      alert("Requried to move page 1");
      return;
    }
    const rows = e.target.value
    if(limit !== rows) {
      setLimit(rows)
      setTimeout(() => refetch(), 1500)
    }
  }

  return (
    <>
      <Title title={title} />
      <PageMainHeader title={title} user={user} settings={settings} />
      <section className="primary">
        {isLoading || isFetching ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant="danger" className="mb-10">
            {error?.message ? error.message : error}
          </Banner>
        ) : (
          <>
            <PageMenuHeader>
              <ItemsHeader
                showModal={showModalHandler}
                importHandler={importItems}
                exportHandler={exportItems}
                selectedExportMode={selectedExportMode}
                setSelectedExportMode={setSelectedExportMode}
                batchDeleteHandler={batchDelete}
              />
              <div className="align-right_container">
                <Search handleSearch={handleSearch} />
              </div>
            </PageMenuHeader>

            {items && (
              <Pagination
                handleOnPageChange={handleOnPageChange}
                totalDocs={items?.totalDocs}
                totalPages={items?.totalPages}
                page={page}
                limit={limit}
                onChangeShowRows={onChangeShowRows}
                showRows={false}
              />
            )}

            {msg && <Banner className="mb-10">{msg}</Banner>}

            {items && Boolean(items?.docs.length) && (
              <ComponentsList
                items={items.docs}
                showModal={showModalHandler}
                settings={settings}
                user={user}
              />
            )}

            {searchTxt && !Boolean(items?.docs.length) && !loading && (
              <Banner>
                <span>Components not found.</span>
              </Banner>
            )}
          </>
        )}
        <ScrollTop />
      </section>
      <ModalComponent
        modalIsOpen={itemModalIsOpen}
        setModalIsOpen={setItemModalIsOpen}
        title={"Component"}
        mode={mode}
        item={item}
        setItem={setItem}
        submitHandler={submitHandler}
        handleDelete={handleDelete}
      />
      <ModalPreloader modalPreloaderIsOpen={loading} />
      {/* <pre>{JSON.stringify(items, null, 2)}</pre>       */}
    </>
  );
}
