import axios from "axios";
import { errorHandler } from "./errorHandler";
import _ from "lodash";

export const fetchItems = (data) => {
  console.log("* utils:fetchItems init");
  // console.log("[utils:fetchItems] data:arg ", data);
  return new Promise(async (resolve, reject) => {
    let results;
    try {
      if (window?.printflo_api) {
        results = await window.printflo_api._fetchItems(data);
        if (_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.post("/api/items", data);
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(results);
    } catch (error) {
      const retVal = errorHandler(error);
      console.error("[utils:fetchItems] retVal: ", retVal);
      reject(retVal);
    }
  });
};

export const getItemDoc = (sku) => {
  console.log("* utils:items:getItemDoc init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchItemDoc({ sku });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(`/api/items/item-doc`, {
            params: { sku },
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("* utils:items:getItemDoc result: ", result);
      resolve(result);
    } catch (error) {
      const retVal = errorHandler(error);
      console.error("[utils:items:getItemDoc] retVal: ", retVal);
      reject(retVal);
    }
  });
};

// mode: update, create
export const submitItem = (data) => {
  console.log("* utils:items:submitItem init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._submitItem(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/items/update`, data);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.info("[utils:items:submitItem] result: ", result);
      resolve(result);
    } catch (error) {
      const retVal = errorHandler(error);
      console.error("[utils:items:submitItem] retVal: ", retVal);
      reject(retVal);
    }
  });
};

export const deleteItem = (payload) => {
  console.log("* utils:items:deleteItem init");
  console.log("[utils:items:deleteItem] payload: ", payload);

  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._deleteItem(payload);
        if (_.isError(result)) throw result;
      } else {
        // const { _id: id } = payload;
        try {
          const response = await axios.delete(`/api/items/delete`, {
            params: payload,
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.info("[utils:items:deleteItem] result: ", result);
      resolve(result);
    } catch (error) {
      const retVal = errorHandler(error);
      console.error("[utils:items:deleteItem] retVal: ", retVal);
      reject(retVal);
    }
  });
};

export const fetchItemsSearch = (data) => {
  console.log("* utils:items:fetchItemsSearch init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchItemsSearch(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post("/api/items/search", data);
          // console.log(
          //   "[utils:items:fetchItemsSearch:axios.post] response: ",
          //   response
          // );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:items:fetchItemsSearch] result: ", result);
      resolve(result);
    } catch (error) {
      const retVal = errorHandler(error);
      console.log("[utils:items:fetchItemsSearch] retVal: ", retVal);
      reject(retVal);
    }
  });
};
