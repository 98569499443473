import React, {useRef} from 'react'
import ModalTemplate from './ModalTemplate'
import { FaSave, FaUndo } from 'react-icons/fa';
import Button from '@leafygreen-ui/button'

export default React.memo(function ModalConfigVars({
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  config,
  setConfig,
  submitHandler,
}) {
  const configValRef = useRef();
  const formRef = useRef();

  function handleChangeValue(e) {
    e.preventDefault();
    // console.log("key", config.key);
    // console.log("value", e.target.value);
    setConfig({ key: config.key, value: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.checkValidity()) {
      setModalIsOpen(false)
      submitHandler();
    } 
  };

  const onCloseModal = () => {
    console.log('- onCloseModal init')
  }

  const afterOpenModal = () => {
    // console.log('* onAfterCloseModal init')
    configValRef.current.focus();
  }

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "400px", height: "auto" }}
      closeModal={onCloseModal}
      afterOpenModal={afterOpenModal}
    >
      <form className="card modal-form" ref={formRef} onSubmit={(e) => handleSubmit(e)}>
        <div className="input-field">
          <label htmlFor="key">Key</label>
          <input type="text" id="key" value={config.key} disabled={true} />
        </div>
        <div className="input-field">
          <label htmlFor="Value">Value</label>
          <input type="text" id="value" value={config.value} ref={configValRef} onChange={ (e) => handleChangeValue(e) }/>
        </div>
        <div className="modal-footer">
          <Button
            variant="primary"
            className="swing-icon"
            leftGlyph={<FaUndo />}
            onClick={(e) => { e.preventDefault(); setModalIsOpen(false); }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => handleSubmit(e)}
            className="swing-icon"
            leftGlyph={<FaSave />}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </ModalTemplate>
  )
})
