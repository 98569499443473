import React from 'react'
import { FaEdit } from 'react-icons/fa';
import ReactTableContainer from 'utils/ReactTableContainer';
import IconButton from '@leafygreen-ui/icon-button'
import Tooltip from '@leafygreen-ui/tooltip'
import moment from 'moment'
import _ from 'lodash'

export default function UsersList({users, showModal, settings}) {
  const hiddenColumns = ["_id"]
  if(!settings?.useMultipleLocations) hiddenColumns.push("locationCode");
  const columns = React.useMemo(
    () => [
      { Header: "id", accessor: "_id" },
      { Header: "User Id", accessor: "username" },
      { Header: "Role", accessor: "role" },
      { 
        Header: "Location", 
        accessor: "locationCode", 
        Cell: ({row}) => {
          // console.log(row)
          let location = _.find(settings?.locations, {code: row.values.locationCode})
          // console.log(location)
          return (
            <span>{location ? location?.name : ""}</span>
          )
        }
      },
      { 
        Header: "Created At", 
        accessor: "createdAt",
        Cell: ({ row }) => {
          return (
            <span>{moment(row.values.createdAt).local().format("MM-DD-YYYY")}</span>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <Tooltip
              align="right"
              justify="start"
              darkMode={true}
              className='lg-tooltip-container'
              trigger={
                <IconButton
                  onClick={() =>
                    showModal({ selectedUser: row.original, mode: "_updateUser" })
                  }
                  className="swing-icon"
                  aria-label="Update a user"
                >
                  <FaEdit/>
                </IconButton>}
              triggerEvent="hover"
            >
              Edit this user
            </Tooltip>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  )
  return (
    <ReactTableContainer
      columns={columns}
      data={users}
      hiddenColumns={hiddenColumns}
      sortBy={[{ id: "role" }, {id: 'username'}]}
    />
  
  )
}
