import React from 'react'
import { AuthContext } from 'contexts/AuthContext';
import { SettingsContext } from 'contexts/SettingsContext';
import IconButton from '@leafygreen-ui/icon-button';
import { FaSignOutAlt, FaMapMarked, FaUserAlt } from 'react-icons/fa';
import Tooltip from '@leafygreen-ui/tooltip';
import _ from "lodash";

export default function PageMainHeader({title, description}) {
  // console.log('* PageMainHeader init')
  // console.log('- title: ', title)
  // console.log('- description: ', description)
  const { user, logout } = React.useContext(AuthContext);
  const { settings } = React.useContext(SettingsContext);
  // console.log('- user: ', user)

  const LocationComponent = () => {
    let location = _.find(settings.locations, {code: user.locationCode});

    return (
      <>
        { 
          settings?.company && (
            <>
              <FaMapMarked />
              <span className='ml-5'>{settings?.company}</span>
            </>
          )  
        }
        { 
          settings.useMultipleLocations  && (
            <span className='ml-5'>{location?.name && location.name}</span>
          )
        }
      </>
    )
  }

  return (
    <div className="page-main-header">
        <div className='user-wrapper'>
          <div className='login-location'>
            { settings && <LocationComponent />}
          </div>
          <div className='login-user'>
            <FaUserAlt />
            {(user?.username && settings?.company) && (
              <span className='ml-5'>{user.username}</span>
            )}
            <Tooltip
              aria-label="Sign-out"
              trigger={
                <IconButton 
                  onClick={logout} 
                  aria-label="Sign-out"
                  className='ml-10'
                >
                  <FaSignOutAlt />
                </IconButton>
              }
            >
              Sign-out
            </Tooltip>

          </div>
        </div>
      <div>
        <h1>{title}</h1>
        {description && (
          <p className="page-description">
            {description}
          </p>
        )}
      </div>
    </div>
  )
}

