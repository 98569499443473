import log from "loglevel";
import remote from "loglevel-plugin-remote";

log.enableAll();

const customJson = (log) => {
  const user = window.localStorage.getItem("_auth");
  const hostname = window.localStorage.getItem("_hostname");
  const { username } = JSON.parse(user);
  const locationCode = null;
  log.level = log.level.label;
  return { ...log, username, hostname, locationCode };
};

remote.apply(log, { format: customJson, url: "/api/log/add" });

const jsonFormat = (message, locationCode) => {
  const user = window.localStorage.getItem("_auth");
  const hostname = window.localStorage.getItem("_hostname");
  const { username } = JSON.parse(user);
  let log = {
    level: "info",
    logger: "",
    message,
    stacktrace: "",
    timestamp: new Date(),
  };
  return { ...log, username, hostname, locationCode };
};

const saveLog = async ({ message, locationCode }) => {
  console.log("[util:log:saveLog] init");
  console.log("[util:log:saveLog] message: ", message);
  console.log("[util:log:saveLog] locationCode: ", locationCode);
  if (window?.printflo_api) {
    const logJson = jsonFormat(message, locationCode);
    console.log("[util:log:saveLog] logJson: ", logJson);
    const result = await window.printflo_api._saveLog(logJson);
    console.log("[util:log:saveLog] result: ", result);
  } else {
    log.info(message);
  }
};

export default saveLog;
