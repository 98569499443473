import axios from "axios";
import _ from "lodash";
import { errorHandler } from "./errorHandler";

export const getSessions = ({ useStoredSessions = true, hostname }) => {
  console.log("* utils:getSessions init");
  return new Promise(async (resolve, reject) => {
    try {
      console.log("* utils:getSessions useStoredSessions: ", useStoredSessions);
      console.log("* utils:getSessions hostname: ", hostname);
      let storedSessions = window.localStorage.getItem("_sessions");

      // if (!_.isNil(storedSessions) && !_.isArray(storedSessions)) {
      //   window.localStorage.removeItem("_sessions");
      // }

      if (storedSessions && useStoredSessions) {
        resolve(JSON.parse(storedSessions));
        return;
      } else {
        let result;
        if (window?.printflo_api) {
          result = await window?.printflo_api?._fetchSessions();
          if (_.isError(result)) throw result;
        } else {
          try {
            const response = await axios.get("/api/sessions", {
              params: { hostname },
            });
            result = response.data;
          } catch (error) {
            throw error;
          }
        }
        if (Boolean(result.length))
          window.localStorage.setItem("_sessions", JSON.stringify(result));
        resolve(result);
      }
    } catch (error) {
      console.log("[utils:getSessions:axios.get] error: ", error);
      reject(errorHandler(error));
    }
  });
};

export const updateSessionHandler = (args) => {
  console.log("* utils:updateSessionHandler init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window?.printflo_api?._updateSession({ ...args });
        if (_.isError(result)) throw result;
      } else {
        try {
          result = await axios.post("/api/sessions", args);
        } catch (error) {
          throw error;
        }
      }
      console.log("[utils:updateSessionHandler] result: ", result);
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log("[utils:updateSessionHandler] retval: ", retval);
      reject(retval);
    }
  });
};
