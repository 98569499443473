import React, { useEffect, useState } from "react";
import { FaPrint } from "react-icons/fa";
import ModalTemplate from "./ModalTemplate";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import IconButton from "@leafygreen-ui/icon-button";
import _ from 'lodash';
import { useHistory } from "react-router-dom";

export default React.memo(function ModalConsolidationDetails({
  modalIsOpen,
  setModalIsOpen,
  title,
  subTitle,
  selectedConsolidation
}) {
  // console.log("* ModalConsolidationDetails init");
  const [data, setData] = useState(null)
  const history = useHistory();

  useEffect(() => {
    if(selectedConsolidation) {
      // console.log('- selectedConsolidation: ', selectedConsolidation);
      let tempGroupedSKUsArray = [];
      const tempGroupedSKU = _.groupBy(selectedConsolidation.orders, 'sku')
      // console.log('- tempGroupedSKU: ', tempGroupedSKU)
      for(const [key, value] of  Object.entries(tempGroupedSKU)) {
        let obj = { sku: '', ordered: 0, picked: 0, printed: 0, checkedout: 0, ordersCount: 0 } 
        // console.log('- key: ', key)
        obj.sku = key
        // console.log('- value: ', value)
        obj.ordered = _.sumBy(value, 'quantity')
        obj.picked = _.sumBy(value, 'pickedQty')
        obj.printed = _.sumBy(value, ({printed}) => _.sumBy(printed, 'qty') )
        obj.checkedout = _.sumBy(value, 'checkedOutQty')
        obj.ordersCount = value.length
        tempGroupedSKUsArray.push(obj)
      }
      
      // console.log('- tempGroupedSKUsArray: ', tempGroupedSKUsArray)
      setData(tempGroupedSKUsArray)
    }
  }, [selectedConsolidation])
  
  const columns = [
    <TableHeader label="SKU" />,
    <TableHeader label="Ordered" dataType="number"/>,
    <TableHeader label="Picked" dataType="number" />,
    <TableHeader label="Printed" dataType="number" />,
    <TableHeader label="Checked-out" dataType="number" />,
    <TableHeader label="Orders Count" dataType="number" />,
    <TableHeader label="Action" />,
  ];
  return (
    <ModalTemplate
      title={title}
      subTitle={subTitle}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "auto", height: 'auto' }}
    >
      <div className="section">
        {data && (
          <Table data={data} columns={columns}>
            {({ datum }) => {
              // console.log("- datum: ", datum);
              return (
                <Row key={datum.sku}>
                  <Cell>{datum?.sku ? datum.sku : ""}</Cell>
                  <Cell>{datum?.ordered ? datum.ordered : 0}</Cell>
                  <Cell>{datum?.picked ? datum.picked : 0}</Cell>
                  <Cell>{datum?.printed ? datum.printed : 0}</Cell>
                  <Cell>{datum?.checkedout ? datum.checkedout : 0}</Cell>
                  <Cell>{datum?.ordersCount ? datum.ordersCount : 0}</Cell>
                  <Cell>
                    <IconButton
                      onClick={() => {
                        // let url = `/queue-print-${!_.includes(settingsRef.current?.defaultIntegratedAutomation, 'GTX') ? "epson" : "brother"}?sku=${datum?.sku}&qty=${datum.ordered}`
                        let url = `/queue-print?sku=${datum?.sku}&consolidation=1`;
                        history.push(url);
                      }}
                      className="swing-icon"
                      aria-label="Queue print"
                      title="Queue print"
                    >
                      <FaPrint />
                    </IconButton>
                  </Cell>
                </Row>
              );
            }}
          </Table>
        )}

      </div>
    </ModalTemplate>
  );
})
