import React from "react";
import moment from "moment-timezone";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

const WorkOrderDetailsHeader = ({
  ssOrder,
  localOrder,
  isLoading,
  settings,
}) => {
  // const { data: localOrder } = localOrder;
  const locationNameRef = React.useRef("");
  // console.log("[WorkOrderDetailsHeader:localOrder] ", localOrder);

  React.useEffect(() => {
    // console.log(
    //   "[WorkOrderDetailsHeader:localOrder.locationCode] ",
    //   localOrder?.locationCode
    // );
    let location = _.find(settings?.locations, {
      code: localOrder?.locationCode,
    });
    // console.log("location: ", location);
    if (location) locationNameRef.current = location.name;
  }, [settings, localOrder]);

  return (
    <div className="mt-10">
      {isLoading ? (
        <div>
          <Skeleton height={55} />
        </div>
      ) : (
        <div className="title-content-list card">
          <div className="title-content">
            <span className="title">Order #</span>
            <span className="content">
              {ssOrder?.orderNumber
                ? ssOrder.orderNumber
                : localOrder?.orderNumber
                ? localOrder.orderNumber
                : ""}
            </span>
          </div>
          <div className="title-content">
            <span className="title">Date</span>
            <span className="content">
              {moment(ssOrder?.orderDate).format("MM-DD-YYYY")}
            </span>
          </div>
          <div className="title-content">
            <span className="title">Recipient</span>
            <span className="content truncate">
              {ssOrder?.shipTo?.name
                ? ssOrder.shipTo.name
                : localOrder?.shipTo?.name
                ? localOrder.shipTo.name
                : ""}
            </span>
          </div>

          <div className="title-content">
            <span className="title">Ordered</span>
            <span className="content">
              {localOrder?.quantity
                ? localOrder?.quantity
                : _.sumBy(ssOrder?.items, (item) => {
                    if (!item.adjustment) return item.quantity;
                  })}
            </span>
          </div>
          {ssOrder && (
            <>
              <div className="title-content">
                <span className="title">Shipment Amount</span>
                <span className="content">
                  {ssOrder?.shippingAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </div>

              <div className="title-content">
                <span className="title">Order Total</span>
                <span className="content">
                  {ssOrder?.orderTotal.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </div>

              <div className="title-content">
                <span className="title">Carrier Code</span>
                <span>{ssOrder?.carrierCode}</span>
              </div>
              <div className="title-content">
                <span className="title">Service Code</span>
                <span className="content truncate">{ssOrder?.serviceCode}</span>
              </div>
            </>
          )}

          <div className="title-content">
            <span className="title">Order Status</span>
            <span className="content truncate">
              {ssOrder?.orderStatus
                ? ssOrder.orderStatus
                : localOrder?.orderStatus
                ? localOrder.orderStatus
                : ""}
            </span>
          </div>
          <div className="title-content">
            <span className="title">Picked</span>
            <span className="content">{localOrder?.pickedQty}</span>
          </div>
          <div className="title-content">
            <span className="title">Printed</span>
            <span className="content">{localOrder?.printedQty}</span>
          </div>
          <div className="title-content">
            <span className="title">Checked Out</span>
            <span className="content">{localOrder?.checkedOutQty}</span>
          </div>
          <div className="title-content">
            <span className="title">Bin #</span>
            <span className="content">{localOrder?.bin}</span>
          </div>
          <div className="title-content">
            <span className="title">{ssOrder ? "Synced" : "Created"} At</span>
            <span className="content">
              {localOrder &&
                settings &&
                moment(localOrder?.createdAt)
                  .tz(settings?.timezone)
                  .format("MM-DD-YYYY hh:mmA")}
            </span>
          </div>

          {(ssOrder?.customerNotes || ssOrder?.internalNotes) && (
            <>
              <div className="title-content">
                <span className="title">Customer Notes</span>
                <span
                  className="content truncate"
                  title={ssOrder?.customerNotes}
                >
                  {/* {ssOrder?.customerNotes} */}
                  {ssOrder?.customerNotes
                    ? ssOrder?.customerNotes.indexOf("To:") > 0
                      ? ssOrder?.customerNotes.slice(
                          ssOrder?.customerNotes.indexOf("To:") * 1
                        )
                      : ssOrder?.customerNotes
                    : ""}
                </span>
              </div>
              <div className="title-content">
                <span className="title">Internal Notes</span>
                <span
                  className="content truncate"
                  title={ssOrder?.internalNotes}
                >
                  {ssOrder?.internalNotes}
                </span>
              </div>
            </>
          )}

          {settings?.useMultipleLocations && (
            <div className="title-content">
              <span className="title">Location</span>
              <span className="content">{locationNameRef.current}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkOrderDetailsHeader;
