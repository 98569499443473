import React from "react";
import { FaHistory } from "react-icons/fa";
import Button from "@leafygreen-ui/button";

const WorkOrdersHeader = ({ testLabel, resetData, user }) => {
  return (
    <>
      {user && user.role === "admin" && (
        <div>
          {testLabel && (
            <Button
              variant="danger"
              onClick={resetData}
              leftGlyph={<FaHistory />}
            >
              Reset Data
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(WorkOrdersHeader);
