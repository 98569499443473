import React from "react";
import { FaArrowAltCircleDown, FaListAlt } from "react-icons/fa";
import Msg from "components/Msg";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import IconButton from "@leafygreen-ui/icon-button";
import Tooltip from "@leafygreen-ui/tooltip";

const ActivityGraphicsList = ({
  data,
  usePrintedFilm,
  handleViewGraphicDetails,
  anticipationHandler,
}) => {
  // console.log("* ActivityGraphicsList init");
  // console.log("- data: ", data);
  // console.log("- usePrintedFilm: ", usePrintedFilm);
  const columns = [
    <TableHeader
      label="Graphic File"
      sortBy={(datum) => datum.graphicFilename}
    />,
    <TableHeader
      label="Total"
      sortBy={(datum) => datum.total}
      dataType="number"
    />,
    <TableHeader label="Action" />,
  ];

  return (
    <>
      {Boolean(data.length) ? (
        <div className="page-content card p-10">
          <Table data={data} columns={columns}>
            {({ datum }) => (
              <Row key={datum.sku}>
                <Cell>{datum.graphicFilename}</Cell>
                <Cell>{datum?.total ? datum?.total : 0}</Cell>
                <Cell>
                  <Tooltip
                    align="top"
                    justify="start"
                    className="lg-tooltip-container"
                    darkMode={true}
                    trigger={
                      <IconButton
                        onClick={() => handleViewGraphicDetails(datum)}
                        className="swing-icon"
                        aria-label="Queue print"
                      >
                        <FaListAlt />
                      </IconButton>
                    }
                    triggerEvent="hover"
                  >
                    View Details
                  </Tooltip>
                  {usePrintedFilm && (
                    <Tooltip
                      align="top"
                      justify="middle"
                      triggerEvent="hover"
                      className="lg-tooltip-container"
                      darkMode={true}
                      trigger={
                        <IconButton
                          onClick={() => anticipationHandler(datum)}
                          className="swing-icon"
                          aria-label="Use total as anticipated stock"
                        >
                          <FaArrowAltCircleDown />
                        </IconButton>
                      }
                    >
                      Use total as anticipated stock
                    </Tooltip>
                  )}
                </Cell>
              </Row>
            )}
          </Table>
        </div>
      ) : (
        <div className="mt-10">
          <Msg msg={"Activities not found."} />
        </div>
      )}
    </>
  );
};

export default React.memo(ActivityGraphicsList);
