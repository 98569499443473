import _ from "lodash";
import { format } from "date-fns";

import { servicesInRush } from "../utils/servicesInRush";

function getServiceInRush(settings, item) {
  // console.log("* getServiceInRush init");
  // console.log("- item?._order?.serviceCode: ", item?._order?.serviceCode);
  const serviceCodesInRush = settings?.serviceCodesInRush
    ? settings.serviceCodesInRush
    : servicesInRush;

  // _.includes(servicesInRush, item?._order?.serviceCode) ||
  // (_.has(settings?.tagInRush, "tagId") &&
  //   _.includes(item?._order?.tagIds, settings?.tagInRush?.tagId));

  // console.log("- serviceCodesInRush: ", serviceCodesInRush);
  // console.log(
  //   "- settings.useMultipleLocations: ",
  //   settings.useMultipleLocations
  // );
  let isRush = false;
  let symbol = "R";

  const foundService = _.find(serviceCodesInRush, {
    name: item?._order?.serviceCode,
  });
  // console.log("- foundService: ", foundService);

  const isTagInRush =
    _.has(settings?.tagInRush, "tagId") &&
    _.includes(item?._order?.tagIds, settings?.tagInRush?.tagId);
  // console.log("- isTagInRush: ", isTagInRush);

  if (foundService) {
    isRush = true;
    if (!_.isNil(foundService?.symbol) && !_.isEmpty(foundService?.symbol)) {
      symbol = foundService?.symbol;
    }
  }

  if (isTagInRush) isRush = true;

  return {
    isRush,
    symbol,
  };
}

export const labelTemplateQR = ({
  item,
  platens,
  settings,
  type = "component",
  isPicked = false,
  areaCode = null,
  media,
}) => {
  // console.log("* labelTemplateQR init");
  // console.log("- item", item);
  // console.log("- type", type);
  // console.log("- isPicked", isPicked);
  // console.log("- areaCode", areaCode);
  // console.log("- settings", settings);
  // console.log("- media", media);
  // console.log("- platens", platens);

  const { orderNumber } = item;

  let serviceInRush = getServiceInRush(settings, item);
  // console.log("- serviceInRush: ", serviceInRush);

  function platenName(platen) {
    // console.log("* platenName init");
    const foundPlaten = _.find(platens, { code: platen });
    // console.log("- foundPlaten", foundPlaten);
    return foundPlaten ? `${foundPlaten.name}` : "";
  }

  function mediaName(media) {
    let retVal = "";
    let foundMedia;
    if (!media) {
      const defaultMedia = _.find(settings?.media, {
        isDefault: true,
      });
      // console.log('- defaultMedia; ', defaultMedia)
      foundMedia = defaultMedia;
    } else {
      foundMedia = _.find(settings?.media, { code: media });
    }
    // console.log("- foundMedia; ", foundMedia);
    if (foundMedia) retVal = foundMedia.name;
    return retVal;
  }

  const platen = _.has(item?._item?._graphics[0], "platen")
    ? item?._item?._graphics[0]?.platen
    : "";

  let totalQtyToPrint = isPicked
    ? item?.quantity
    : item?.quantity - item?.pickedQty;
  // console.log("[labelTemplateQR] totalQtyToPrint: ", totalQtyToPrint);

  let data = "";
  data = "^xa";
  data += "^lh5,5"; // home position
  data += "^fo22,20^abn,10,15^fd" + item?.sku + "^fs";

  if (settings?.printDescriptionLabel) {
    if (item?._item?.description) {
      if (settings?.usePretreatmentBarcode) {
        data +=
          "^fo20,40^abn,10,10^fd" +
          item?._item?.description.substring(0, 60) +
          "^fs";
      } else {
        data += "^fo20,40^abn,10,10^fd" + item?._item?.description + "^fs";
      }
    }
  }
  data += "^by1,2";
  if (settings?.usePretreatmentBarcode) {
    data += "^fo490,18^acn,10,10^fd*PRETREATMENT^fs";
    if (
      !_.isNil(item?._item?.pretreatmentBarcode) &&
      !_.isEmpty(item?._item?.pretreatmentBarcode)
    ) {
      data +=
        "^fo490,40^a0n,20,20^bcn,30,y,n,n^fd" +
        `${item?._item?.pretreatmentBarcode}` +
        "^fs";
    } else {
      let selectedModel = settings?.pretreatmentConfigures?.model;
      console.log("[skuLabelTemplateQR] selectedModel: ", selectedModel);
      if (selectedModel) {
        let foundDefault = _.find(
          settings?.pretreatmentConfigures?.defaultValue,
          { model: selectedModel }
        );
        console.log("[skuLabelTemplateQR] foundDefault: ", foundDefault);

        if (foundDefault) {
          data += "^fo490,18^acn,10,10^fd*PRETREATMENT^fs";
          data +=
            "^fo490,43^a0n,20,20^bcn,30,y,n,n^fd" +
            `${foundDefault?.value}` +
            "^fs";
        }
      }
    }
  }

  // change delimeter * to ; for CADlink
  if (item?.orderItemId) {
    if (item?._item?.graphicPosition) {
      data +=
        "^fo20,48^bq,2,3^fda," +
        item?.sku +
        ";" +
        item?._order?.orderId +
        ";" +
        item?.orderItemId +
        ";" +
        item?._item?.graphicPosition +
        "^fs";
    } else {
      data +=
        "^fo20,48^bq,2,3^fda," +
        item?.sku +
        ";" +
        item?._order?.orderId +
        ";" +
        item?.orderItemId +
        "^fs";
    }
  } else {
    data +=
      "^fo20,48^bq,2,3^fda," + item?.sku + ";" + item?._order?.orderId + "^fs";
  }

  if (_.includes(settings?.defaultIntegratedAutomation, "CAD")) {
    data += "^fo140,60^abn,10,15^fd" + mediaName(media) + "^fs";
  } else {
    data += "^fo140,60^abn,10,15^fd" + platenName(platen) + "^fs";
  }

  if (settings?.usePlatenSymbol && platen) {
    data += "^fo655,155^abn,11,11^fd" + platen.substring(0, 2) + "^fs";
    data += "^fo650,140^gc45,2,b^fs";
  }

  if (item?._item?.graphicPosition) {
    data += "^fo140,80^abn,10,15^fd" + item?._item?.graphicPosition + "^fs";
  }

  if (type !== "fg") {
    if (!_.isNil(item?._item?.component)) {
      if (item?._item?.component?.length > 15) {
        data += "^fo140,100^abn,10,15^fd" + item._item.component + "^fs";
      } else {
        data += "^fo140,100^abn,20,20^fd" + item._item.component + "^fs";
      }
      if (settings?.useMultipleLocations) {
        if (!_.isNil(areaCode))
          data += "^fo100,180^abn,11,11^fd" + areaCode + "^fs";
      } else {
        if (item?._item?._component?.inventoryArea) {
          if (item?._item?.component?.length > 10) {
            data +=
              "^fo100,180^abn,10,10^fd" +
              item._item._component.inventoryArea +
              "^fs";
          } else {
            data +=
              "^fo100,180^abn,11,11^fd" +
              item._item._component.inventoryArea +
              "^fs";
          }
        }
      }
    }
    if (type === "pf") {
      data += "^fo350,160^abn,11,11^fd" + areaCode + "^fs";
    }
  } else {
    data += "^fo140,100^abn,18,18^fd" + areaCode + "^fs";
  }

  if (serviceInRush?.isRush) {
    data += "^fo730,155^abn,20,20^fd" + serviceInRush?.symbol + "^fs";
    data += "^fo715,140^gc50,2,b^fs";
  }

  data +=
    "^fo140,150^abn,18,18^fd" +
    (item?._order?.bin ? item._order.bin : "") +
    "^fs";

  if (orderNumber.length > 15) {
    data += "^fo140,130^abn,10,10^fd" + orderNumber + "^fs";
  } else {
    data += "^fo140,130^abn,11,11^fd" + orderNumber + "^fs";
  }

  // item options
  if (Boolean(item?.options?.length)) {
    let option = item.options[0];
    const { name, value } = option;
    // console.log("- value: ", value);
    data += "^fo500,120^abn,10,10^fd" + name + "^fs";
    data += "^fo500,140^acn,13,13^fd" + value + "^fs";
  }

  if (!settings?.printOrderDateLabel) {
    data +=
      "^fo350,180^abn,10,10^fd" +
      format(new Date(), "MM/dd/yy hh:mm:ss a") +
      "^fs";
  } else {
    data +=
      "^fo350,180^abn,10,10^fd" +
      format(new Date(item?._order?.orderDate), "MM/dd/yy hh:mm:ss a") +
      "^fs";
  }

  if (settings?.useMultipleLocations && item?.locationCode) {
    data += "^fo550,180^abn,10,10^fd" + item?.locationCode + "^fs";
  }

  data += "^fo20,180^abn,11,11^fd" + item?._order?.quantity + "^fs";
  data += "^pq" + totalQtyToPrint;
  data += "^xz";

  return { zpl: data, totalQtyToPrint };
};

/*
export const labelTemplate = ({
  item,
  platens,
  settings,
  type = "component",
  isPicked = false,
  areaCode = null,
}) => {
  console.log("* labelTemplate init");
  // console.log("- item", item);
  // console.log("- type", type);
  // console.log("- isPicked", isPicked);
  // console.log("- areaCode", areaCode);
  // console.log("- settings", settings);

  if (type === "component" && item._item?._component?.sku) {
    console.log("- component sku: ", item._item?._component?.sku);
  }

  const { orderNumber } = item;

  function platenName(platen) {
    const foundPlaten = _.find(platens, { code: platen });
    // console.log("foundPlaten", foundPlaten);
    return foundPlaten ? `${foundPlaten.name}` : "";
  }
  const isRush =
    _.includes(servicesInRush, item?._order?.serviceCode) ||
    (_.has(settings?.tagInRush, "tagId") &&
      _.includes(item?._order?.tagIds, settings?.tagInRush?.tagId));

  const platen = _.has(item?._item?._graphics[0], "platen")
    ? item?._item?._graphics[0]?.platen
    : "";

  let totalQtyToPrint = isPicked
    ? item?.quantity
    : item?.quantity - item?.pickedQty;
  console.log("- totalQtyToPrint: ", totalQtyToPrint);

  let data = "";
  data = "^xa";
  data += "^lh5,5"; // home position
  data += "^fo22,20^abn,10,15^fd" + item?.sku + "^fs";
  if (settings?.printDescriptionLabel) {
    if (settings?.usePretreatmentBarcode) {
      data +=
        "^fo20,37^abn,10,10^fd" +
        item?._item?.description.substring(0, 60) +
        "^fs";
    } else {
      data += "^fo20,37^abn,10,10^fd" + item?._item?.description + "^fs";
    }
  }
  data += "^by1,2";
  if (settings?.usePretreatmentBarcode) {
    data += "^fo550,25^acn,10,10^fd*PRETREATMENT^fs";
    if (
      !_.isNil(item?._item.pretreatmentBarcode) &&
      !_.isEmpty(item?._item.pretreatmentBarcode)
    ) {
      data +=
        "^fo550,48^a0n,20,20^bcn,40,y,n,n^fd" +
        `${item?._item?.pretreatmentBarcode}` +
        "^fs";
    } else {
      data +=
        "^fo550,48^a0n,20,20^bcn,40,y,n,n^fd" +
        `${settings?.pretreatmentConfigures?.defaultValue}` +
        "^fs";
    }
  }

  // change delimeter * to ;
  if (item?.orderItemId) {
    data +=
      "^fo20,55^bcn,40,n,n,n^fd" +
      item?.sku +
      ";" +
      item?._order?.orderId +
      ";" +
      item?.orderItemId +
      "^fs";
  } else {
    data +=
      "^fo20,55^bcn,40,n,n,n^fd" +
      item?.sku +
      ";" +
      item?._order?.orderId +
      "^fs";
  }

  data += "^fo23,105^abn,10,15^fd" + platenName(platen) + "^fs";

  if (settings?.usePlatenSymbol && platen) {
    data += "^fo655,155^abn,11,11^fd" + platen.substring(0, 2) + "^fs";
    data += "^fo650,140^gc45,2,b^fs";
  }

  if (item?._item?.graphicPosition) {
    data += "^fo23,125^abn,10,15^fd" + item?._item?.graphicPosition + "^fs";
  }

  if (!areaCode) {
    if (!_.isNil(item?._item?.component)) {
      // console.log("item._item.component.length", item._item.component.length);
      if (item?._item?.component?.length > 15) {
        data += "^fo23,145^abn,10,15^fd" + item._item.component + "^fs";
      } else {
        data += "^fo23,145^abn,20,20^fd" + item._item.component + "^fs";
      }
      if (item?._item?._component?.inventoryArea) {
        if (item?._item?._component?.length > 7) {
          data +=
            "^fo550,150^abn,10,10^fd" +
            item._item._component.inventoryArea +
            "^fs";
        } else {
          data +=
            "^fo550,160^abn,10,15^fd" +
            item._item._component.inventoryArea +
            "^fs";
        }
      }
    }
  } else {
    data += "^fo23,150^abn,20,20^fd" + areaCode + "^fs";
  }

  if (isRush) {
    data += "^fo730,155^abn,20,20^fdR^fs";
    data += "^fo715,140^gc50,2,b^fs";
  }

  data +=
    "^fo550,130^abn,18,18^fd" +
    (item?._order?.bin ? item._order.bin : "") +
    "^fs";

  if (orderNumber.length > 15) {
    data += "^fo23,180^abn,10,10^fd" + orderNumber + "^fs";
  } else {
    data += "^fo23,180^abn,11,11^fd" + orderNumber + "^fs";
  }

  // item options: no applied on barcode
  if (!settings?.printOrderDateLabel) {
    data +=
      "^fo350,180^abn,10,10^fd" +
      format(new Date(), "MM/dd/yy hh:mm:ss a") +
      "^fs";
  } else {
    data +=
      "^fo350,180^abn,10,10^fd" +
      format(new Date(item?._order?.orderDate), "MM/dd/yy hh:mm:ss a") +
      "^fs";
  }
  data += "^fo550,180^abn,11,11^fd" + item?._order?.quantity + "^fs";
  data += "^pq" + totalQtyToPrint;
  data += "^xz";

  return data;
};
*/
