import React, { useContext, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Title } from "utils/Title";
import Msg from "components/Msg";
import PageMainHeader from "components/PageMainHeader";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
// import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { endOfToday, subDays } from "date-fns";
import Pagination from "utils/Pagination";
import ActivityItemsList from "./ActivityItemsList";
import ActivityGraphicsList from "./ActivityGraphicsList";
import Banner from "@leafygreen-ui/banner";
import Button from "@leafygreen-ui/button";
import PageMenuHeader from "components/PageMenuHeader";
import DateRange from "components/DateRange";
import { ModalPreloader } from "utils/Preloader";
import ScrollTop from "utils/ScrollTop";
import { fetchActivityItems, fetchActivityGraphics } from "utils/packingItems";
import FileImportExport from "components/FileImportExport";
import _ from "lodash";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import { Tabs, Tab } from "@leafygreen-ui/tabs";
import ModalActivityGraphicDetails from "components/modals/ModalActivityGraphicDetails";
import Search from "components/Search";
import { errorHandler } from "utils/errorHandler";
import { FaBolt, FaCreativeCommonsSampling } from "react-icons/fa";
import { updatePrintedFilm } from "utils/printedFilms";

const title = "Order Items Activity";

const sDate = subDays(new Date(), 90);
const eDate = endOfToday(new Date());
const pageSize = 50;

export default function OrderItemsActivity() {
  const { settings } = useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [startDateItems, setStartDateItems] = useState(sDate);
  const [endDateItems, setEndDateItems] = useState(eDate);
  const [startDateGraphics, setStartDateGraphics] = useState(sDate);
  const [endDateGraphics, setEndDateGraphics] = useState(eDate);
  const [pageItems, setPageItems] = useState(1);
  const [pageGraphics, setPageGraphics] = useState(1);
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedGraphic, setSelectedGraphic] = React.useState(null)
  const [searchTxtGraphics, setSearchTxtGraphics] = React.useState(null);
  const [searchTxtItems, setSearchTxtItems] = React.useState(null);
  const queryClient = useQueryClient();

  const {
    data: items,
    isLoading: isLoadingItems,
    isFetching: isFetchingItems,
    isError: isErrorItems,
    error: errorItems,
    refetch: refetchItems,
  } = useQuery(
    ["activities_items", { pageItems, startDateItems, endDateItems }],
    () => fetchActivityItems({ page:pageItems, startDate: startDateItems, endDate: endDateItems, limit: pageSize }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1,
    }
  );

  const {
    data: graphics,
    refetch: refetchGraphics,
    isFetching: isFetchingGraphics,
    isError: isErrorGraphics,
    error: errorGraphics,
  } = useQuery(
    ["activities_graphics", { pageGraphics, startDateGraphics, endDateGraphics }],
    () => fetchActivityGraphics({ page: pageGraphics, startDate: startDateGraphics, endDate: endDateGraphics, searchTxt: searchTxtGraphics, limit: pageSize }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1,
    }
  );

  useEffect(() => {
    console.log("* activities_graphics hook init");
    if (graphics?.totalPages > pageGraphics) {
      queryClient.prefetchQuery(
        ["activities_graphics", { pageGraphics: pageGraphics + 1, startDateGraphics, endDateGraphics }],
        () => fetchActivityGraphics({ page: pageGraphics + 1, startDate: startDateGraphics, endDate: endDateGraphics, limit: pageSize }),
        {
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 1,
        }
      );
    }
    // eslint-disable-next-line
  }, [pageGraphics, graphics, queryClient]);
  
  useEffect(() => {
    console.log("* activities_items hook init");
    if (items?.totalPages > pageItems) {
      queryClient.prefetchQuery(
        ["activities_items", { pageItems: pageItems + 1, startDateItems, endDateItems }],
        () => fetchActivityItems({ page: pageItems + 1, startDate: startDateItems, endDate: endDateItems, limit: pageSize }),
        {
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 1,
        }
      );
    }
    // eslint-disable-next-line
  }, [pageItems, items, queryClient]);


  const handleOnPageChangeGraphics = ({ selected }) => {
    // console.log("handleOnPageChangeGraphics page selected: ", selected);
    setPageGraphics(selected+1)
  };

  const handleOnPageChangeItems = ({ selected }) => {
    // console.log("handleOnPageChangeItems page selected: ", selected);
    setPageItems(selected+1)
  };

  const exportActivityGraphics = async () => {
    console.log("* exportActivityGraphics init");
    console.log("- selectedExportMode: ", selectedExportMode);
    setLoading(true);
    msg && setMsg(null);

    let exportActivities;

    if (selectedExportMode === "all") {
      setSelectedExportMode("retrieved");
      try {
        let query = { startDate: startDateGraphics, endDate: endDateGraphics}
        if(searchTxtGraphics) query["searchTxt"] = searchTxtGraphics
        exportActivities = await fetchActivityGraphics(query)
      } catch (error) {
        setMsg(error?.message)
      }

    } else {
      exportActivities = _.cloneDeep(graphics);
    }
    
    console.log("exportActivities", exportActivities);
    if (_.has(exportActivities, "docs")) exportActivities = exportActivities.docs;

    let fields = [
      "graphicFilename",
      "total",
    ];

    const result = jsonToCSV({
      fields,
      data: exportActivities,
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `activities_graphics_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    setLoading(false);
  };

  const exportActivityItems = async () => {
    console.log("* exportActivityItems init");
    console.log("- selectedExportMode: ", selectedExportMode);
    setLoading(true);
    msg && setMsg(null);

    let exportActivities;

    if (selectedExportMode === "all") {
      setSelectedExportMode("retrieved");
      try {
        let query = {startDate: startDateItems, endDate: endDateItems}
        if(searchTxtItems) query["searchTxt"] = searchTxtItems
        exportActivities = await fetchActivityItems(query)
      } catch (error) {
        setMsg(error?.message)
      }

    } else {
      exportActivities = _.cloneDeep(items);
    }
    
    // console.log("exportActivities", exportActivities);
    if (_.has(exportActivities, "docs")) exportActivities = exportActivities.docs;

    let fields = [
      "sku",
      "description",
      "category",
      "color",
      "component",
      "total",
    ];

    const result = jsonToCSV({
      fields,
      data: exportActivities,
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `activities_items_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    setLoading(false);
  };

  const saveAnticipation = async ({graphicFilename, total}) => {
    console.log('* saveAnticipation init')
    console.log("[saveAnticipation] graphicFilename : ", graphicFilename)
    console.log("[saveAnticipation] total : ", total)
    try {
      setLoading(true)
      const result = await updatePrintedFilm({
        condition: {graphicFilename},
        update: {
          graphicFilename,
          anticipatedStock: total
        }
      });

      setLoading(false)
      if(result) {
        toast.info(`Successfully saved!`, {
          position: "bottom-right",
          autoClose: 1000,
        });
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const batchSaveAnticipation = () => {
    console.log('* batchSaveAnticipation init')
    // console.log('[batchSaveAnticipation] graphics; ', graphics)
    setLoading(true)
    const promises = graphics?.docs.map(async doc => {
      const {graphicFilename, total} = doc;
      const promise = await updatePrintedFilm({
        condition: {graphicFilename},
        update: {
          graphicFilename,
          anticipatedStock: total
        }
      })
      return promise
    })

    Promise.all(promises).then(() => {
      toast.info(`Successfully saved!`, {
        position: "bottom-right",
        autoClose: 1000,
      });
      setLoading(false)
    }).catch(error => {
      console.error(error)
      setMsg(errorHandler(error))
      setLoading(false)
    })

  }

  const handleViewGraphicDetails = (datum) => {
    console.log('* handleViewGraphicDetails init')
    // console.log('- datum: ', datum)
    setSelectedGraphic(datum)
    setShowModal(true)
  }

  const fetchSearchResultsGraphics = useMutation(
    async (searchTxt) => {
      console.log("* fetchSearchResults init");
      console.log("- searchTxt: ", searchTxt);
      setLoading(true);

      return await fetchActivityGraphics({
        page: pageGraphics, startDate: startDateGraphics, endDate: endDateGraphics, searchTxt, limit: pageSize
      });
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
        setLoading(false);
      },
      onSuccess: (result) => {
        // console.log("- fetchSearchResults onSuccess result: ", result);
        queryClient.setQueryData(["activities_graphics", { pageGraphics, startDateGraphics, endDateGraphics }], () => {
          return { ...result };
        });
        // setLimit(result?.limit)
        setLoading(false);
      },
    }
  );

  const fetchSearchResultsItems = useMutation(
    async (searchTxt) => {
      console.log("* fetchSearchResultsItems init");
      console.log("- searchTxt: ", searchTxt);
      setLoading(true);

      return await fetchActivityItems({
        page: pageItems, startDate: startDateItems, endDate: endDateItems, searchTxt, limit: pageSize
      });
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
        setLoading(false);
      },
      onSuccess: (result) => {
        console.log("- fetchSearchResults onSuccess result: ", result);
        queryClient.setQueryData(["activities_items", { pageItems, startDateItems, endDateItems }], () => {
          return { ...result };
        });
        // setLimit(result?.limit)
        setLoading(false);
      },
    }
  );

  const handleSearchGraphics = async (searchTxt) => {
    console.log("* handleSearchGraphics init");
    // console.log("- searchTxt", searchTxt);
    // console.log("- flag", flag);
    if (!_.isEmpty(searchTxt)) {
      if (pageGraphics > 1) {
        alert("Requried to move page 1");
        return;
      }
      setSearchTxtGraphics(searchTxt);
      fetchSearchResultsGraphics.mutate(searchTxt);
    }
  };

  const handleSearchItems = async (searchTxt) => {
    console.log("* handleSearchItems init");
    console.log("- searchTxt", searchTxt);
    if (!_.isEmpty(searchTxt)) {
      if (pageItems > 1) {
        alert("Requried to move page 1");
        return;
      }
      setSearchTxtItems(searchTxt);
      fetchSearchResultsItems.mutate(searchTxt);
    }
  };


  return (
    <>
      <Title title={title} />
      <PageMainHeader title={title} user={user} settings={settings} />
      <ToastContainer theme="dark" />
      <section className="primary">
        {isLoadingItems ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isErrorItems ? (
          <Msg msg={errorItems?.message ? errorItems.message : errorItems} />
        ) : isErrorGraphics ? (
          <Msg msg={errorGraphics?.message ? errorGraphics.message : errorGraphics} />
        ) : null}
        
        {msg && <Banner variant="info">{msg}</Banner>}
        
        {(items || graphics) && (
          <Tabs
            setSelected={setSelectedTab}
            selected={selectedTab}
            aria-label="Tabs"
          >
            <Tab name="Graphics">
              {graphics && (
                <div className="tab-wrapper p-10">
                  <PageMenuHeader>
                    <DateRange
                      startDate={startDateGraphics}
                      endDate={endDateGraphics}
                      setStartDate={setStartDateGraphics}
                      setEndDate={setEndDateGraphics}
                      onClickHandler={refetchGraphics}
                    />

                    {(graphics.docs && Boolean(graphics?.docs?.length)) && (
                      <>
                        <div className="d-flex align-items-center ">
                          {settings?.usePrintedFilm && (
                            <Button
                                variant="danger"
                                className="swing-icon"
                                onClick={batchSaveAnticipation}
                                leftGlyph={<FaCreativeCommonsSampling />}
                              >
                                Batch to Printed Films
                            </Button>
                          )}
                          <Button
                            variant="primary"
                            className="swing-icon"
                            title="Reload"
                            leftGlyph={<FaBolt />}
                            onClick={() => {
                              window.location.reload();
                            }}
                          >
                            Refresh
                          </Button>
                          <FileImportExport 
                            exportHandler={exportActivityGraphics}
                            selectedExportMode={selectedExportMode}
                            setSelectedExportMode={setSelectedExportMode}
                          />
                        </div>
                        <Search handleSearch={handleSearchGraphics} placeholderText="Search by graphic file..."/>
                      </>
                    )}

                  </PageMenuHeader>
                  {graphics?.totalPages > 1 && (
                    <Pagination
                      handleOnPageChange={handleOnPageChangeGraphics}
                      totalDocs={graphics?.totalDocs}
                      totalPages={graphics?.totalPages}
                      page={pageGraphics}
                      limit={pageSize}
                    />
                  )}
                  <ActivityGraphicsList
                    data={graphics?.docs}
                    usePrintedFilm={settings?.usePrintedFilm}
                    handleViewGraphicDetails={handleViewGraphicDetails}
                    anticipationHandler={saveAnticipation}
                  />
                  {/* <pre>{JSON.stringify(graphics, null, 2)}</pre> */}

                </div>
              )}
            </Tab>
            <Tab name="Items">
              {items && (
                <div className="tab-wrapper p-10">
                  <PageMenuHeader>
                    <DateRange
                      startDate={startDateItems}
                      endDate={endDateItems}
                      setStartDate={setStartDateItems}
                      setEndDate={setEndDateItems}
                      onClickHandler={refetchItems}
                    />

                    {(items.docs && Boolean(items?.docs?.length)) && (
                      <>
                        <div className="d-flex align-items-center">
                          <Button
                          variant="primary"
                          className="swing-icon"
                          title="Reload"
                          leftGlyph={<FaBolt />}
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          Refresh
                        </Button>
                          <FileImportExport 
                            exportHandler={exportActivityItems}
                            selectedExportMode={selectedExportMode}
                            setSelectedExportMode={setSelectedExportMode}
                          />
                        </div>
                        <Search handleSearch={handleSearchItems} placeholderText="Search by SKU..."/>
                      </>
                    )}

                  </PageMenuHeader>
                  {items?.totalPages > 1 && (
                    <Pagination
                      handleOnPageChange={handleOnPageChangeItems}
                      totalDocs={items?.totalDocs}
                      totalPages={items?.totalPages}
                      page={pageItems}
                      limit={pageSize}
                    />
                  )}
                  {items?.docs && (
                    <ActivityItemsList
                      data={items?.docs}
                      useARXfile={settings?.useARXfile}
                    />

                  )}
                  {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}

                </div>
              )}
            </Tab>
          </Tabs>  

        )}
        
        <ScrollTop />
      </section>
      <ModalActivityGraphicDetails 
        modalIsOpen={showModal} 
        setModalIsOpen={setShowModal} 
        title="View Details" 
        selectedGraphic={selectedGraphic}
      />
      <ModalPreloader modalPreloaderIsOpen={isFetchingItems || isFetchingGraphics || loading} />
    </>
  );
}


/* 
{activities?.docs && Boolean(activities?.docs?.length) ? (
  <>
    <OrderItemsActivityList
      data={activities?.docs}
      anticipationHandler={saveAnticipation}
      usePrintedFilm={settings?.usePrintedFilm}
    />
  </>
) : (
  <Banner>Not found</Banner>
)} 
*/