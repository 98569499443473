import React, { memo, useRef } from "react";
import ModalTemplate from "./ModalTemplate";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import { useLocation } from "react-router";
import Button from "@leafygreen-ui/button";

function ModalComponent({
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  item,
  setItem,
  submitHandler,
  handleDelete,
}) {
  // console.log("* ModalComponent init ==");
  // console.log("- ModalItem item", item);
  // item && console.log("mode", mode);
  // console.log("selectedItemsType", selectedItemsType);
  // console.log("- settings", settings);
  const location = useLocation()
  // console.log('- location: ', location)
  

  const formRef = useRef();
  // console.log("formRef", formRef);

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.checkValidity()) submitHandler();
  };

  const onChangeHandler = (e) => {
    // console.log('* onChangeHandler init')
    // console.log(e.target.id, e.target.value);
    setItem({ ...item, [e.target.id]: e.target.value.trimLeft() });
  };

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "600px", height: "auto" }}
    >
      <form ref={formRef} className="card modal-form" >
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="sku">
              SKU
            </label>
            <input
              id="sku"
              type="text"
              value={item?.sku ? item.sku : ""}
              onChange={onChangeHandler}
              placeholder="SKU"
              required={true}
              disabled={mode === "update"}
              autoFocus={mode === "create"}
            />
          </div>
          <div className="input-field">
            <label htmlFor="description">
              Description
            </label>
            <input
              id="description"
              type="text"
              value={item?.description ? item.description : ""}
              onChange={onChangeHandler}
              placeholder="Description"
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="category">
              Category
            </label>
            <input
              id="category"
              type="text"
              value={item?.category ? item.category : ""}
              onChange={onChangeHandler}
              placeholder="Category"
            />
          </div>
          <div className="input-field">
            <label htmlFor="color">
              Color
            </label>
            <input
              id="color"
              type="text"
              value={item?.color ? item.color : ""}
              onChange={onChangeHandler}
              placeholder="Color"
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="size">
              Size
            </label>
            <input
              id="size"
              type="text"
              value={item?.size ? item.size : ""}
              onChange={onChangeHandler}
              placeholder="Size"
            />
          </div>
          <div className="input-field">
            <label htmlFor="priority">
              Collection Priority
            </label>
            <input
              id="priority"
              type="text"
              value={item?.priority ? item.priority : ""}
              onChange={onChangeHandler}
              placeholder="Collection Priority"
            />
          </div>
          
        </div>
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="inventoryArea">
              Inventory Area
            </label>
            <input
              id="inventoryArea"
              type="text"
              value={item?.inventoryArea ? item.inventoryArea : ""}
              onChange={onChangeHandler}
              placeholder="Inventory Area"
            />
          </div>
          <div className="input-field">
            <label htmlFor="stock">
              Stock
            </label>
            <input
              id="stock"
              type="text"
              value={item?.stock ? item.stock : ""}
              onChange={onChangeHandler}
              placeholder="Stock"
            />
          </div>
        </div>
        <div className="modal-footer">
          {(mode==='update' && !location?.pathname.includes('workorder')) && (
            <Button
              variant="danger"
              className="swing-icon"
              onClick={handleDelete}
              leftGlyph={<FaTrashAlt />}
            >
              Delete
            </Button>
          )}

          <Button
            variant="primary"
            onClick={handleSubmit} 
            className="swing-icon"
            leftGlyph={<FaSave />}
          >
            Submit
          </Button>
          
        </div>
      </form>
    </ModalTemplate>
  )
}

export default memo(ModalComponent)
