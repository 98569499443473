import React, { createContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
// import _ from "lodash";
import { getHostname, getSettings } from "utils/settings";
import { getSessions } from "utils/sessions";

export const SettingsContext = createContext();

const initialPlatens = [
  {
    name: "Large Platen(GTX H21)",
    code: "16x21",
    size: { width: 16, height: 21 },
    sizeCodeGTX: 0,
    printers: ["GTX"],
  },
  {
    name: "Large Platen",
    code: "16x20",
    size: { width: 16, height: 20 },
    sizeCodeGTX: null,
    printers: ["Epson"],
  },
  {
    name: "Large Platen(GTX H18)",
    code: "16x18",
    size: { width: 16, height: 18 },
    sizeCodeGTX: 1,
    printers: ["GTX"],
  },
  {
    name: "Medium Platen",
    code: "14x16",
    size: { width: 14, height: 16 },
    sizeCodeGTX: 2,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Small Platen",
    code: "10x12",
    size: { width: 10, height: 12 },
    sizeCodeGTX: 3,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Extra Small Platen",
    code: "7x8",
    size: { width: 7, height: 8 },
    sizeCodeGTX: 4,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Sleeve Platen",
    code: "4x4",
    size: { width: 4, height: 4 },
    sizeCodeGTX: null,
    printers: ["Epson"],
  },
];

const SettingsContextProvider = (props) => {
  // console.log("* SettingsContextProvider init");
  const [platens, setPlatens] = useState([]);
  const [media, setMedia] = useState([]);

  const { data: settings } = useQuery("settings", getSettings, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  const { data: hostname } = useQuery("hostname", getHostname, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  const { data: sessions, refetch: fetchSessions } = useQuery(
    "sessions",
    () => getSessions({ useStoredSessions: true, hostname }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
      enabled: false,
    }
  );

  useEffect(() => {
    if (settings) {
      if (settings?.platens?.length > 0) {
        setPlatens(settings.platens);
      } else {
        setPlatens(initialPlatens);
      }

      if (settings?.media?.length > 0) {
        setMedia(settings.media);
      }
    }
  }, [settings]);

  useEffect(() => {
    if (hostname) {
      fetchSessions();
    }
    // eslint-disable-next-line
  }, [hostname]);

  return (
    <SettingsContext.Provider
      value={{ settings, platens, media, hostname, sessions }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
