import React from 'react'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import ReactPaginate from "react-paginate";

export default function Pagination({ handleOnPageChange, totalDocs, totalPages, page, limit, showRows=false, onChangeShowRows }) {
  return (
    <div className="card d-flex justify-content-space p-10 mb-10 align-items-center">
      <ReactPaginate
        containerClassName={"react-paginate"}
        onPageChange={handleOnPageChange}
        marginPagesDisplayed={2}
        initialPage={page - 1}
        pageCount={totalPages===0?1:totalPages}
        pageRangeDisplayed={5}
        previousLabel={<FaCaretLeft size="1.5em"/>}
        nextLabel={<FaCaretRight size="1.5em"/>}
      />
      <div className="page-count align-items-center" >
        {showRows && 
          <div className='show-rows'>
            <label>Show rows</label>
            <select className='mv-10' onChange={onChangeShowRows} defaultValue={limit}>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="400">400</option>
              <option value="500">500</option>
            </select>
          </div>
        }

        <span className='block' style={{maxWidth: 300}}>
          Row {(page * limit - (limit - 1)).toLocaleString()} to &nbsp;
          {page * limit > totalDocs
            ? totalDocs.toLocaleString()
            : (page * limit).toLocaleString()}
          &nbsp; of &nbsp;
          {totalDocs.toLocaleString()}
        </span>

      </div>
    </div>
  )
}