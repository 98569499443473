import React from 'react'
import ReactTableContainer from 'utils/ReactTableContainer'
import Tooltip from '@leafygreen-ui/tooltip'
import IconButton from '@leafygreen-ui/icon-button'
import { FaEdit } from 'react-icons/fa'
import _ from 'lodash'

export default function FinishedGoodsInventoryList({ items, showModal, locationCode }) {
  // console.log('* FinishedGoodsInventoryList init')
  // console.log('- items: ', items)
  console.log('- locationCode: ', locationCode)
  let hiddenColumns = ["_id"]
  if(_.isNil(locationCode)) hiddenColumns.push("locationCode")
  // console.log('- hiddenColumns: ', hiddenColumns)
  const columns = React.useMemo(
    () => [
      { Header: "id", accessor: "_id" },
      { Header: "SKU", accessor: "sku"},
      { Header: "Location Code", accessor: "locationCode" },
      { Header: "Inventory Area", accessor: "areaCode" },
      { Header: "Stock", accessor: "stock" },
      { Header: "Action", accessor: "action",
        Cell: ({ row }) => {
          // console.log('- row: ', row)
          return (
            <div className="action-btn-list">
              <Tooltip
                align="left"
                justify="start"
                triggerEvent="hover"
                aria-labelledby="Edit"
                className='lg-tooltip-container'
                darkMode={true}
                trigger={  
                  <IconButton 
                    className="swing-icon"
                    aria-labelledby="Edit"
                    onClick={() => showModal("update", row.original)}
                  >
                    <FaEdit />
                  </IconButton>
                }
              >
                Edit
              </Tooltip>
            </div>  
          )
        }
      },
    ],
    // eslint-disable-next-line
    []
  )

  return (
    <ReactTableContainer
      columns={columns}
      data={items}
      hiddenColumns={hiddenColumns}
      sortBy={[{ id: "sku" }]}
    />
  )
}
