import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaBoxOpen } from "react-icons/fa";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import Banner from "@leafygreen-ui/banner";

const WorkOrderList = ({ orders, useMultipleLocations, workOrderStatuses }) => {
  console.log("* WorkOrderList init");
  // console.log("- orders: ", orders);
  const columns = [
    <TableHeader label="Order Number" sortBy={(datum) => datum.orderNumber} />,
    <TableHeader
      label="Date"
      sortBy={(datum) => datum.orderDate}
      dataType="date"
      style={{ minWidth: 110 }}
    />,
    <TableHeader label="Recipient" sortBy={(datum) => datum.shipTo.name} />,
    // <TableHeader label="Order Total" dataType="number" />,
    <TableHeader label="Ordered" dataType="number" />,
    <TableHeader label="Picked" dataType="number" />,
    <TableHeader label="Printed" dataType="number" />,
    <TableHeader label="Checked Out" dataType="number" />,
    <TableHeader label="Bin #" sortBy={(datum) => datum.local?.bin} />,
    <TableHeader label="Carrier Code" sortBy={(datum) => datum.carrierCode} />,
    <TableHeader label="Service Code" sortBy={(datum) => datum.serviceCode} />,
  ];
  // console.log('- columns: ', columns)
  if (useMultipleLocations) {
    columns.push(
      <TableHeader
        label="Location Code"
        sortBy={(datum) => datum.locationCode}
      />
    );
  }

  /*
  const WorkOrderStatus = ({ order, flag }) => {
    // console.log('* OrderStatus init')
    const styled = { backgroundColor: "#E1F2F6", color: "#1A567E" };

    const status = _.find(workOrderStatuses, {
      name: order.local?.workOrderStatus,
    });
    if (status) {
      // console.log('- status: ', status);
      styled.backgroundColor = status.color;
      styled.color = "#fff";
    }

    return (
      <select
        // defaultValue={""}
        // value={order?.local?.workOrderStatus ? order.local.workOrderStatus : ""}
        onChange={(e) => onChangeHandler({ order, value: e.target.value })}
        className={`work-order-status ${flag}`}
        style={styled}
      >
        <option style={{ backgroundColor: "#E1F2F6", color: "#1A567E" }}>
          = Pick Order Status
        </option>
        {workOrderStatuses.map((s) => {
          return (
            <option
              value={s.name}
              key={s._id}
              style={{
                backgroundColor: s.color,
                color: "#fff",
              }}
              selected={s.name === order?.local?.workOrderStatus}
            >
              {s.name}
            </option>
          );
        })}
      </select>
    );
  };
  */
  /*
  const onChangeHandler = ({ order, value }) => {
    //change select elem style
    console.log("- order: ", order);
    console.log("- value: ", value);
    let orderId = order?.orderId;
    let foundIndex = _.findIndex(orders, { orderId });
    console.log("- foundIndex: ", foundIndex);
    if (foundIndex !== -1) {
      const elems = document.querySelectorAll("select.shipstation");
      elems[foundIndex].selected = value;
      // console.log('- elems: ', elems)
      const status = _.find(workOrderStatuses, { name: value });
      // console.log('- status: ', status)
      if (status) {
        elems[foundIndex].style.backgroundColor = status.color;
        elems[foundIndex].style.color = "#fff";
      } else {
        elems[foundIndex].style.backgroundColor = "#E1F2F6";
        elems[foundIndex].style.color = "#1A567E";
      }
    }

    workOrderStatusHandler({ orderId, value });
  };
  */

  return (
    <>
      {orders && Boolean(orders?.docs?.length) ? (
        <div className="page-content card p-10 mt-10 shipstation-orders">
          <Table data={orders?.docs} columns={columns}>
            {({ datum: order }) => (
              <Row key={order.orderId}>
                <Cell>
                  <Link
                    to={`/workorder-details/${order.orderId}?source=shipstation`}
                  >
                    {/* {!order?.local && (
                      <FaBellSlash
                        color="red"
                        style={{ marginBottom: 3, marginRight: 5 }}
                      />
                    )} */}
                    {order.orderNumber}
                    {order.hasBundleItem && <FaBoxOpen />}
                  </Link>
                </Cell>
                <Cell>
                  {moment(order.orderDate).local().format("MM-DD-YYYY")}
                </Cell>
                <Cell style={{ width: 200 }}>
                  {order?.shipTo?.name ? order?.shipTo?.name : ""}
                </Cell>
                {/* <Cell>
                  {order?.orderTotal
                    ? order.orderTotal.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : 0}
                </Cell> */}
                <Cell>{order?.quantity ? order.quantity : 0}</Cell>
                <Cell>{order?.pickedQty ? order.pickedQty : 0}</Cell>
                <Cell>{order?.printedQty ? order.printedQty : 0}</Cell>
                <Cell>{order?.checkedOutQty ? order.checkedOutQty : 0}</Cell>
                <Cell>{order?.bin ? order?.bin : ""}</Cell>
                <Cell>{order?.carrierCode ? order.carrierCode : ""}</Cell>
                <Cell>{order?.serviceCode ? order.serviceCode : ""}</Cell>
                {useMultipleLocations && (
                  <Cell>{order?.locationCode ? order.locationCode : ""}</Cell>
                )}
              </Row>
            )}
          </Table>
        </div>
      ) : (
        <Banner variant="info">Orders not found</Banner>
      )}
    </>
  );
};

export default React.memo(WorkOrderList);

/* 
<table>
  <thead>
    <tr>
      <th>No</th>
      <th>Order #</th>
      <th>Date</th>
      <th>Recipient</th>
      <th>Ordered</th>
      <th>Order Total</th>
      <th>Printed</th>
      <th>Checked Out</th>
      <th>Bin #</th>
      <th>Carrier Code</th>
      <th>Service Code</th>
    </tr>
  </thead>
  <tbody>
    {orders.map((order, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>
            <Link to={`/workorder-details/${order.orderId}`}>
              {!order?.local && <FaBellSlash />}
              {order.orderNumber}
              {order.hasBundleItem && <FaBoxOpen />}
            </Link>
          </td>
          <td>
            {moment(order.orderDate).local().format("MM-DD-YYYY")}
          </td>
          <td>{order.shipTo.name}</td>
          <td className="text-center">
            {_.sumBy(order.items, (item) => {
              if (item.sku !== null) return item.quantity;
            })}
          </td>
          <td className="text-right">
            {order.orderTotal.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </td>
          <td className="text-center">{order?.local?.printedQty}</td>
          <td className="text-center">
            {" "}
            {order?.local?.checkedOutQty}
          </td>
          <td>{order?.local?.bin}</td>
          <td>{order.carrierCode}</td>
          <td>{order.serviceCode}</td>
        </tr>
      );
    })}
  </tbody>
</table>
*/
