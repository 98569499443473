import React from 'react'
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalTemplate from 'components/modals/ModalTemplate';
import Button from '@leafygreen-ui/button';
import { FaSave, FaTrashAlt } from 'react-icons/fa';

Modal.setAppElement("#root");

function SessionEditModal({ 
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  session,
  setSession,
  onChangeHandler,
  submitHanlder,
  deleteHandler
}) {
  // session && console.log('- session: ', session)
  const usedForEnum = ['Printing', 'Picking', 'Shipping', 'Picking & Shipping']
  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "400px", height: "auto" }}
    >
      <form onSubmit={submitHanlder} className="card modal-form">
        <div className="input-field">
          <label htmlFor="hostname">Hostname</label>
          <input type="text" id="hostname" name="hostname" value={session?.hostname} onChange={onChangeHandler} disabled={true}></input>
        </div>
        <div className="input-field">
          <label htmlFor="dateOfIssue">Issue Date</label>
          <DatePicker
            selected={session?.dateOfIssue ? new Date(session.dateOfIssue) : ''}
            onChange={(date) => setSession({ ...session, "dateOfIssue": date})}
          />
        </div>
        <div className="input-field">
          <label htmlFor="dateOfExpiry">Expire Date</label>
          <DatePicker
            selected={session?.dateOfExpiry ? new Date(session.dateOfExpiry) : ''}
            className="browser-default"
            onChange={(date) => setSession({ ...session, "dateOfExpiry": date })}
          />
        </div>
        <div className="input-field">
          <label htmlFor="usedFor">Used For</label>
          <select id="usedFor" name="usedFor" value={session?.usedFor ? session.usedFor : ''}
            onChange={e => {
              setSession({ ...session, [e.target.id]: e.target.value })
            }
          }>
            <option>== Select Used for ==</option>
            {usedForEnum.map((u, index) => <option value={u} key={index}>{ u }</option>)}
          </select>
        </div>

        <div className="input-field">
          <label htmlFor="alias">Alias</label>
          <input type="text" id="alias" name="alias" value={session?.alias ? session.alias : ''}
            onChange={e => {
              setSession({ ...session, [e.target.id]: e.target.value })
            }}>
          </input>
        </div>
        <div className="modal-footer">
          <Button
            variant="danger"
            className="swing-icon"
            onClick={() => deleteHandler()}
          >
            <FaTrashAlt />Delete
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="swing-icon"
            leftGlyph={<FaSave />}
          >
            Submit
          </Button>
        </div>
      </form>

    </ModalTemplate>
  )
}

export default React.memo(SessionEditModal)
