import React, { useState, useEffect, useContext, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import Pagination from "utils/Pagination";
import { Title } from "utils/Title";
import { errorHandler } from "utils/errorHandler";
import PrintedFilmsInventoryHeader from "./PrintedFilmsInventoryHeader";
import PrintedFilmsInventoryList from "./PrintedFilmsInventoryList";
import ModalPrintedFilmsInventory from "./ModalPrintedFilmsInventory";
import { jsonToCSV } from "react-papaparse";
import { SettingsContext } from "contexts/SettingsContext";
import { AuthContext } from "contexts/AuthContext";
import PageMainHeader from "components/PageMainHeader";
import Banner from "@leafygreen-ui/banner";
import PageMenuHeader from "components/PageMenuHeader";
import Search from "components/Search";
import { ModalPreloader } from "utils/Preloader";
import ScrollTop from "utils/ScrollTop";
import { deletePrintedFilm, fetchPrintedFilms, updatePrintedFilm } from "utils/printedFilms";
import { barcodeScannedHandler } from "utils/barcodeScannedHandler";
import socketIOClinet from "socket.io-client";
import axios from "axios";

const title = "Printed Films Inventory";
const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

const initialPrintedFilm = {
  graphicFilename: "",
  areaCode: "",
  stock: "",
};

const initialLimit = 50;

const computeReplenishment = (datum) => {
  let retVal = 0
  if(datum.stock < datum.lowStock) {
    retVal = datum.lowStock - datum.stock
  } else {
    if((datum.stock - datum.lowStock) < datum.anticipatedStock)
      retVal = datum.anticipatedStock - datum.stock
  }
  return retVal < 0 ? 0 : retVal;
}

export default function PrintedFilmsInventory() {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(initialPrintedFilm);
  const [mode, setMode] = useState("create");
  const [msg, setMsg] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [limit, setLimit] = useState(initialLimit);
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [searchTxt, setSearchTxt] = useState("");
  const [items, setItems] = useState(null)
  const modeRef = useRef("_createPrintedFilm")
  const queryClient = useQueryClient();

  const {
    data: printedFilmsInventory,
    isLoading,
    isFetching,
    isError,
    error,
    refetch
  } = useQuery(
    ["printedFilms", page],
    () =>
      fetchPrintedFilms({
        query: {},
        options: { page, limit },
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    }
  );

  const onBarcodeScanned = (data) => {
    console.log("* onBarcodeScanned init");
    console.log("[onBarcodeScanned] data: ", data);
    const { sku:graphicFilename } = barcodeScannedHandler(data);
    console.log('- graphicFilename: ', graphicFilename)
    if(graphicFilename) handleSearch(graphicFilename)
    // eslint-disable-next-line
  }

  const socketOnBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("* on-barcode-scanned")
      console.log("[on-barcode-scanned] data: ", data);
      const { sku:graphicFilename } = barcodeScannedHandler(data);
      console.log('- graphicFilename: ', graphicFilename)
      if(graphicFilename) handleSearch(graphicFilename)
    })
  }

  //onBarcodeScanned hook
  useEffect(() => {
    const listner = window.printflo_api && window.printflo_api.receive("serialport_readline", onBarcodeScanned)
    
    setTimeout(() => {
      _.isNil(window?.printflo_api) && socketOnBarcodeScanned();
    }, 1000);

    return () => {
      if(listner) listner();
      socket.removeAllListeners(["on-barcode-scanned"]);
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    // console.log("items in hook: ", items);
    if (items?.totalPages > page) {
      queryClient.prefetchQuery(
        ["printedFilms", page + 1],
        () =>
          fetchPrintedFilms({
            query: {},
            options: { page: page + 1, limit },
          }),
        {
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5,
        }
      );
    }
    // eslint-disable-next-line
  }, [page, items, queryClient]);

  useEffect(() => {
    console.log('* printedFilmsInventory init');
    // console.log('- printedFilmsInventory; ', printedFilmsInventory)
    if(printedFilmsInventory && Boolean(printedFilmsInventory?.docs.length)) {
      const temp = printedFilmsInventory.docs.map(doc => {
        return {...doc, replenishment: computeReplenishment(doc)}
      })

      // console.log(temp)
      setItems(() => ({...printedFilmsInventory, docs: temp}))
    } 
  }, [printedFilmsInventory])

  //onBarcodeScanCallback
  /* useEffect(() => {
    onBarcodeScanCallback();
    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
  }, [onBarcodeScanCallback]); */

  const showModalHandler = async (mode, item) => {
    console.log("* showModalHandler init");
    console.log("[showModalHandler] mode: ", mode);
    console.log("[showModalHandler] item: ", item);
    modeRef.current = mode;
    setMode(mode);
    if (msg) setMsg(null);
    if (mode === "_createPrintedFilm") {
      modeRef.current = "_updatePrintedFilm";
      setItem(initialPrintedFilm);
    } else {
      setItem(item);
    }
    // console.groupEnd();
    setModalIsOpen(true);
  };

  const mutatePrintedFilm = useMutation(
    async (payload) => {
      console.log('[mutatePrintedFilm] payload: ', payload)
      console.log('[mutatePrintedFilm] modeRef: ', modeRef.current)
      let result;
      if(window?.printflo_api) {
        result = await window.printflo_api[modeRef.current](payload);
        if(_.isError(result)) throw result;
      } else {
        const action = _.replace(modeRef.current, "_", "").replace("PrintedFilm", "");
        console.log("[mutatePrintedFilm] action:replace ", action, typeof action);
        try {
          const response = await axios.post(`/api/printed-film/${action}`, payload);
          result = response?.data;
        } catch (error) {
          throw error
        }
      }
      return result;
    },
    {
      onError: (error) => {
        console.log('[mutatePrintedFilm] error: ', error)
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: (result) => {
        console.log('[mutatePrintedFilm] result: ', result)
        // console.log('-searchTxt: ', searchTxt)
        if (!modeRef.current.includes("delete") && searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          queryClient.invalidateQueries(["printedFilms", page]);
        }
      },
    }
  );

  const submitHandler = () => {
    console.log("* submitHandler init");
    console.log("[submitHandler] item: ", item);
    console.log("[submitHandler] mode: ", mode);
    console.log("[submitHandler] modeRef: ", modeRef.current);
    setModalIsOpen(false);
    const { graphicFilename } = item;
    mutatePrintedFilm.mutate({
      condition: { graphicFilename },
      update: item,
    });
  };

  const deletePrintedFilmHandler = () => {
    setModalIsOpen(false);
    modeRef.current = "_deletePrintedFilm";
    mutatePrintedFilm.mutate({_id:item._id});
    // handleDelete.mutate();
  };

  /*
  const handleDelete = useMutation(
    async () => {
      return await deletePrintedFilm({ id: item._id, sku: item.sku });
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: () => {
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          queryClient.invalidateQueries(["printedFilms", page]);
          
        }
      },
    }
  );
  */

  const fetchSearchResults = useMutation(
    async (searchTxt) => {
      console.log("[fetchSearchResults] searchTxt: ", searchTxt);
      setLoading(true);
      let results;
      const payload = { searchTxt, options: { pagination: false } };
      if(window?.printflo_api) {
        results = await window.printflo_api._searchPrintedFilms(payload);
        if(_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.post(`/api/printed-film/search`, payload);
          results = response?.data
        } catch (error) {
          throw error
        }
      }
      return results;
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: async (result) => {
        console.log("[fetchSearchResults:onSuccess] result: ", result);
        setLoading(false);
        if(Boolean(result?.docs.length)) {
          queryClient.setQueryData(["printedFilms", page], (old) => {
            return { ...old, ...result };
          });
          setLimit(result?.limit);
        } else {
          console.log("[fetchSearchResults:onSuccess] case of none for search result");
          setMsg('Not found search results')
        }
      },
    }
  );

  const handleSearch = async (searchTxt) => {
    msg && setMsg(null)
    if (!_.isEmpty(searchTxt)) {
      setSearchTxt(searchTxt);
      fetchSearchResults.mutate(searchTxt);
    }
  };


  const exportPrintedFilms = async () => {
    setLoading(true);
    setMsg(null);
    // console.log(selectedExportMode);
    let temp = [];

    if (selectedExportMode === "all") {
      await fetchPrintedFilms({
        query: {},
        options: {
          pagination: false,
        },
      })
        .then((results) => {
          // console.log("get all inventory area", results)
          temp = _.orderBy(results.docs, ["graphicFilename"], ["asc"]);
        })
        .catch((error) => {
          console.log("[exportPrintedFilms:all] error: ", error);
          alert("Error occured while retrieving printed films inventory");
          return;
        });
      
    } else {
      temp = _.cloneDeep(_.orderBy(items.docs, ["graphicFilename"], ["asc"]));
    }

    // console.log("exportInventoryArea", exportInventoryArea);

    const result = jsonToCSV({
      fields: ["graphicFilename", "areaCode", "stock", "lowStock", "anticipatedStock"],
      data: temp,
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `printed_films_inventories_${moment().format(
      "YYYYMMDDHHmm"
    )}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoading(false);
  };

  const importPrintedFilms = (parsedData) => {
    try {
      setMsg(null);

      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of importing printed films inventory: ${temp.length}`;
      setMsg(msg);
      setLoading(true)

      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        temp[i].graphicFilename = temp[i].graphicFilename.trim();
        let graphicFilename = temp[i].graphicFilename;
        // let areaCode = temp[i].areaCode;
        // let stock = temp[i].stock;
        // let lowStock = temp[i].lowStock;
        // let anticipatedStock = temp[i].anticipatedStock;

        // console.log(i, temp[i]);
        if (graphicFilename) {
          try {
            const result = await updatePrintedFilm({
              condition: { graphicFilename },
              update: temp[i],
            });
            // console.log("updatePrintedFilm result", result);
            setMsg(`${i + 1}/${temp.length}: ${result.graphicFilename} updated.`);
            i += 1;
          } catch (error) {
            console.log("[importPrintedFilms:_updatePrintedFilm] error: ", error);
            setMsg(`Error occured, try again from this graphicFilename: ${graphicFilename}`);
            return;
          }
        }

        if (i <= len && _.has(temp[i], "graphicFilename")) {
          loop();
        } else {
          setMsg(
            "* Completed importing printed films inventory, click refresh to load a page."
          );
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      console.log("[importPrintedFilms] error: ", error);
      const retval = errorHandler(error);
      setMsg(retval)
    }
  };

  const handleOnPageChange = ({ selected }) => {
    // console.log("handleOnPageChange page", selected);
    msg && setMsg(null)
    setPage(selected + 1);
  };

  const batchDelete = (parsedData) => {
    console.log("* batchDelete init");
    try {
      setLoading(true);
      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of printed films inventory status for deleting: ${temp.length}`;
      setMsg(msg);
      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        let graphicFilename = temp[i].graphicFilename;
        if(graphicFilename) {
          try {
            await deletePrintedFilm({ graphicFilename });
            // await deletePrintedFilmByGraphicFilename({ graphicFilename });
            setMsg(`${i + 1}/${temp.length}: ${graphicFilename} deleted.`);
            i += 1;
          } catch (error) {
            console.log("deletePrintedFilm error", error);
            const retval = errorHandler(error);
            setMsg(retval);
            return;
          }
  
          if (i <= len && _.has(temp[i], "graphicFilename")) {
            loop();
          } else {
            setMsg(
              "* Completed batch deletion of the printed flims inventory, click refresh to load a page."
            );
            setLoading(false);
            return;
          }
        } else {
          throw new Error('Graphic file name not exists')
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      const retval = errorHandler(error);
      console.log("[batchDelete] error: ", retval);
      setMsg(retval);
    }
  };

  const handleReplenishment = (e, datum) => {
    console.log('* handleReplenishment init')
    console.log('- e.target.value: ', e.target.value)
    console.log('- datum: ', datum)
    const idx = _.findIndex(items.docs, {_id: datum._id})
    console.log('- idx: ', idx)
    setItems(current => {
      current.docs[idx]['replenishment'] = e.target.value;
      return current
    })
  }

  const onChangeShowRows = (e) => {
    console.log('* onChangeShowRows init')
    console.log('- rows: ', e.target.value)
    if (page > 1) {
      alert("Requried to move page 1");
      return;
    }
    const rows = e.target.value
    if(limit !== rows) {
      setLimit(rows)
      setTimeout(() => refetch(), 1500)
    }
  }

  const showUnderLowStock = async () => {
    console.log('* showUnderLowStock init')
    setLoading(true)
    fetchPrintedFilms({
      query: {$expr: {$lt: ['$stock', '$lowStock']}},
      options: { page, limit },
    }).then(response => {
      if(response?.docs) {
        setLoading(false)
        if(Boolean(response.docs.length)) {
          queryClient.setQueryData(['printedFilms', page], response)
        } else {
          setMsg('Not found results')
        }
      }
    }).catch(error => {
      setLoading(false)
      const retVal = errorHandler(error)
      console.log('- error retVal: ', retVal)
      setMsg(retVal)
    })
    
  }

  return (
    <>
      <Title title={title} />
      <ToastContainer theme="dark" />
      <PageMainHeader title={title} user={user} settings={settings} />
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant="danger">
            {error?.message ? error.message : error}
          </Banner>
        ) : (
          <>
            <PageMenuHeader>
              <PrintedFilmsInventoryHeader
                showModal={showModalHandler}
                importHandler={importPrintedFilms}
                exportHandler={exportPrintedFilms}
                selectedExportMode={selectedExportMode}
                setSelectedExportMode={setSelectedExportMode}
                batchDeleteHandler={batchDelete}
                showUnderLowStock={showUnderLowStock}
              />
              <Search handleSearch={handleSearch} placeholderText={"Search"} />
            </PageMenuHeader>

            {items && (
              <Pagination
                handleOnPageChange={handleOnPageChange}
                totalDocs={items?.totalDocs}
                totalPages={items?.totalPages}
                page={page}
                limit={limit}
                onChangeShowRows={onChangeShowRows}
                showRows={true}
              />
            )}

            {msg && <Banner className="mb-10">{msg}</Banner>}

            {items && Boolean(items?.docs.length) ? (
              <PrintedFilmsInventoryList
                items={items.docs}
                showModal={showModalHandler}
                handleReplenishment={handleReplenishment}
              />
            ) : (
              <Banner>Inventory status not found</Banner>
            )}
          </>
        )}
        <ScrollTop />
      </section>
      <ModalPrintedFilmsInventory
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        title={"Printed Films Inventory Status"}
        mode={mode}
        item={item}
        setItem={setItem}
        submitHandler={submitHandler}
        handleDelete={deletePrintedFilmHandler}
        user={user}
      />
      <ModalPreloader modalPreloaderIsOpen={ isLoading || isFetching || loading} />
    </>
  );
}
