import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {format, differenceInCalendarDays} from 'date-fns'
import SessionEditModal from './SessionEditModal'
import {Table, TableHeader, Row, Cell} from "@leafygreen-ui/table"
import IconButton from "@leafygreen-ui/icon-button"
import { FaEdit } from 'react-icons/fa'
import { getSessions } from 'utils/sessions'
import axios from 'axios'
import _ from "lodash"
import { errorHandler } from 'utils/errorHandler'

const title="Sessions"

export default function Sessions() {
  const { settings, hostname } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [modalSessionIsOpen, setModalSessionIsOpen] = React.useState(false)
  const [session, setSession] = React.useState();
  const modeRef = React.useRef('_updateSession')

  
  const queryClient = useQueryClient()

  const { data: sessions, isLoading, isError, error } = useQuery(['sessions'],
    async () => { return await getSessions({useStoredSessions: false, hostname}) },
  {
    refetchOnWindowFocus: false  
  })
  // console.log('- sessions: ', sessions)

  const mutateSession = useMutation(async () => {
    console.log('* mutateSession init')
    console.log('[mutateSession] session: ', session);
    console.log('[mutateSession] modeRef: ', modeRef.current);
    let { _id, dateOfExpiry, dateOfIssue, alias, usedFor, lastActivityAt } = session
    
    dateOfExpiry = new Date(dateOfExpiry)
    dateOfIssue = new Date(dateOfIssue)
    lastActivityAt = new Date()
    const payload = { _id, dateOfExpiry, dateOfIssue, alias, usedFor, lastActivityAt };
    let result;
    if(window?.printflo_api) {
      result = await window.printflo_api[modeRef.current](payload);
      if(_.isError(result)) throw result;
    } else {
      if(modeRef.current.includes("_delete")) {
        try {
          const response = await axios.delete(`/api/sessions/delete/${_id}`)
          result = response?.data
        } catch (error) {
          throw error
        }
      } else {
        try {
          const response = await axios.post("/api/sessions", payload)
          result = response?.data
        } catch (error) {
          throw error
        }
      }
    }

  }, {
    onSuccess: response => {
      console.log('[mutateSession:onSuccess]  response: ', response)
      queryClient.invalidateQueries('sessions')
    },
    onError: (error) => {
      console.log("[mutateSession] error: ", error);
      const retval = errorHandler(error)
      console.log("[mutateSession] retval: ", retval);
      toast.info(retval, {
        position: "bottom-right"
      })
    }
  })

  const editSession = (row) => {
    console.log('* editSession init')
    console.log('[editSession] row: ', row)
    setModalSessionIsOpen(true)
    setSession(row)
  }

  const onChangeHandler = (e) => {
    console.log('* onChangeHandler init')
    // console.log('- e.target.id: ', e.target.id)
    // console.log('- e.target.value: ', e.target.value)
    setSession(current => {
      // console.log('-current: ', current)
      return {...current, [e.target.id]: e.target.value}
    })
  }

  const submitHanlder = (e) => {
    console.log('* submitHanlder init')
    e.preventDefault()
    setModalSessionIsOpen(false)
    mutateSession.mutate()
  }

  const deleteHandler = () => {
    console.log('* deleteHanlder init')
    setModalSessionIsOpen(false);
    modeRef.current = "_deleteSession"
    var confirm = window.confirm('Confirm to delete a session.')
    // console.log('- confirm: ', confirm)
    if(confirm) mutateSession.mutate()
  }

  return (
    <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
        <ToastContainer theme='dark'/>
        <section className="primary">
          {isLoading ? (
            <Skeleton count={20} height={50} circle={true} />
          ) : isError ? (
            <Banner variant='danger'>{error?.message ? error.message : error}</Banner>
          ) : user?.username === "super" ? (
            <Table 
              data={sessions} 
              columns={
                [
                  <TableHeader label="Host Name" sortBy={(datum) => (datum.hostname)}/>,
                  <TableHeader label="IP Address" />,
                  <TableHeader label="Machine ID" />,
                  <TableHeader label="Remote IP" sortBy={(datum) => (datum.remoteIP)}/>,
                  <TableHeader label="Date of Issue" sortBy={(datum) => (datum.dateOfIssue)}/>,
                  <TableHeader label="Date of Expiry" sortBy={(datum) => (datum.dateOfExpiry)}/>,
                  <TableHeader label="Days remaining" sortBy={(datum) => (differenceInCalendarDays(new Date(datum.dateOfExpiry), new Date()))}/>,
                  <TableHeader label="Last Activity At" sortBy={(datum) => (datum.lastActivityAt)}/>,
                  <TableHeader label="Alias" sortBy={(datum) => (datum.alias)}/>,
                  <TableHeader label="Action" />
                ]
              }
            >
              {({datum}) => (
                <Row key={datum._id}>
                  <Cell>{datum?.hostname ? datum.hostname : ""}</Cell>,
                  <Cell>{datum?.ip ? datum.ip : ""}</Cell>,
                  <Cell>{datum?.machineId ? datum.machineId : ""}</Cell>,
                  <Cell>{datum?.remoteIP ? datum.remoteIP : ""}</Cell>,
                  <Cell>{datum?.dateOfIssue ?  format(new Date(datum.dateOfIssue), 'MM-dd-yyyy') : ""}</Cell>,
                  <Cell>{datum?.dateOfExpiry ?  format(new Date(datum.dateOfExpiry), 'MM-dd-yyyy') : ""}</Cell>,
                  <Cell>
                    {datum.dateOfExpiry ? `${differenceInCalendarDays(new Date(datum.dateOfExpiry), new Date())} days` : ""}
                  </Cell>,
                  <Cell>
                    {datum.lastActivityAt ? format(new Date(datum.lastActivityAt), 'MM-dd-yyyy') : ""}
                  </Cell>,
                    
                  <Cell>{datum?.alias ? datum.alias : ""}</Cell>,
                  <Cell>
                    <IconButton
                      className="swing-icon" 
                      onClick={() => editSession({ ...datum })}
                      aria-label="Edit a session"
                      disabled={true}
                    >
                      <FaEdit />  
                    </IconButton>
                  </Cell>,
                  
                </Row>
              )}
            </Table>
          ) : (
            <Banner variant='danger'>Access denied</Banner>
          )}
        </section>
        <SessionEditModal
          modalIsOpen={modalSessionIsOpen}
          setModalIsOpen={setModalSessionIsOpen}
          session={session}
          setSession={setSession}
          onChangeHandler={onChangeHandler}
          submitHanlder={submitHanlder}
          deleteHandler={deleteHandler}
          mode="edit"
          title="Session"
        />
    </>
    )
}
