import axios from "axios";
import { errorHandler } from "./errorHandler";
import _ from "lodash";

export const getS3presign = ({ key }) => {
  console.log("* utils:awsS3:getS3presign init");
  console.log("[utils:awsS3:getS3presign] key: ", key);
  return new Promise(async (resolve, reject) => {
    let result;

    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._getS3presign({ key });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/awss3/s3-presign`, { key });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:awsS3:getS3presign] result: ", result);
      resolve(result);
    } catch (error) {
      console.log("[utils:awsS3:getS3presign] error: ", error);
      reject(errorHandler(error));
    }
  });
};
