import React from "react";
import Msg from "components/Msg";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";

const ActivityItemsList = ({ data, useARXfile }) => {
  console.log("* OrderItemsActivityList init");
  // console.log("- data: ", data);
  // console.log("- useARXfile: ", useARXfile);
  const columns = [
    <TableHeader label="SKU" sortBy={(datum) => datum.sku} />,
    <TableHeader label="Component" sortBy={(datum) => datum.component} />,
    <TableHeader label="Graphics" sortBy={(datum) => datum.graphics} />,
    <TableHeader label="Category" sortBy={(datum) => datum.category} />,
    <TableHeader label="color" sortBy={(datum) => datum.color} />,
    <TableHeader
      label="Total"
      sortBy={(datum) => datum.total}
      dataType="number"
    />,
  ];

  const getGraphicsFilename = (datum) => {
    // console.log("* getGraphicsFilename init");
    // console.log("- datum: ", datum);
    let graphicFilename = "";
    let tempArray = [];

    for (let graphic of datum.graphics) {
      let temp = useARXfile ? graphic.arxFileName : graphic.graphicFileName;
      tempArray.push(temp);
    }
    graphicFilename = tempArray.join(", ");

    return graphicFilename;
  };

  return (
    <>
      {Boolean(data.length) ? (
        <div className="page-content card p-10">
          <Table data={data} columns={columns}>
            {({ datum }) => (
              <Row key={datum.sku}>
                <Cell title={datum.description}>{datum.sku}</Cell>
                <Cell>{datum?.component ? datum.component : ""}</Cell>
                <Cell>{getGraphicsFilename(datum)}</Cell>
                <Cell>{datum?.category ? datum.category : ""}</Cell>
                <Cell>{datum?.color ? datum.color : ""}</Cell>
                <Cell>{datum?.total ? datum?.total : 0}</Cell>
              </Row>
            )}
          </Table>
        </div>
      ) : (
        <div className="mt-10">
          <Msg msg={"Activities not found."} />
        </div>
      )}
    </>
  );
};

export default React.memo(ActivityItemsList);
