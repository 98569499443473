import React, { useState, useEffect, useContext, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import Pagination from "utils/Pagination";
import { Title } from "utils/Title";
import { errorHandler } from "utils/errorHandler";
import { getItemDoc } from "utils/items";
import InventoryHeader from "./InventoryHeader";
import InventoryList from "./InventoryList";
import ModalInventory from "./ModalInventory";
import { jsonToCSV } from "react-papaparse";
import { SettingsContext } from "contexts/SettingsContext";
import { AuthContext } from "contexts/AuthContext";
import PageMainHeader from "components/PageMainHeader";
import Banner from "@leafygreen-ui/banner";
import PageMenuHeader from "components/PageMenuHeader";
import Search from "components/Search";
import { ModalPreloader } from "utils/Preloader";
import ScrollTop from "utils/ScrollTop";
import {
  getInventoryAreas,
  deleteInventoryArea,
  submitInventoryArea,
  searchInventoryAreas,
} from "utils/inventoryArea";
import { barcodeScannedHandler } from "utils/barcodeScannedHandler";
import socketIOClient from "socket.io-client";
import { Option, Select } from "@leafygreen-ui/select";
import { Tabs, Tab } from "@leafygreen-ui/tabs";

const title = "Inventory Status";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const initialInventoryArea = {
  sku: "",
  areaCode: "",
  stock: "",
};

const initialLimit = 50;

export default function Inventory() {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(initialInventoryArea);
  const [mode, setMode] = useState("create");
  const [msg, setMsg] = useState(null);
  const [pageFG, setPageFG] = useState(1);
  const [pageComp, setPageComp] = useState(1);
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [limit, setLimit] = useState(initialLimit);
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [searchTxt, setSearchTxt] = useState("");
  const locationCodeRef = useRef(null);
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const inventoryTypes = [ {title: 'Finished Goods', code: 0, value: 'production' }, {title: 'Components', code: 1, value: 'component'} ];
  const matchedTypeRef = React.useRef();

  // console.log("formRef", formRef);
  // console.log("- item: ", item);
  // console.log("- inventoryTypes: ", inventoryTypes);
  // console.log("- selectedTab: ", selectedTab);
  
  useEffect(() => {
    if(user && user?.locationCode) locationCodeRef.current = user.locationCode;
  }, [user])

  useEffect(() => {
    // console.log("selectedTab init")
    // console.log("selectedTab: ", selectedTab)
    matchedTypeRef.current = _.find(inventoryTypes, {code: selectedTab})
    // console.log("matchedTypeRef", matchedTypeRef);
  // eslint-disable-next-line
  }, [selectedTab])

  const {
    data: items,
    isLoading,
    isError,
    error,
    refetch: refetchInventoryAreaFG
  } = useQuery(
    ["inventoryAreaFG", pageFG],
    () =>
      getInventoryAreas({
        query: {locationCode: locationCodeRef.current, type: 'production'},
        options: { page: pageFG, limit },
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    }
  );

  const {
    data: components,
    // isLoading,
    // isError,
    // error,
    refetch: refetchInventoryAreaComp
  } = useQuery(
    ["inventoryAreaComps", pageComp],
    () =>
      getInventoryAreas({
        query: {locationCode: locationCodeRef.current, type: 'component'},
        options: { page: pageComp, limit },
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    }
  );
  
  useEffect(() => {
    // console.log("items in hook: ", items);
    if (items?.totalPages > pageFG) {
      queryClient.prefetchQuery(
        ["inventoryAreaFG", pageFG + 1],
        () =>
          getInventoryAreas({
            query: {locationCode: locationCodeRef.current, type: 'production'},
            options: { page: pageFG + 1, limit },
          }),
        {
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5,
        }
      );
    }
    // eslint-disable-next-line
  }, [pageFG, items, queryClient]);
  
  useEffect(() => {
    // console.log("components in hook init: ");
    // console.log("components in hook component: ", components);
    // console.log("components in hook pageComp: ", pageComp);
    if (components?.totalPages > pageComp) {
      queryClient.prefetchQuery(
        ["inventoryAreaComps", pageComp + 1],
        () =>
          getInventoryAreas({
            query: {locationCode: locationCodeRef.current, type: 'component'},
            options: { page: pageComp + 1, limit },
          }),
        {
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5,
        },
        {
          onSuccess: (data) => {
            console.log('onSucess data: ', data)
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [pageComp, components, queryClient]);

  const onBarcodeScanned = (data) => {
    console.log("* onBarcodeScanned init");
    console.log("[onBarcodeScanned] data: ", data);
    if(data) {
      const { sku:code } = barcodeScannedHandler(data);
      setSearchTxt(code);
      setPageFG(1);
      fetchSearchResults.mutate(code);
    }
  };

  const socketOnBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("* on-barcode-scanned")
      console.log("[on-barcode-scanned] data: ", data);
      if(data) {
      const { sku:code } = barcodeScannedHandler(data);
      setSearchTxt(code);
      setPageFG(1);
      fetchSearchResults.mutate(code);
    }
    })
  }

  //onBarcodeScanned hook
  useEffect(() => {
    const listner = window?.printflo_api && window.printflo_api.receive("serialport_readline", onBarcodeScanned)
    
    setTimeout(() => {
      _.isNil(window?.printflo_api) && socketOnBarcodeScanned();
    }, 1000);

    return () => {
      if(listner) listner();
      socket.removeAllListeners(["on-barcode-scanned"]);
    }
    // eslint-disable-next-line
  }, []);


  const showModalHandler = async (mode, item) => {
    console.log("* showModalHandler init");
    console.groupCollapsed();
    // console.log("- mode: ", mode);
    // console.log("- item: ", item);
    setMode(mode);
    if (msg) setMsg(null);
    if (mode === "create") {
      setItem(initialInventoryArea);
    } else {
      setItem(item);
      const temp = await getItemDoc(item.sku);
      // console.log("- temp: ", temp);
      let imageUrl;
      if (!_.isNil(temp?.imageUrl) && !_.isEmpty(temp?.imageUrl)) {
        imageUrl = temp.imageUrl;
      }
      if (temp) {
        setItem({
          ...item,
          imageUrl,
        });
      }
    }
    console.groupEnd();
    setModalIsOpen(true);
  };

  const mutateInventoryArea = useMutation(
    async (payload) => {
      return await submitInventoryArea(payload);
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: () => {
        // console.log('-searchTxt: ', searchTxt)
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          let queryKey = "inventoryAreaFG";
          let pageType = pageFG;

          if(selectedTab !== 0) {
            queryKey = "inventoryAreaComps"
            pageType = pageComp
          }
          queryClient.invalidateQueries([queryKey, pageType]);
        }
      },
    }
  );

  const submitHandler = () => {
    console.log("* submitHandler init");
    console.log("onsubmit item", item);
    // console.log("mode", mode);
    // inventoryAreaModalInstance.close();
    setModalIsOpen(false);
    if(!_.isNil(item?.sku)) item.sku = item.sku.trimLeft(); 
    
    let clonedItem = _.cloneDeep(item);
    
    let { sku, areaCode, locationCode, _id, type } = clonedItem;
    console.log('_id: ', _id);
    
    let query = {};

    if(!_.isNil(_id)) {
      query["_id"] = _id;
      delete clonedItem._id;
    } else {
      query["sku"] = sku;
      query["areaCode"] = areaCode;
      if(!_.isNil(locationCode)) query["locationCode"] = locationCode;
      if(!_.isNil(type)) query["type"] = type;

    } ;

    console.log("query: ", query);
    console.log("clonedItem: ", clonedItem);

    mutateInventoryArea.mutate({
      condition: query,
      update: clonedItem,
    });
  };

  const deleteInventoryAreaHandler = () => {
    setModalIsOpen(false);
    handleDelete.mutate();
  };

  const handleDelete = useMutation(
    async () => {
      const { _id, sku, areaCode, locationCode, type} = item;
      return await deleteInventoryArea({ _id, sku, areaCode, locationCode, type });
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: () => {
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          let queryKey = "inventoryAreaFG";
          let pageType = pageFG;

          if(selectedTab !== 0) {
            queryKey = "inventoryAreaComps"
            pageType = pageComp
          }
          queryClient.invalidateQueries([queryKey, pageType]);
          // queryClient.invalidateQueries(["inventoryArea", pageFG]);
        }
      },
    }
  );

  const fetchSearchResults = useMutation(
    async (searchTxt) => {
      console.log("* fetchSearchResults:mutation init")
      console.log("[fetchSearchResults:mutation] searchTxt: ", searchTxt);
      console.log("[fetchSearchResults:mutation] locationCodeRef.current: ", locationCodeRef.current);
      setLoading(true);
      return searchInventoryAreas({searchTxt, options: { pagination: false }, locationCode: locationCodeRef.current});
    },
    {
      onError: (error) => {
        console.error("[fetchSearchResults:mutation] error: ", error);
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: async (result, variables) => {
        console.log("[fetchSearchResults:mutation:onSuccess]  result: ", result);
        console.log("[fetchSearchResults:mutation:onSuccess] variables: ", variables);
        let queryKey = "inventoryAreaFG";
        let pageType = pageFG;

        if(selectedTab !== 0) {
          queryKey = "inventoryAreaComps"
          pageType = pageComp
        }

        if(Boolean(result?.docs.length) && matchedTypeRef.current) {
          if(result.docs[0]?.type !== matchedTypeRef.current.value) {
            const resultType = result.docs[0]?.type;
            const t = _.find(inventoryTypes, {value: resultType});
            // console.log("t ", t)
            if(t) {
              if(t.code === 1) {
                queryKey = "inventoryAreaComps"
                pageType = pageComp
              } else {
                queryKey = "inventoryAreaFG";
                pageType = pageFG;
              }
              
              setSelectedTab(t.code)
            }
          }
        }
        
        queryClient.setQueryData([queryKey, pageType], (old) => {
          return { ...old, ...result };
        });

        setLimit(result?.limit);
        setLoading(false);
        if (!Boolean(result?.docs.length)) {
          console.log("[fetchSearchResults:mutation:onSuccess] case of none for search result");
          console.log("[fetchSearchResults:mutation:onSuccess] action: find an item doc by searchTxt: ", variables);
          const itemDoc = await getItemDoc(variables);
          // console.log('- itemDoc: ', itemDoc)
          if (itemDoc?.sku) {
            console.log("[fetchSearchResults:mutation:onSuccess] case of exists sku on itemDoc");
            console.log(
              "[fetchSearchResults:mutation:onSuccess] action: modal pop-up w/ sku and focus on inventory area field"
            );
            setMode("create");
            let imageUrl;
            if (!_.isNil(itemDoc?.imageUrl) && !_.isEmpty(itemDoc?.imageUrl)) {
              imageUrl = itemDoc.imageUrl;
            }

            setItem({ ...initialInventoryArea, sku: itemDoc.sku, imageUrl });
            setModalIsOpen(true);
          }
        }
      },
    }
  );

  const handleSearch = async (searchTxt) => {
    console.log("- searchTxt", searchTxt);
    if (!_.isEmpty(searchTxt)) {
      setSearchTxt(searchTxt);
      fetchSearchResults.mutate(searchTxt);
    }
  };

  //onBarcodeScanned hook
  useEffect(() => {
    setTimeout(() => {
      onBarcodeScanned();
      setLoading(false);
    }, 1000);

    // eslint-disable-next-line
  }, []);

  const exportInvetoryArea = async () => {
    console.log("[exportInvetoryArea] init");
    
    setLoading(true);
    setMsg(null);
    console.log("[exportInvetoryArea] selectedExportMode", selectedExportMode);
    let exportInventoryArea = [];
    let queryObj = {};
    if(!_.isNil(locationCodeRef.current)) queryObj['locationCode'] = locationCodeRef.current;
    if(!_.isNil(matchedTypeRef.current)) queryObj['type'] = matchedTypeRef.current.value;
    console.log("[exportInvetoryArea] queryObj", queryObj);

    if (selectedExportMode === "all") {
      await getInventoryAreas({
        query: queryObj,
        options: {
          pagination: false,
        },
      })
      .then((results) => {
        console.log("get all inventory area", results)
        exportInventoryArea = results.docs;
      })
      .catch((error) => {
        console.log("get all invnetory areas error", error);
        alert("Error occured while retrieving all inventory areas");
        return;
      });
    } else {
      exportInventoryArea = _.cloneDeep(items.docs);
    }

    // console.log("exportInventoryArea", exportInventoryArea);
    let fieldsArr = ["sku", "areaCode", "stock"]
    if(settings?.useMultipleLocations) {
      fieldsArr.push("locationCode", "type");
    } 
    
    const result = jsonToCSV({
      fields: fieldsArr,
      data: exportInventoryArea,
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `inventory_status_${moment().format(
      "YYYYMMDDHHmm"
    )}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoading(false);
  };

  const importInventoryAreas = (parsedData) => {
    try {
      setMsg(null);

      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of finished goods inventory status: ${temp.length}`;
      setMsg(msg);
      //for test
      // temp = temp.slice(0,10);

      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        temp[i].sku = temp[i].sku.trim();
        let sku = temp[i].sku;
        let areaCode = temp[i].areaCode;
        let stock = temp[i].stock;
        let locationCode = temp[i]?.locationCode;
        let type = temp[i]?.type;

        // console.log(i, temp[i]);
        if (sku && areaCode && stock && parseInt(stock) > 0) {
          try {
            let query = { sku, areaCode };
            if(settings?.useMultipleLocations && !_.isNil(locationCodeRef.current)) query['locationCode'] = locationCode;
            if(type && matchedTypeRef.current) query["type"] = type;

            const result = await submitInventoryArea({
              condition: query,
              update: temp[i],
            });
            // console.log("submitInventoryArea result", result);
            setMsg(`${i + 1}/${temp.length}: ${result.sku} updated.`);
            i += 1;
          } catch (error) {
            console.log("submit item error", error);
            setMsg(`Error occured, try again from this sku: ${sku}`);
            return;
          }
        } else {
          i += 1;
        }

        if (i <= len && _.has(temp[i], "sku")) {
          loop();
        } else {
          setMsg(
            "* Completed import inventory area status, click refresh to load a page."
          );
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }

    } catch (error) {
      console.log("importInventoryArea error", error);
    }
  };

  const handleOnPageChange = ({ selected }) => {
    // console.log("handleOnPageChange page", selected);
    // console.log("handleOnPageChange selectedTab", selectedTab);
    if(selectedTab === 0) setPageFG(selected + 1)
    else setPageComp(selected + 1)
  };

  const batchDelete = (parsedData) => {
    console.log("* batchDelete init");
    try {
      setLoading(true);
      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of finished goods inventory status for deleting: ${temp.length}`;
      setMsg(msg);
      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        let sku = temp[i]?.sku.trim();
        let areaCode = temp[i]?.areaCode.trim();
        let locationCode = temp[i]?.locationCode.trim();
        let type = temp[i]?.type;
        let query = { sku, areaCode };
        if(settings?.useMultipleLocations && !_.isNil(locationCodeRef.current)) query['locationCode'] = locationCode;
        if(type && matchedTypeRef.current) query["type"] = type;
        
        try {
          const result = await deleteInventoryArea(query);
          
          // console.log("submitInventoryArea result", result);
          if(result?.n > 0) {
            setMsg(`${i + 1}/${temp.length}: ${sku} deleted.`);
          } else {
            setMsg(`${i + 1}/${temp.length}: ${sku} not deleted.`);
          }
          i += 1;
        } catch (error) {
          console.log("deleteInventoryAreaBySku error", error);
          const retval = errorHandler(error);
          setMsg(retval);
          return;
        }

        if (i <= len && _.has(temp[i], "sku")) {
          loop();
        } else {
          setMsg(
            "* Completed batch deletion of the inventory area status, click refresh to load a page."
          );
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      const retval = errorHandler(error);
      console.log("- batchDelete error: ", retval);
      setMsg(retval);
    }
  };

  const onChangeLocationCodeHander = (v) => {
    console.log("* onChangeLocationCodeHander init");
    // console.log("[onChangeLocationCodeHander] v: ", v);
    if(v==="all") v = null;
    locationCodeRef.current = v;
    // console.log("[onChangeLocationCodeHander] locationCode: ", locationCodeRef)
    refetchInventoryAreaFG();
    refetchInventoryAreaComp();
  }

  return (
    <>
      <Title title={title} />
      <ToastContainer theme="dark" />
      <PageMainHeader title={title} user={user} settings={settings} />
      
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant="danger">
            {error?.message ? error.message : error}
          </Banner>
        ) : (
          <>
            <PageMenuHeader>
              <InventoryHeader
                showModal={showModalHandler}
                importHandler={importInventoryAreas}
                exportHandler={exportInvetoryArea}
                selectedExportMode={selectedExportMode}
                setSelectedExportMode={setSelectedExportMode}
                batchDeleteHandler={batchDelete}
                handleDelete={handleDelete}
              />
              {(settings?.useMultipleLocations && settings?.locations) && (
                <div className="input-field location-container align-items-center">
                  <label htmlFor="locationCode" className="mr-10">Location:</label>
                  <Select
                    value={locationCodeRef?.current ? locationCodeRef.current : 'all'}
                    onChange={(value) => onChangeLocationCodeHander(value)}
                    className="select-location"
                    placeholder="Location Options"
                    aria-labelledby="Location options"
                  >
                    <Option value="all">All</Option>
                    {settings?.locations.map((location) => {
                      return (
                        <Option key={location?.code} value={location?.code}>
                          {location.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              )}
              <Search handleSearch={handleSearch} placeholderText={"Search"} />
            </PageMenuHeader>

            <Tabs
              setSelected={setSelectedTab}
              selected={selectedTab}
              aria-label="Tabs"
            >
              <Tab name="Finished Goods">
                  <div className="card p-10">
                    {items && (
                      <Pagination
                        handleOnPageChange={handleOnPageChange}
                        totalDocs={items?.totalDocs}
                        totalPages={items?.totalPages}
                        page={pageFG}
                        limit={limit}
                      />
                    )}

                    {msg && <Banner className="mb-10">{msg}</Banner>}

                    {items && Boolean(items?.docs.length) ? (
                      <InventoryList
                        items={items.docs}
                        showModal={showModalHandler}
                        settings={settings}
                        locationCode={locationCodeRef.current}
                      />
                    ) : (
                      <Banner>Inventory status not found</Banner>
                    )}
                  </div>
              </Tab>
              <Tab name="Components">
                  <div className="card p-10">
                    {components && (
                      <Pagination
                        handleOnPageChange={handleOnPageChange}
                        totalDocs={components?.totalDocs}
                        totalPages={components?.totalPages}
                        page={pageComp}
                        limit={limit}
                      />
                    )}

                    {msg && <Banner className="mb-10">{msg}</Banner>}

                    {components && Boolean(components?.docs.length) ? (
                      <InventoryList
                        items={components.docs}
                        showModal={showModalHandler}
                        settings={settings}
                        locationCode={locationCodeRef.current}
                      />
                    ) : (
                      <Banner>Inventory status not found</Banner>
                    )}
                  </div>
              </Tab>    
            </Tabs>        
            


          </>
        )}
        <ScrollTop />
      </section>
      <ModalInventory
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        title={"Inventory Status"}
        mode={mode}
        item={item}
        setItem={setItem}
        submitHandler={submitHandler}
        handleDelete={deleteInventoryAreaHandler}
        user={user}
        settings={settings}
        inventoryTypes={inventoryTypes}
        selectedTab={selectedTab}
      />
      <ModalPreloader modalPreloaderIsOpen={loading} />
    </>
  );
}
