import _ from "lodash";
import { differenceInCalendarDays, toDate, format } from "date-fns";

export const sessionValidate = ({ sessions, hostname }) => {
  console.log("* sessionValidate init");
  // console.log("[sessionValidate] sessions: ", sessions);
  // console.log("[sessionValidate] hostname-0 : ", hostname);

  if (_.isNil(hostname)) {
    const _hostname = window.localStorage.getItem("_hostname");
    // console.log("[sessionValidate] _hostname: ", _hostname);
    if (_hostname) hostname = _hostname;
  }

  // console.log("[sessionValidate] hostname-1 : ", hostname);

  // isQueuePrint depreciated: 1/15/2023
  // console.log('- isQueuePrint: ', isQueuePrint)
  const enums = [
    { id: 0, result: true },
    { id: 1, result: true, message: "Your license for Printflo is expiring " },
    {
      id: 2,
      result: false,
      message: "Session not found: some functions may be limited",
    },
    {
      id: 3,
      result: false,
      message:
        "No Printflo license registered for this station: : some functions may be limited",
    },
    { id: 4, result: false, message: "Your license for Printflo is expired" },
  ];

  const session = _.find(sessions, { hostname });
  // console.log("[sessionValidate] session: ", session);

  // if (_.includes(hostname, "CHH")) return { ...enums[0] };

  if (sessions && session) {
    if (session?.dateOfExpiry) {
      const remainingDays = differenceInCalendarDays(
        new Date(session.dateOfExpiry),
        toDate(new Date())
      );
      // console.log("[sessionValidate] remainingDays: ", remainingDays);
      if (remainingDays >= 0 && remainingDays <= 30) {
        if (remainingDays >= 1) {
          return {
            ...enums[1],
            message: `${enums[1].message} on ${format(
              toDate(new Date(session.dateOfExpiry)),
              "E LLL d yyyy"
            )}`,
            session,
          };
        } else if (remainingDays === 0) {
          return {
            ...enums[1],
            message: `${enums[1].message} in today`,
            session,
          };
        }
      }
      if (remainingDays < 0) {
        return {
          ...enums[4],
          message: `${enums[4].message} after ${format(
            toDate(new Date(session.dateOfExpiry)),
            "E LLL d yyyy"
          )}`,
          session,
        };
      }

      if (remainingDays > 0) {
        return { ...enums[0], session };
        // if (isQueuePrint) {
        //   return session.usedFor === "Printing" ? { ...enums[0], session } : { ...enums[4], session };
        // } else {
        //   return { ...enums[0], session };
        // }
      } else {
      }
    } else {
      return { ...enums[2], session };
    }
  } else {
    return { ...enums[3] };
  }
};
