import _ from "lodash";
import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { authenticate } from "utils/auth";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  console.log("* AuthContextProvider init");
  const [authError, setAuthError] = useState(null);
  const [user, setUser] = useState(() => {
    const storedUser = window.localStorage.getItem("_auth");
    // console.log("[AuthContextProvider] storedUser", storedUser);
    if (storedUser && storedUser !== "undefined") {
      return JSON.parse(storedUser);
    } else {
      return null;
    }
  });

  // eslint-disable-next-line

  const history = useHistory();

  const login = async (username, password) => {
    console.log("* authContext:login init");
    try {
      const result = await authenticate({ username, password });
      // console.log("[authContext:login] result: ", result);
      // console.log("[authContext:login] result: ", result?.username, result?.role);
      // if (result?.message) {
      //   console.log("[authContext:login] message: ", result.message);
      //   setAuthError(result.message);
      //   return result;
      // } else {
      setUser(result);
      window.localStorage.setItem("_auth", JSON.stringify(result));
      return result;
      // }
    } catch (error) {
      console.error("[authContext:login] error: ", error);
      console.error("[authContext:login] error:isObject ", _.isObject(error));
      console.error("[authContext:login] error:message ", error?.message);
      console.error("[authContext:login] error:response ", error?.response);

      if (error?.response) {
        console.error(
          "[authContext:login] error:response:data ",
          error?.response?.data
        );
        if (error?.response?.data?.message) {
          setAuthError(error.response.data.message);
          return;
        }
      } else if (error?.message) {
        setAuthError(error.message);
        return;
      } else {
        setAuthError("Unknown error occured");
        return;
      }
    }
  };

  const logout = async () => {
    const _hostname = window.localStorage.getItem("_hostname");
    window.localStorage.clear();
    if (!_.isNil(_hostname))
      window.localStorage.setItem("_hostname", _hostname);
    setUser(null);
    history.push("/login");
    window?.printflo_api && (await window.printflo_api._analytics());
    return Promise.resolve(true);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        authError,
        setAuthError,
        user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
