import axios from "axios";
import { errorHandler } from "./errorHandler";
import _ from "lodash";

export const fetchPackingItems = (payload) => {
  console.log("* utils:fetchPackingItems init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchPackingItems(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(`/api/packing-items/fetch`, {
            params: payload,
          });
          // console.log("[utils:fetchPackingItems] response", response);
          result = response.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:fetchPackingItems] result", result);
      resolve(result);
    } catch (error) {
      console.log("[utils:fetchPackingItems] error: ", error);
      reject(error);
    }
  });
};

export const updatePackingItem = (data) => {
  console.info("* utils:packingitems:updatePackingItem init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._updatePackingItem(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/packing-items/update`, data);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.info("[utils:packingitems:updatePackingItem] result ", result);
      resolve(result);
    } catch (error) {
      console.error(
        "[utils:packingitems:updatePackingItem] error",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};

export const deletePackingItem = (orderId) => {
  console.log("* utils:packingitems:deletePackingItem init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._deletePackingItem({ orderId });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.delete(
            `/api/packing-items/delete/${orderId}`
          );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.log("[utils:packingitems:deletePackingItem] result: ", result);
      resolve(result);
    } catch (error) {
      console.error(
        "[utils:packingitems:deletePackingItem] error",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};

export const updateManyPackingItem = (data) => {
  console.log("* utils:packingitems:updateManyPackingItem init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._updateManyPackingItem(data);
      } else {
        const response = await axios.post(
          `/api/packing-items/update-many`,
          data
        );
        result = response?.data;
      }
      console.log(
        "[utils:packingitems:updateManyPackingItem] result: ",
        result
      );
      resolve(result);
    } catch (error) {
      console.error(
        "[utils:packingitems:updateManyPackingItem] error",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};

export const addPackingItem = (payload) => {
  console.log("* utils:packingitems:addPackingItem init");

  return new Promise(async (resolve, reject) => {
    let result;

    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._addPackingItem(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post("/api/packing-items/add", {
            update: payload,
          });
          // console.info(
          //   "[utils:packingitems:addPackingItem:axios] response: ",
          //   response
          // );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.info("[utils:packingitems:addPackingItem] result: ", result);
      resolve(result);
    } catch (error) {
      console.error(
        "[utils:packingitems:addPackingItem] error",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};

export const fetchActivityItems = (payload) => {
  console.log("* utils:packingitems:fetchActivityItems init");
  return new Promise(async (resolve, reject) => {
    // console.log("[utils:fetchActivityItems] payload: ", payload);
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchActivityItems(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(
            "/api/packing-items/activity-items",
            {
              params: payload,
            }
          );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:fetchActivityItems] result", result);
      resolve(result);
    } catch (error) {
      console.log("[utils:fetchActivityItems] error: ", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};

export const fetchActivityGraphics = (payload) => {
  return new Promise(async (resolve, reject) => {
    // console.log("[utils:fetchActivityGraphics] payload: ", payload);
    try {
      let result;
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchActivityGraphics(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(
            "/api/packing-items/activity-graphics",
            { params: payload }
          );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:fetchActivityGraphics] result", result);
      resolve(result);
    } catch (error) {
      console.log("[utils:fetchActivityGraphics] error: ", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};

export const fetchConsolidate = (payload) => {
  console.log("[utils:fetchConsolidate] init");
  return new Promise(async (resolve, reject) => {
    console.log("[utils:fetchConsolidate] payload: ", payload);
    let results;
    try {
      if (window?.printflo_api) {
        results = await window.printflo_api._fetchConsolidate(payload);
        if (_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.get("/api/packing-items/consolidate", {
            params: payload,
          });
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      if (payload?.page) resolve(results);
      else resolve(results.docs);
    } catch (error) {
      console.log("[utils:fetchConsolidate] error: ", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};

export const deleteManyPackingItems = (payload) => {
  console.log("* utils:packingitems:deleteManyPackingItems init");
  console.log("* utils:packingitems:deleteManyPackingItems payload: ", payload);
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._deleteManyPackingItems(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.delete(`/api/packing-items/deleteMany`, {
            payload,
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.log(
        "[utils:packingitems:deleteManyPackingItems] result: ",
        result
      );
      resolve(result);
    } catch (error) {
      console.error(
        "[utils:packingitems:deleteManyPackingItems] error",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};
