import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import PageMenuHeader from 'components/PageMenuHeader'
import _ from "lodash";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { errorHandler } from "utils/errorHandler";
import { getItemDoc } from "utils/items";
import Search from "components/Search";
import { FaBackspace, FaList, FaRecycle } from "react-icons/fa";
import Button from "@leafygreen-ui/button";
import {Table, TableHeader, Row, Cell} from "@leafygreen-ui/table";
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import { ModalPreloader } from "utils/Preloader";
import ScrollTop from "utils/ScrollTop";
import { addTransaction } from "utils/inventoryTransactions";
import { barcodeScannedHandler } from "utils/barcodeScannedHandler";
import { useHistory } from "react-router-dom";
import socketIOClient from "socket.io-client";
import axios from "axios";

const title="Defect Handling"
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const reasons = ["Print defect", "Material defect", "Pre-treatment defect", "Inventory defect", "Other defect"];

const fetchDefect = () => {
  return new Promise(async (resolve, reject) => {
    let results;
    try {
      if(window?.printflo_api) {
        results = await window.printflo_api._fetchDefect({})
        if(_.isError(results)) throw results
      } else {
        try {
          const response = await axios.get("/api/defect");
          if(response?.data) {
            results = response.data
          }
        } catch (error) {
          reject(error)
        }
      }
      resolve(results);
      
    } catch (error) {
      reject(errorHandler(error))
    }

  })
}

export default function DefectProcess() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [sku, setSKU] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [searchTxt, setSearchTxt] = useState("");
  const [disableDispose, setDisableDispose] = useState(true);
  const queryClient = useQueryClient();
  const history = useHistory();
  const bannerVariantRef = React.useRef("info");
  const { data: items, isLoading, isError, error } = useQuery("defects",
    async () => await fetchDefect(),
    {refetchOnWindowFocus: false}
  )

  const defectHandler = useMutation(async ({ action, payload }) => {
    console.log('* defectHandler init')
    console.log("[defectHandler] action: ", action, typeof action);
    console.log("[defectHandler] payload: ", payload);
    let result;
    try {
      if(window?.printflo_api) {
        result = await window.printflo_api[action](payload)
        if(_.isError(result)) throw result;
      } else {
        try {
          action = _.replace(action, "_", "").replace("Defect", "");
          console.log("[defectHandler] action:replace ", action, typeof action);
          const response = await axios.post(`/api/defect/${action}`, payload)
          result = response?.data
        } catch (error) {
          console.log(error)
          throw error;
        }
      }
      return result;
      
    } catch (error) {
      throw error;
    }
  }, {
    onMutate: () => queryClient.cancelQueries("defects"),
    onSuccess: (result, variables) => {
      console.log("[defectHandler] result: ", result);
      console.log("[defectHandler] variables: ", variables);
      const { action, payload } = variables;
      console.log('[defectHandler] action: ', action)
      switch (action) {
        case "_createDefect":
          queryClient.setQueriesData("defects", old => ([...old, result]))
          break;
        case "_deleteDefect":
          sku && setSKU(null)
          selectedItem && setSelectedItem(null)
          queryClient.setQueriesData("defects", old => {
            return old.filter(i => i._id !== payload.id)
          })
          break;
        case "_updateDefect":
          queryClient.setQueriesData("defects", old => {
            let idx = _.findIndex(old, { _id: payload?.condition.id });
            old[idx] = result;
            return old;
          })
          break;
        default:
          break;
      }
    },
    onError: (error) => {
      console.log("[defectHandler:onError] error: ", error);
      bannerVariantRef.current = "danger";
      if(error?.message) setMsg(error.message)
    }
  });

  const onBarcodeScannedHandler = async (code) => {
    setLoading(true)
    msg && setMsg(null);
    selectedItem && setSelectedItem(null);
    searchTxt && setSearchTxt("");
    sku && setSKU(null);

    setSKU(code);

    let production, payload;

    try {
      // get a production info from items
      production = await getItemDoc(code);
      if (production) {
        console.log("[onBarcodeScanned] production: ", production);

        setSelectedItem(production);
        const component = production?._component;
        if (!_.isNil(component) && !_.isEmpty(component)) {
          payload = {
            sku: component.sku,
            inventoryArea: component.inventoryArea,
            reason: reasons[0],
          };
          setDisableDispose(false);
        } else {
          toast.error(`Component information not found`, {
            position: "bottom-right"
          })
          setLoading(false)
          return
        }
      } else {
        toast.error(`${code} not found!`, {
          position: "bottom-right",
        })
        setLoading(false)
        return
      }
    } catch (error) {
      const retval = errorHandler(error);
      setMsg(retval)
      setLoading(false)
      return
    }

    if (payload) {
      setLoading(false)
      defectHandler.mutate({ action: "_createDefect", payload });
    }
  }

  const onBarcodeScanned = async (data) => {
    console.log("* onBarcodeScanned init");
    console.log("[onBarcodeScanned] data: ", data);
    const { sku:code } = barcodeScannedHandler(data);
    console.log("[onBarcodeScanned] code", code);
    onBarcodeScannedHandler(code);
    // eslint-disable-next-line
  };

  const socketOnBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("* on-barcode-scanned")
      console.log("[on-barcode-scanned] data: ", data);
      const { sku:code } = barcodeScannedHandler(data);
      onBarcodeScannedHandler(code);

    })
  }

  //onBarcodeScanned hook
  useEffect(() => {
    const listner = window?.printflo_api && window.printflo_api.receive("serialport_readline", onBarcodeScanned)
    
    setTimeout(() => {
      _.isNil(window?.printflo_api) && socketOnBarcodeScanned();
    }, 1000);

    return () => {
      if(listner) listner();
      socket.removeAllListeners(["on-barcode-scanned"]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(items) {
      if(Boolean(items.length)) {
        setDisableDispose(false);
      } else {
        setDisableDispose(true);
      }
    } 
  }, [items])


  const onChangeHandler = (e, item) => {
    console.log('* onChangeHandler init')
    console.log("value: ", e.target.value);
    console.log("name: ", e.target.name);
    defectHandler.mutate({ action: "_updateDefect", payload: { condition: { id: item._id }, update: { [e.target.name]: e.target.value } } });
  };

  const deleteDefectHandler = (item) => {
    defectHandler.mutate({ action: "_deleteDefect", payload: { id: item._id } });
  };

  const handlingDefect = async () => {
    console.log("* handlingDefect init");
    setDisableDispose(true)
    setLoading(true)
    let len = items.length;
    let i = 0;

    async function loop(i) {
      let item = items[i];
      // console.log(i, item);
      // console.log("reason", item.reason);
      let transaction = {
        sku: item.sku,
        description: item.reason,
        quantity: item.quantity * -1,
        date: new Date(),
        inventoryArea: item?.inventoryArea,
        user: user.username,
        type: "component"
      };
      try {
        const result = await addTransaction({transaction});
        console.log("[handlingDefect:addTransaction] result: ", result);
        // console.log('- i: ', i)
        setFeedback(`${i+1}/${len} ${item.sku} disposed.`)
        setLoading(false)
       
      } catch (error) {
        console.log('- handlingDefect error: ', error)
        setLoading(false)
        setDisableDispose(false)
        const retval = errorHandler(error)
        console.log('- retval: ', retval)
        setMsg(retval)
        return
      }

      i++;
      if (i < len) {
        loop(i);
      } else {
        console.log(`- reach out the last loop: i: ${i}`)
        setTimeout(() => {
          setLoading(false)
          setDisableDispose(false)
          setFeedback(null);
          toast.info(`Defect handling done! reloading...`, {
            position: "bottom-right",
            autoClose: 2000,
            onClose: async () => {
              let result;
              if(window?.printflo_api) {
                result = await window.printflo_api._deleteAllDefect()
              } else {
                const response = await axios.delete(`/api/defect/delete-all/`);
                result = response?.data;
              }
              console.log("[handlingDefect:_defectDeleteAll] result: ", result);
              
              if(result) window.location.reload();
            },
          });
        }, 3000);
      }

    }

    if (i >= 0) {
      loop(i);
    }
  };

  const handleSearchComponent = async (searchTxt) => {
    console.log('* handleSearchComponent init')
    console.log("[handleSearchComponent] searchTxt: ", searchTxt);
    let component, payload;
    if (searchTxt) {
      const searchTxtUpper = searchTxt.toUpperCase();
      setSearchTxt(searchTxtUpper);
      component = await getItemDoc(searchTxtUpper)
      console.log("handleSearchComponent component", component)
      if (!_.isNil(component) && !_.isEmpty(component)) {
        payload = {
          sku: component.sku,
          inventoryArea: component.inventoryArea,
          reason: reasons[0],
        };

        if (payload) {
          defectHandler.mutate({ action: "_createDefect", payload });
        }

      } else {
        toast.error(`Component information not found`, {
          position: "bottom-right"
        })
        return
      }
    }

  }

  return <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <ToastContainer theme='dark'/>
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger' className="mb-10">{error?.message ? error.message : error}</Banner>
        ) : (
          null
        )}
        <PageMenuHeader>
          <div className='btn-menu-list'>
            <Button
              variant="primary"
              onClick={() => history.push("/defect-transactions")}
              leftGlyph={<FaList/>}
            >
              View defect transactions
            </Button>
            {items && (
              <Button
                variant="danger"
                className="swing-icon"
                onClick={handlingDefect}
                disabled={disableDispose}
                leftGlyph={<FaRecycle />}
              >
                Dispose all
              </Button>
            )}
          </div>
          <div className="align-right_container">
            <Search handleSearch={handleSearchComponent} placeholderText="Search by component" />
          </div>
        </PageMenuHeader>
        {msg && <Banner className="mb-10" variant={bannerVariantRef.current}>{msg}</Banner>}
        {feedback && <Banner variant="success" className="mb-10">{feedback}</Banner>}
        
        { (sku && selectedItem) && (
          <div className="d-flex card mb-10 p-10">
            <div className="title-content-list" style={{width: "100%", justifyContent: "space-between"}}>
              <div className="title-content">
                <span className="title">SKU</span>
                {sku && (
                  <span className="d-block content">{sku}</span>
                )}
              </div>
            
              <div className="title-content">
                <span className="title">Component</span>
                <span className="content">{selectedItem?.component}</span>
              </div>
              <div className="title-content">
                <span className="title">Stock</span>
                <span className="content">
                  {selectedItem?._component?.stock}
                </span>
              </div>
              <div className="title-content">
                <span className="title">Inventory Location</span>
                <span className="content">
                  {selectedItem?._component?.inventoryArea}
                </span>
              </div>
              <div className="title-content">

                {!selectedItem?.imageUrl && (
                  <span className="title">Mockup</span>
                )}
                {selectedItem?.imageUrl && (
                  <img
                    src={selectedItem?.imageUrl}
                    alt={`${selectedItem?.sku}`}
                    className="responsive-img"
                    onError={(e) =>
                      (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
                    }
                    style={{maxWidth: 80}}
                  />
                )}
              </div>
            </div>
          </div>
        )}        

        {items && (Boolean(items.length) ? (
          <Table
            data={items}
            columns={[
              <TableHeader label="Date" dataType="date"/>,
              <TableHeader label="SKU" />,
              <TableHeader label="Quantity" dataType="number"/>,
              <TableHeader label="Reason" />,
              <TableHeader label="Action" />,
            ]}
          >
            {({datum}) => (
            <Row key={datum._id}>
              <Cell>
                {moment(datum.date).local().format("MM-DD-YYYY")}
              </Cell>
              <Cell>{datum.sku}</Cell>
              <Cell>
                <input type="text" value={datum?.quantity}
                  onChange={(e) => onChangeHandler(e, datum)}
                  name="quantity"
                  className="browser-default center-align"
                  style={{maxWidth: 50}}
                />
              </Cell>
              <Cell>
                <select
                  className="browser-default"
                  name="reason"
                  onChange={(e) => onChangeHandler(e, datum)}
                  style={{ maxWidth: "250px" }}
                  value={datum.reason}
                >
                  <option value="">= Select a defect reason =</option>
                  {reasons.map((r, index) => {
                    return (
                      <option key={index} value={r}>
                        {r}
                      </option>
                    );
                  })}
                </select>
              </Cell>

              <Cell>
                <Tooltip
                  className="lg-tooltip-container"
                  darkMode={true}
                  trigger={
                    <IconButton
                      className="swing-icon"
                      onClick={() => deleteDefectHandler(datum)}
                      aria-label="delete the line item"
                    >
                      <FaBackspace /> 
                    </IconButton>
                  }
                >
                  Delete the line item
                </Tooltip>
              </Cell>
            </Row>
            )}
          </Table>
          ) : (
            <span>Not found item.</span>
          ))
        }
        <ScrollTop />
      </section>
      <ModalPreloader modalPreloaderIsOpen={loading}/>
    </>
}
