import React from "react";
import { ToastContainer } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { Title } from "utils/Title";
import PageMainHeader from "components/PageMainHeader";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
import Banner from "@leafygreen-ui/banner";
import { useQuery, useQueryClient } from "react-query";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import { FaListAlt } from "react-icons/fa";
import Pagination from "utils/Pagination";
import { ModalPreloader } from "utils/Preloader";
import ScrollTop from "utils/ScrollTop";
import ModalConsolidationDetails from "components/modals/ModalConsolidationDetails";
import { fetchConsolidate } from "utils/packingItems";

const title = "Consolidation";
const pageSize = 50;

export default function Consolidate() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  let settingsRef = React.useRef();
  const [page, setPage] = React.useState(1);
  const [loading] = React.useState(false);
  const queryClient = useQueryClient();
  // const [printStates, setPrintStates] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedConsolidation, setSelectedConsolidation] = React.useState(null)

  const { data, isError, isLoading, error } = useQuery(
    ["consolidate", page],
    async () => (await fetchConsolidate({page, limit:pageSize, pagination: true})),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1,
    }
  );

  React.useEffect(() => {
    data && console.log("* data(consolidation) in hook: ");
    console.log("- page: ", page);
    if (data?.totalPages > page) {
      console.log("- prefetchQuery init");
      queryClient.prefetchQuery(
        ["consolidate", page + 1],
        async () => await fetchConsolidate({page: page+1, limit:pageSize, pagination: true}),
        {
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5,
        }
      );
    }
    // eslint-disable-next-line
  }, [page, data, queryClient]);

  // React.useEffect(() => {
  //   consolidate().then((results) => {
  //     setPrintStates(results);
  //   });
  // }, []);

  React.useEffect(() => {
    if (settings) {
      // console.log('- settings: ', settings)
      settingsRef.current = settings;
    }
  }, [settings]);

  const columns = [
    <TableHeader label="Artwork" sortBy={(datum) => datum.artwork} />,
    <TableHeader label="Ordered" sortBy={(datum) => datum.ordered} dataType="number"/>,
    <TableHeader label="Picked" dataType="number" />,
    <TableHeader label="Printed" dataType="number" />,
    <TableHeader label="Checked-out" dataType="number" />,
    <TableHeader label="Orders count" dataType="number" />,
    <TableHeader label="Action" />,
  ];

  const handleOnPageChange = ({ selected }) => {
    console.log("- handleOnPageChange init");
    console.log("- selected: ", selected);
    setPage(selected + 1);
  };

  /* const printConsolidate = async () => {
    console.log("* printConsolidate init");
    const printSectionElem = document.getElementById("print-section");
    if (!printSectionElem) return;
    setLoading(true);
    const printContent = printSectionElem.innerHTML;

    const frame1 = document.createElement("iframe");
    frame1.name = "frame3";
    frame1.style.position = "absolute";
    frame1.style.top = "-100000000px";
    document.body.appendChild(frame1);

    const frameDoc = frame1.contentWindow
      ? frame1.contentWindow
      : frame1.contentDocument.document
      ? frame1.contentDocument.document
      : frame1.contentDocument;

    frameDoc.document.open();
    frameDoc.document.write("<html><head><title>Consolidation</title>");
    frameDoc.document.write("<style>");
    frameDoc.document.write(
      "body,table{font-family:Arial,Helvetica,sans-serif;font-size:9pt}"
    );
    frameDoc.document.write(
      ".text-center{text-align:center}.text-right{text-align:right}"
    );
    frameDoc.document.write("table th{text-align:center}");
    frameDoc.document.write(
      "table{border-collapse:collapse;border:#666 .5px solid;width:100%}"
    );
    frameDoc.document.write(
      "table thead th, table tbody td{border:#666 .5px solid;padding:5px;}"
    );
    frameDoc.document.write("table tbody tr.hidden{display: none;}");
    frameDoc.document.write(".print-section_items{margin-top:10px;}");
    frameDoc.document.write("</style>");
    frameDoc.document.write('</head><body onafterprint="window.close()">');
    frameDoc.document.write(printContent);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();
    setTimeout(() => {
      window.frames["frame3"].focus();
      window.frames["frame3"].print();
      document.body.removeChild(frame1);
    }, 1000);
    setLoading(false);
  }; */

  const sumPrinted = (orders) => {
    let sum = 0;
    // console.log("orders: ", orders);
    for (let index = 0; index < orders.length; index++) {
      const { printed } = orders[index];
      for (let i = 0; i < printed.length; i++) {
        const elem = printed[i];
        if (elem?.qty) {
          sum += elem.qty;
        }
      }
    }
    return sum;
  };

  const handleViewConsolidationDetails = (datum) => {
    console.log('* handleViewConsolidationDetails init')
    // console.log('- datum: ', datum)
    setSelectedConsolidation(datum)
    setShowModal(true)
  }

  return (
    <>
      <Title title={title}/>
      <PageMainHeader title={title} user={user} settings={settings} description="Consolidated order items by artwork"/>
      <ToastContainer theme="dark" />
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant="danger">
            {error?.message ? error.message : error}
          </Banner>
        ) : null}
        {/* <PageMenuHeader>
          <Button
            variant="primaryOutline"
            className="swing-icon"
            onClick={printConsolidate}
            leftGlyph={<FaListAlt />}
          >
            Print Consolidation
          </Button>
        </PageMenuHeader> */}
        {data?.totalPages > 1 && (
          <Pagination
            handleOnPageChange={handleOnPageChange}
            totalDocs={data.totalDocs}
            totalPages={data.totalPages}
            page={page}
            limit={pageSize}
          />
        )}
        {data?.docs && (
          <Table data={data?.docs} columns={columns}>
            {({ datum }) => {
              // console.log("- datum: ", datum);
              return (
                <Row key={datum.artwork}>
                  <Cell>{datum?.artwork ? datum.artwork : ""}</Cell>
                  <Cell>{datum?.ordered ? datum.ordered : 0}</Cell>
                  <Cell>{datum?.picked ? datum.picked : 0}</Cell>
                  <Cell>
                    {sumPrinted(datum.orders)}
                  </Cell>
                  <Cell>{datum?.checkedout ? datum.checkedout : 0}</Cell>
                  <Cell>{datum?.count ? datum.count : 0}</Cell>
                  <Cell>
                    <Tooltip
                      align="right"
                      justify="start"
                      className="lg-tooltip-container"
                      darkMode={true}
                      trigger={
                        <IconButton
                          onClick={() => handleViewConsolidationDetails(datum)}
                          className="swing-icon"
                          aria-label="Queue print"
                        >
                          <FaListAlt />
                        </IconButton>
                      }
                      triggerEvent="hover"
                    >
                      View Details
                    </Tooltip>
                  </Cell>
                </Row>
              );
            }}
          </Table>
        )}
        <ModalConsolidationDetails 
          modalIsOpen={showModal} 
          setModalIsOpen={setShowModal} 
          title="Consolidation Details" 
          selectedConsolidation={selectedConsolidation}
          subTitle={selectedConsolidation?.artwork}
        />
        {/* {printStates && <ConsolidationsPrint consolidations={printStates} />} */}
        <ModalPreloader modalPreloaderIsOpen={loading} />
        <ScrollTop />
        {/* <pre>{JSON.stringify(printStates, null, 2)}</pre> */}
      </section>
    </>
  );
}

/*
<IconButton
  onClick={() => {
    // let url = `/queue-print-${!_.includes(settingsRef.current?.defaultIntegratedAutomation, 'GTX') ? "epson" : "brother"}?sku=${datum?.sku}&qty=${datum.ordered}`
    let url = `/queue-print?sku=${datum?.sku}&consolidation=1`;
    window.location.assign(url);
  }}
  className="swing-icon"
  aria-label="Queue print"
>
  <FaQrcode />
</IconButton>
 */
