import React, { useRef } from "react";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import ModalTemplate from "./ModalTemplate";
import Button from "@leafygreen-ui/button";

export default React.memo(function ModalBin({
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  selectedBin,
  setSelectedBin,
  submitHandler,
  handleDelete,
  binCodeInputRef,
  settings,
  user
}) {
  // console.log("* ModalBin init");
  // console.log("ModalBin selectedBin", selectedBin);
  // console.log("mode", mode);
  const modalBinFormRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    modalBinFormRef.current.reportValidity();
    if (modalBinFormRef.current.checkValidity()) submitHandler();
  };

  const onChangeHandler = (e) => {
    // console.log(e.target.id, e.target.value);
    if (e.target.id === "isActive") {
      setSelectedBin((current) => {
        return { ...current, isActive: !current.isActive };
      });
    } else {
      setSelectedBin({ ...selectedBin, [e.target.id]: e.target.value });
    }
  };

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "400px", height: "auto" }}
    >
      <form ref={modalBinFormRef} className="card modal-form">
        <div className="input-field">
          <label htmlFor="binCode">
            Bin Code
          </label>
          <input
            id="binCode"
            type="text"
            value={selectedBin?.binCode ? selectedBin?.binCode : "" }
            onChange={onChangeHandler}
            placeholder="Bin Code"
            required={true}
            ref={binCodeInputRef}
            autoFocus
          />
        </div>
        <div className="input-field">
          <label htmlFor="description">
            Description
          </label>
          <input
            id="description"
            type="text"
            value={selectedBin?.description ? selectedBin?.description : "" }
            onChange={onChangeHandler}
            placeholder="Description"
          />
        </div>
        {settings?.useMultipleLocations && (
          <div className="input-field">
            <label htmlFor="locationCode">
              location
            </label>
            {/* <input
              id="locationCode"
              type="text"
              value={selectedBin?.locationCode ? selectedBin?.locationCode : "" }
              onChange={onChangeHandler}
              placeholder="location"
            /> */}
            <select
              id="locationCode"
              value={selectedBin?.locationCode ? selectedBin.locationCode : ""}
              onChange={onChangeHandler}
              placeholder="location"
              required = {settings?.useMultipleLocations ? true : false}
            >
              <option value="">-- Choose a location --</option>
              {settings &&
                settings?.locations.map((l) => {
                  return (
                    <option value={l.code} key={l.code}>
                      {l.name}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        
        <div className="input-field">
          <label htmlFor="loadCapacity">
            Load Capacity
          </label>
          <input
            id="loadCapacity"
            type="text"
            value={selectedBin?.loadCapacity ? selectedBin?.loadCapacity : ""}
            onChange={onChangeHandler}
            placeholder="Load Capacity"
          />
        </div>
        <div className="input-field">
          <label htmlFor="priority">
            Priority
          </label>
          <input
            id="priority"
            type="text"
            value={selectedBin?.priority ? selectedBin?.priority : ""}
            onChange={onChangeHandler}
            placeholder="Priority"
          />
          
        </div>
        <div className="input-checkbox-container">
          <input
            id="isActive"
            type="checkbox"
            value={selectedBin?.isActive ? selectedBin?.isActive : false}
            checked={selectedBin?.isActive ? selectedBin?.isActive : false}
            onChange={onChangeHandler}
            placeholder="Active"
          />
          <label>Active</label>
        </div>
        <div className="modal-footer">
          {mode!=='create' && (
            <Button
              variant="danger"
              className="swing-icon"
              onClick={handleDelete}
              leftGlyph={<FaTrashAlt /> }
            >
              Delete
            </Button>
          )}
          <Button variant='primary' onClick={handleSubmit} className="swing-icon" leftGlyph={<FaSave />}>
            Submit
          </Button>
        </div>
      </form>
          
    </ModalTemplate>
  );
})
