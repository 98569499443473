/* eslint-disable import/no-anonymous-default-export */
export default {
  en: {
    title: "Printingify",
    sidebarTitle: "Printflo",
    description: "All about Printing",
    dashboard: "Dashboard",
    orders: "Orders",
    workOrders: "Work Orders",
    shippedOrders: "Shipped Orders",
    browsingOrderItem: "Browsing Order Item",
    orderItemsActivity: "Order Items Activity",
    pickingList: "Picking List",
    consolidation: "Consolidation",
    packingList: "Packing List",
    epson: "Queue Print Epson",
    brother: "Queue Print Brother",
    queuePrint: "Queue Print",
    shipment: "Shipment",
    items: "Items",
    productions: "Productions",
    components: "Components",
    graphics: "Graphics",
    inventoryTransactions: "Inventory Transactions",
    finishedGoodsInventory: "F/G Inventory Status",
    Inventory: "Inventory Status",
    defect: "Defect Handling",
    bins: "Bins",
    users: "Users",
    logs: "Logs",
    settings: "Settings",
    sessions: "Sessions",
    new: "New",
    printedFilmsInventory: "Printed Films Inventory",
    syncOrders: "Sync Orders",
    configvars: "Config Vars",
  },
};
